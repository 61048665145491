import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { GlobalService } from '../../../core/services/global-services/global.service';
import { LoadingScreenService } from '../../../core/services/loading-screen/loading-screen.service';
import {ApiService} from '../../../core/services/api-services/api.service';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {AlertasComponent} from '../../../shared/components/alertas/alertas.component';

@Component({
  selector: 'app-confirm-telemarketing-tax',
  templateUrl: './confirm-telemarketing-tax.component.html',
  styleUrls: ['./confirm-telemarketing-tax.component.sass']
})
export class ConfirmTelemarketingTaxComponent implements OnInit {
  public screenActual = 1;
  public activateLine = false;
  public telemarketingPayTax;
  public accountNumber;
  public createdDate;
  public amount;
  public urlId;
  public valuePayment;
  public subscriberEmail;
  public subscriberName;
  public subscriberLastName;
  public ban;
  paymentReceived: Subscription;

  public valuePaymentSplit1;
  public valuePaymentSplit2;
  public amountToPay;

  splitPayment = false;
  private paySplit1 = false;
  private paySplit2 = false;
  // todo: cambiar cuando se tenga previa generacion del token
  private paySplit1Completed = true;
  private paySplit2Completed = false;

  private paymentToken;

  private numConfirmationPaySplit1;
  private numAuthorizationPaySplit1;
  private dateProvisioningPaySplit1;
  private paymentMethodDisplayPaySplit1;

  private numConfirmationPaySplit2;
  private numAuthorizationPaySplit2;
  private dateProvisioningPaySplit2;
  private paymentMethodDisplayPaySplit2;

  private transactionId;

  private redirectURL;

  public paymentValue = null;
  private verifyPaymentStatus = null;
  private numAuthorization = null;

  // tslint:disable-next-line:max-line-length
  constructor(private loadingScreenService: LoadingScreenService, public global: GlobalService, private router: Router, private services: ApiService, public dialog: MatDialog) { }

  ngOnInit() {
    this.paymentReceived = this.global.paymentReceived.subscribe((value: boolean) => {
      if (this.paymentValue === null || (this.paymentValue !== value)) {
        if (this.paymentValue !== true) {
          this.paymentValue = value;
          this.processPayment(value);
        }
      }
    });

    // this.paymentReceived = this.global.paymentReceived.subscribe((value: boolean) => {
    //   const verifyPaymentStatus = JSON.parse(sessionStorage.getItem('verifyPaymentStatus'));
    //
    //   if (value) {
    //     this.loadingScreenService.stopLoading();
    //     if (!this.splitPayment) {
    //       this.global.notif('Se ha efectuado el pago de la factura.');
    //       this.screenActual = 2;
    //     } else {
    //       if (this.paySplit1) {
    //         this.paySplit1 = false;
    //         this.paySplit1Completed = true;
    //
    //         this.numConfirmationPaySplit1 = sessionStorage.getItem('numConfirmation');
    //         this.numAuthorizationPaySplit1 = sessionStorage.getItem('numAuthorization');
    //         this.dateProvisioningPaySplit1 = verifyPaymentStatus.provisioningDate;
    //         this.paymentMethodDisplayPaySplit1 = verifyPaymentStatus.paymentMethodImage;
    //       } else {
    //         this.paySplit2 = false;
    //         this.paySplit2Completed = true;
    //
    //         this.numConfirmationPaySplit2 = sessionStorage.getItem('numConfirmation');
    //         this.numAuthorizationPaySplit2 = sessionStorage.getItem('numAuthorization');
    //         this.dateProvisioningPaySplit2 = verifyPaymentStatus.provisioningDate;
    //         this.paymentMethodDisplayPaySplit2 = verifyPaymentStatus.paymentMethodImage;
    //       }
    //     }
    //   } else {
    //     if (this.splitPayment) {
    //       if (this.paySplit1) {
    //         this.paySplit1 = false;
    //       } else {
    //         this.paySplit2 = false;
    //       }
    //     }
    //   }
    //
    //   this.loadingScreenService.startLoading();
    //   this.services.updateTransaction(
    //     this.transactionId,
    //     verifyPaymentStatus.errorCode === null ? '' : verifyPaymentStatus.errorCode,
    //     verifyPaymentStatus.confirmationNumber === null ? '' : verifyPaymentStatus.confirmationNumber,
    //     verifyPaymentStatus.authorizationNumber === null ? '' : verifyPaymentStatus.authorizationNumber,
    //     verifyPaymentStatus.paymentStatus + '###' + verifyPaymentStatus.provisioningStatus,
    //     verifyPaymentStatus.message === null ? '' : verifyPaymentStatus.message,
    //     this.getTransactionStatus(verifyPaymentStatus.paymentStatus, verifyPaymentStatus.provisioningStatus),
    //     sessionStorage.getItem('token'),
    //     this.urlId,
    //     this.amountToPay,
    //     'TELEMARKETING_PAY_TAXES',
    //     this.paySplit1 ? 'split' : 'completed',
    //     null, null, null, null, null, null
    //   ).subscribe(data => {
    
    //     this.loadingScreenService.stopLoading();
    //
    //     if (!value) {
    //       this.showDialogError('Disculpe, hemos tendido problemas procesando su pago, por favor llamar al 787-793-1253');
    //     }
    //
    //   });
    //
    // });
    if (sessionStorage.getItem('telemarketing_pay_tax') != null) {
      this.telemarketingPayTax = JSON.parse(atob(sessionStorage.getItem('telemarketing_pay_tax')));
      this.accountNumber = this.telemarketingPayTax.accountNumber;
      this.createdDate = this.telemarketingPayTax.createdDate;
      this.amount = this.telemarketingPayTax.amount;
      this.urlId = this.telemarketingPayTax.urlId;

      this.valuePayment = this.amount;
      this.valuePaymentSplit1 = this.valuePayment;
      this.valuePaymentSplit2 = 0;

      // todo: validar token previo para split
    }

    this.subscriberEmail = JSON.parse(atob(sessionStorage.getItem('getSubscriber'))).accountInfo.email;
    this.subscriberName = JSON.parse(atob(sessionStorage.getItem('getSubscriber'))).accountInfo.firstName;
    this.subscriberLastName = JSON.parse(atob(sessionStorage.getItem('getSubscriber'))).accountInfo.lastName;
    this.ban = atob(sessionStorage.getItem('subscriber'));
  }

  private async processPayment(value: boolean) {
    this.verifyPaymentStatus = JSON.parse(sessionStorage.getItem('verifyPaymentStatus'));

    if (value) {
      this.loadingScreenService.stopLoading();

      if (!this.splitPayment) {
        this.global.notif('Se ha efectuado el pago de la factura.');
        this.numAuthorization = sessionStorage.getItem('numAuthorization');
        sessionStorage.setItem('transactionId', this.transactionId);
        this.screenActual = 2;
      } else {
      }

    } else {

      // caso de error 129: expiracion de transaccion
      if (this.verifyPaymentStatus.errorNum === 129) {
        this.router.navigate(['']);
      }
    }

    if (value) {
      this.loadingScreenService.startLoading();
      this.services.updateTransaction(
        this.transactionId,
        this.verifyPaymentStatus.errorCode === null ? '' : this.verifyPaymentStatus.errorCode,
        this.verifyPaymentStatus.confirmationNumber === null ? '' : this.verifyPaymentStatus.confirmationNumber,
        this.verifyPaymentStatus.authorizationNumber === null ? '' : this.verifyPaymentStatus.authorizationNumber,
        this.verifyPaymentStatus.paymentStatus + '###' + this.verifyPaymentStatus.provisioningStatus,
        this.verifyPaymentStatus.message === null ? '' : this.verifyPaymentStatus.message,
        this.getTransactionStatus(this.verifyPaymentStatus.paymentStatus, this.verifyPaymentStatus.provisioningStatus),
        sessionStorage.getItem('token'),
        sessionStorage.getItem('urlId'),
        this.amountToPay,
        'TELEMARKETING_PAY_TAXES',
        this.paySplit1 ? 'split' : 'completed',
        JSON.parse(atob(sessionStorage.getItem('getSubscriber'))).accountInfo.accountType,
        JSON.parse(atob(sessionStorage.getItem('getSubscriber'))).accountInfo.accountSubtype,
        this.accountNumber,
        this.ban,
        this.amount,
        this.subscriberName + ' ' + this.subscriberLastName
      ).subscribe(data => {
        this.loadingScreenService.stopLoading();

        if (!value) {
          this.showDialogError('Disculpe, hemos tendido problemas procesando su pago, por favor llamar al 787-793-1253');
        }

      });
    }
  }

  private showDialogError(error: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    // tslint:disable-next-line:max-line-length
    dialogConfig.data = {message: error, colorSi : 'colorRed', colorNo: undefined, btmOk: true, btmConfirm: false, labelOk: 'Aceptar', labelNo: undefined};
    const dialogRef = this.dialog.open(AlertasComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(value => {
      this.router.navigate(['']);
    });
  }

  async goTo() {
    this.amountToPay = this.valuePayment;
    this.paymentValue = null;
    // tslint:disable-next-line:max-line-length
    const transaction = await this.services.createTransaction('MEPMMTCRDT', 'TELEMARKETING_PAY_TAXES', null, sessionStorage.getItem('token')).toPromise();
    // @ts-ignore
    this.transactionId = transaction.object.id;

    const typeMessage = 'payment';
    // tslint:disable-next-line:max-line-length
    this.global.newPayment(1, typeMessage, this.accountNumber, this.ban, this.valuePayment, this.subscriberEmail, this.subscriberName + ' ' + this.subscriberLastName, '', this.global.tokenMiClaro);
  }

  backToOptions() {
    this.router.navigate(['postpaid_select_option']);
  }
  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy() {
    this.paymentReceived.unsubscribe();
  }

  async goPaySplit1() {
    if (this.validateAmount()) {
      this.amountToPay = this.valuePaymentSplit1;

      this.loadingScreenService.startLoading();
      // todo: generar previo paymentToken
      const value = await this.services.addChildPayment(this.paymentToken, this.valuePaymentSplit1).toPromise();
      this.loadingScreenService.stopLoading();

      if (!value.hasError && value.errorNum === 0) {
        this.paySplit1 = true;
        const typeMessage = 'payment';
        this.redirectURL = value.redirectURL;
        // this.paymentToken = value.childPaymentToken;

        this.loadingScreenService.startLoading();
        // tslint:disable-next-line:max-line-length
        const transaction = await this.services.createTransaction('MEPMMTCRDT', 'TELEMARKETING_PAY_TAXES', value.childPaymentToken, sessionStorage.getItem('token')).toPromise();
        this.loadingScreenService.stopLoading();
        // @ts-ignore
        this.transactionId = transaction.object.id;

        // tslint:disable-next-line:max-line-length
        this.global.newPayment(5, typeMessage, this.accountNumber, this.ban, this.valuePaymentSplit1, this.subscriberEmail, this.subscriberName + ' ' + this.subscriberLastName, '', value.childPaymentToken, '', this.redirectURL);
      } else {
        this.global.notif('Ha Ocurrido un error Inicializando el pago, por favor intente nuevamente');
      }
    }
  }

  async goPaySplit2() {
    if (this.validateAmount()) {
      this.amountToPay = this.valuePaymentSplit2;

      this.loadingScreenService.startLoading();
      // todo: generar previo paymentToken
      const value = await this.services.addChildPayment(this.paymentToken, this.valuePaymentSplit2).toPromise();
      this.loadingScreenService.stopLoading();

      if (!value.hasError && value.errorNum === 0) {
        this.paySplit2 = true;
        const typeMessage = 'payment';
        this.redirectURL = value.redirectURL;

        // this.paymentToken = value.childPaymentToken;

        this.loadingScreenService.startLoading();
        // tslint:disable-next-line:max-line-length
        const transaction = await this.services.createTransaction('MEPMMTCRDT', 'TELEMARKETING_PAY_TAXES', value.childPaymentToken, sessionStorage.getItem('token')).toPromise();
        this.loadingScreenService.stopLoading();
        // @ts-ignore
        this.transactionId = transaction.object.id;

        // tslint:disable-next-line:max-line-length
        this.global.newPayment(5, typeMessage, this.accountNumber, this.ban, this.valuePaymentSplit2, this.subscriberEmail, this.subscriberName + ' ' + this.subscriberLastName, '', value.childPaymentToken, '', this.redirectURL);
      } else {
        this.global.notif('Ha Ocurrido un error Inicializando el pago, por favor intente nuevamente');
      }
    }
  }

  validateAmount() {
    if (this.valuePaymentSplit1 === this.amount) {
      return false;
    }
    if (this.valuePaymentSplit1 === (this.amount / 2)) {
      return false;
    }
    if (this.valuePaymentSplit1 < 5) {
      return false;
    }
    if (this.valuePaymentSplit1 > this.amount) {
      return false;
    }
    if (this.amount - this.valuePaymentSplit1 < 5) {
      return false;
    }
    return true;
  }

  calculateValuePaymentSplit2() {
    this.valuePaymentSplit2 = parseFloat((this.amount - this.valuePaymentSplit1).toString()).toFixed(2);
  }

  goToSplitPaymentFinished() {
    this.global.notif('Se ha efectuado el pago de la factura.');
    this.screenActual = 2;
  }

  private getTransactionStatus(paymentStatus: string, provisioningStatus: string) {
    if (paymentStatus === 'BANK SUCCESS NOT PROVISIONABLE' && provisioningStatus === 'SPLIT TRANSACTION NOT PROVISIONABLE') {
      return 'approved';
    }
    if (paymentStatus === 'BANK PAYMENT SUCCESS' && provisioningStatus === 'PROVISIONED') {
      return 'provisioning';
    }
    if (paymentStatus === 'BANK PAYMENT SUCCESS' && provisioningStatus === 'EXTERNALLY PROVISIONED') {
      return 'provisioning';
    }
    if (paymentStatus === 'BANK PAYMENT SUCCESS' && provisioningStatus === 'PROVISIONING FAILED') {
      return 'noprovisioning';
    }
    return 'rejected';
  }
}
