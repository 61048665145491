import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GlobalService } from '../../services/global-services/global.service';
@Component({
  selector: 'app-payment-declined',
  templateUrl: './payment-declined.component.html',
  styleUrls: ['./payment-declined.component.sass']
})
export class PaymentDeclinedComponent implements OnInit {
  @Output() currentScreen = new EventEmitter();
  public textMessageError='<div>En este momento estamos presentando inconvenientes en nuestro sistema.</div><div> Por favor, íntentelo nuevamente.</div>';
  constructor(public global: GlobalService) { }

  ngOnInit(): void {
    if(this.global.paymentDescription!=""){
      this.textMessageError=this.global.paymentDescription;
    }
  }
  retry(){
    this.global.setStep(1);
    this.currentScreen.next(1);
  }
}