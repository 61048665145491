import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdateEmailComponent } from './update-email.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [
    UpdateEmailComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule

  ],
  exports: [
    UpdateEmailComponent
  ]
})
export class UpdateEmailModule { }
