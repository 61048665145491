import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from 'src/app/components/safe-html/safe-html.component';

@NgModule({
  declarations: [
    SafeHtmlPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SafeHtmlPipe
  ]
})
export class SafeHtmlPipeModule {
}