import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {GlobalService} from '../../../core/services/global-services/global.service';
import {LoadingScreenService} from '../../../core/services/loading-screen/loading-screen.service';
import {ApiService} from '../../../core/services/api-services/api.service';
import {AlertasComponent} from '../../../shared/components/alertas/alertas.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-installment-tax',
  templateUrl: './confirm-installment-tax.component.html',
  styleUrls: ['./confirm-installment-tax.component.sass']
})
export class ConfirmInstallmentTaxComponent implements OnInit {
  public screenActual = 1;
  public activateLine = false;
  public telemarketingPayTax;
  public accountNumber;
  public createdDate;
  public amount;
  public valuePayment;
  public valuePaymentSplit1;
  public valuePaymentSplit2;
  public subscriberEmail;
  public subscriberName;
  public subscriberLastName;
  public ban;
  paymentReceived: Subscription;
  splitPayment = false;
  private paySplit1 = false;
  private paySplit2 = false;
  private paySplit1Completed = false;
  private paySplit2Completed = false;

  private paymentToken;
  private paymentQuota;
  private instllmentUnits;

  private numConfirmationPaySplit1;
  private numAuthorizationPaySplit1;
  private dateProvisioningPaySplit1;
  private paymentMethodDisplayPaySplit1;

  private numConfirmationPaySplit2;
  private numAuthorizationPaySplit2;
  private dateProvisioningPaySplit2;
  private paymentMethodDisplayPaySplit2;

  private transactionId;

  private redirectURL;

  public amountToPay;
  private numAuthorization = null;
  private currentPaymentToken = null;
  // tslint:disable-next-line:max-line-length
  private enableRefresButton = false;
  public paymentValue = null;
  private verifyPaymentStatus = null;
  // tslint:disable-next-line:max-line-length
  constructor(private loadingScreenService: LoadingScreenService, public global: GlobalService, private router: Router, private services: ApiService, public dialog: MatDialog) { }

  ngOnInit() {
    this.paymentReceived = this.global.paymentReceived.subscribe((value: boolean) => {
      if (this.paymentValue === null || (this.paymentValue !== value)) {
        if (this.paymentValue !== true) {
          this.paymentValue = value;
          this.processPayment(value);
        }
      }
    });

    this.processOnInit();
  }

  private processOnInit() {
    // call getPaymentInfo
    this.paymentToken = sessionStorage.getItem('paymentToken');

    this.loadingScreenService.startLoading(true, 'Estamos verificando su pago ', true, null);
    this.services.getPaymentInfo(this.paymentToken).subscribe((value) => {
      this.loadingScreenService.stopLoading();
      if (value.hasError) {
        this.showDialogError(value.errorDisplay);
      } else {
        this.amount = parseFloat(value.paymentAmount);
        // tslint:disable-next-line:radix
        this.instllmentUnits = parseInt(value.installmentUnits);
        this.paymentQuota = parseFloat((this.amount / this.instllmentUnits).toString()).toFixed(2);

        this.valuePayment = this.amount;
        this.valuePaymentSplit1 = this.valuePayment;
        this.valuePaymentSplit2 = 0;

        this.redirectURL = value.redirectURL;

        // caso borde
        if (value.splitPaymentList.length > 0) {
          if (value.splitPaymentList[0].authorizationNumber !== '' && value.splitPaymentList[0].errorCode === '0000') {
            this.paySplit1Completed = true;
            this.splitPayment = true;

            this.valuePaymentSplit1 = parseFloat(value.splitPaymentList[0].paymentAmount);
            this.calculateValuePaymentSplit2();

            this.numConfirmationPaySplit1 = value.splitPaymentList[0].paymentConfirmationNumber;
            this.numAuthorizationPaySplit1 = value.splitPaymentList[0].authorizationNumber;
            this.dateProvisioningPaySplit1 = value.splitPaymentList[0].paymentDate;
            this.paymentMethodDisplayPaySplit1 = value.splitPaymentList[0].imageUrl;

            // tslint:disable-next-line:max-line-length
            this.services.findByAuth(this.numConfirmationPaySplit1, this.numAuthorizationPaySplit1, sessionStorage.getItem('token')).subscribe( data => {
              if (data.object.length > 0) {
                sessionStorage.setItem('transactionIdSplit1', data.object[0].id);
              }
            });
          }
        }
      }
    });

    this.subscriberEmail = JSON.parse(atob(sessionStorage.getItem('getSubscriber'))).accountInfo.email;
    this.subscriberName = JSON.parse(atob(sessionStorage.getItem('getSubscriber'))).accountInfo.firstName;
    this.subscriberLastName = JSON.parse(atob(sessionStorage.getItem('getSubscriber'))).accountInfo.lastName;
    this.accountNumber = JSON.parse(atob(sessionStorage.getItem('getSubscriber'))).accountInfo.BAN;
    this.ban = atob(sessionStorage.getItem('subscriber'));
  }

  private async processPayment(value: boolean) {
    this.verifyPaymentStatus = JSON.parse(sessionStorage.getItem('verifyPaymentStatus'));

    if (value) {
      this.loadingScreenService.stopLoading();

      if (!this.splitPayment) {
        this.global.notif('Se ha efectuado el pago de la factura.');
        this.numAuthorization = sessionStorage.getItem('numAuthorization');
        sessionStorage.setItem('transactionId', this.transactionId);
        this.screenActual = 2;
      } else {
        if (!this.paySplit1Completed) {
          this.paySplit1 = false;
          this.paySplit1Completed = true;

          this.numConfirmationPaySplit1 = sessionStorage.getItem('numConfirmation');
          this.numAuthorizationPaySplit1 = sessionStorage.getItem('numAuthorization');
          this.dateProvisioningPaySplit1 = this.verifyPaymentStatus.paymentDate;
          this.paymentMethodDisplayPaySplit1 = this.verifyPaymentStatus.paymentMethodImage;

          sessionStorage.setItem('transactionIdSplit1', this.transactionId);
          this.enableRefresButton = false;
        } else {
          this.paySplit2 = false;
          this.paySplit2Completed = true;

          this.numConfirmationPaySplit2 = sessionStorage.getItem('numConfirmation');
          this.numAuthorizationPaySplit2 = sessionStorage.getItem('numAuthorization');
          this.dateProvisioningPaySplit2 = this.verifyPaymentStatus.paymentDate;
          this.paymentMethodDisplayPaySplit2 = this.verifyPaymentStatus.paymentMethodImage;

          sessionStorage.setItem('transactionIdSplit2', this.transactionId);
          this.enableRefresButton = false;
        }
      }

    } else {

      // caso de error 129: expiracion de transaccion
      if (this.verifyPaymentStatus.errorNum === 129) {
        this.router.navigate(['']);
      }

      if (this.splitPayment) {
        if (!this.paySplit1Completed) {
          this.paySplit1 = false;
        } else {
          this.paySplit2 = false;
        }
      }

      this.enableRefresButton = true;
    }

    if (value) {
      this.loadingScreenService.startLoading();
      this.services.updateTransaction(
        this.transactionId,
        this.verifyPaymentStatus.errorCode === null ? '' : this.verifyPaymentStatus.errorCode,
        this.verifyPaymentStatus.confirmationNumber === null ? '' : this.verifyPaymentStatus.confirmationNumber,
        this.verifyPaymentStatus.authorizationNumber === null ? '' : this.verifyPaymentStatus.authorizationNumber,
        this.verifyPaymentStatus.paymentStatus + '###' + this.verifyPaymentStatus.provisioningStatus,
        this.verifyPaymentStatus.message === null ? '' : this.verifyPaymentStatus.message,
        this.getTransactionStatus(this.verifyPaymentStatus.paymentStatus, this.verifyPaymentStatus.provisioningStatus),
        sessionStorage.getItem('token'),
        sessionStorage.getItem('urlId'),
        this.amountToPay,
        'INSTALLMENT',
        this.paySplit1 ? 'split' : 'completed',
        JSON.parse(atob(sessionStorage.getItem('getSubscriber'))).accountInfo.accountType,
        JSON.parse(atob(sessionStorage.getItem('getSubscriber'))).accountInfo.accountSubtype,
        this.accountNumber,
        this.ban,
        this.amount,
        this.subscriberName + ' ' + this.subscriberLastName
      ).subscribe(data => {
        this.loadingScreenService.stopLoading();

        if (!value) {
          this.showDialogError('Disculpe, hemos tendido problemas procesando su pago, por favor llamar al 787-793-1253');
        }

      });
    }
  }

  public refresPayment(cont) {
    this.enableRefresButton = false;

    this.loadingScreenService.startLoading(true, 'Por favor espere, estamos verificando su pago.', false, null);

    setTimeout(() => {
      this.services.verifyPaymentStatus(this.currentPaymentToken).subscribe(result => {
        this.loadingScreenService.stopLoading();
        sessionStorage.setItem('verifyPaymentStatus', JSON.stringify(result));

        if (result.errorNum === 0) {
          if (result.processEndState) {
            if (!result.hasError) {
              if (result.errorCode === '') {
                sessionStorage.setItem('numConfirmation', result.confirmationNumber);
                sessionStorage.setItem('numAuthorization', result.authorizationNumber);
                this.paymentValue = null;
                this.processPayment(true);
              } else {
                this.notif(result.errorDisplay);
              }
            } else {
              this.notif(result.errorDisplay);
            }
          } else {
            if (new Date().getTime() < Date.parse(result.paymentAgedDate)) {
              cont++;
              this.refresPayment(cont);
            } else {
              // tslint:disable-next-line:max-line-length
              this.notif('Estimado cliente, hemos encontrado un problema verificar su pago.   Si su compra fue autorizada por el banco, y no la ve reflejada no esta reflejada en su cuenta, favor de consultarle a Centro de Servicio al cliente.');
            }
          }
        } else {
          this.notif(result.errorDisplay);
        }
      });
    }, 30000);
  }

  notif(msg) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {message: msg};
    this.dialog.open(AlertasComponent, dialogConfig);
  }

  private showDialogError(error: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    // tslint:disable-next-line:max-line-length
    dialogConfig.data = {message: error, colorSi : 'colorRed', colorNo: undefined, btmOk: true, btmConfirm: false, labelOk: 'Aceptar', labelNo: undefined};
    const dialogRef = this.dialog.open(AlertasComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(value => {
      this.router.navigate(['']);
    });
  }

  async goTo() {
    this.currentPaymentToken = null;
    this.paymentValue = null;
    this.amountToPay = this.valuePayment;
    // tslint:disable-next-line:max-line-length
    const transaction = await this.services.createTransaction('MEPMMTCRDT', 'INSTALLMENT', this.paymentToken, sessionStorage.getItem('token')).toPromise();
    // @ts-ignore
    this.transactionId = transaction.object.id;

    const typeMessage = 'payment';
    this.paymentToken = sessionStorage.getItem('paymentToken');
    this.currentPaymentToken = this.paymentToken;
    // tslint:disable-next-line:max-line-length
    this.global.newPayment(5, typeMessage, this.accountNumber, this.ban, this.valuePayment, this.subscriberEmail, this.subscriberName + ' ' + this.subscriberLastName, '', this.paymentToken, '', this.redirectURL);
  }
  goToTest() {
    
  }

  backToOptions() {
    this.router.navigate(['postpaid_select_option']);
  }
  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy() {
    this.paymentReceived.unsubscribe();
  }

  async goPaySplit1() {
    if (this.validateAmount()) {

      this.paymentValue = null;
      this.amountToPay = this.valuePaymentSplit1;

      this.loadingScreenService.startLoading();
      const value = await this.services.addChildPayment(this.paymentToken, this.valuePaymentSplit1).toPromise();
      this.loadingScreenService.stopLoading();

      if (!value.hasError && value.errorNum === 0) {
        this.paySplit1 = true;
        const typeMessage = 'payment';
        this.redirectURL = value.redirectURL;
        this.currentPaymentToken = value.childPaymentToken;

        this.loadingScreenService.startLoading();
        // tslint:disable-next-line:max-line-length
        const transaction = await this.services.createTransaction('MEPMMTCRDT', 'INSTALLMENT', value.childPaymentToken, sessionStorage.getItem('token')).toPromise();
        this.loadingScreenService.stopLoading();
        // @ts-ignore
        this.transactionId = transaction.object.id;

        // tslint:disable-next-line:max-line-length
        this.global.newPayment(5, typeMessage, this.accountNumber, this.ban, this.valuePaymentSplit1, this.subscriberEmail, this.subscriberName + ' ' + this.subscriberLastName, '', value.childPaymentToken, '', this.redirectURL);
      } else {
        if (value.errorNum === 130) {
          this.refresPayment(0);
        } else {
          this.currentPaymentToken = null;
          this.global.notif('Ha Ocurrido un error Inicializando el pago, por favor intente nuevamente');
        }
      }
    } else {
      this.showValidations();
    }
  }

  private showValidations() {
    let messageValidation = '- El monto no puede ser ' + parseFloat((this.amount / 2).toString()).toFixed(2) + '<br>';
    messageValidation += '- El monto debe ser mayor a 5 <br>';
    messageValidation += '- El monto debe ser menor a ' + (this.amount - 5) + '<br>';

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    // tslint:disable-next-line:max-line-length
    dialogConfig.data = {message: messageValidation, colorSi : 'colorRed', colorNo: undefined, btmOk: true, btmConfirm: false, labelOk: 'Aceptar', labelNo: undefined, messageHtml: true};
    const dialogRef = this.dialog.open(AlertasComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(value => {
    });
  }

  async goPaySplit2() {
    if (this.validateAmount()) {
      this.paymentValue = null;
      this.amountToPay = this.valuePaymentSplit2;

      this.loadingScreenService.startLoading();
      const value = await this.services.addChildPayment(this.paymentToken, this.valuePaymentSplit2).toPromise();
      this.loadingScreenService.stopLoading();

      if (!value.hasError && value.errorNum === 0) {
        this.paySplit2 = true;
        const typeMessage = 'payment';
        this.redirectURL = value.redirectURL;

        this.currentPaymentToken = value.childPaymentToken;

        this.loadingScreenService.startLoading();
        // tslint:disable-next-line:max-line-length
        const transaction = await this.services.createTransaction('MEPMMTCRDT', 'INSTALLMENT', value.childPaymentToken, sessionStorage.getItem('token')).toPromise();
        this.loadingScreenService.stopLoading();
        // @ts-ignore
        this.transactionId = transaction.object.id;

        // tslint:disable-next-line:max-line-length
        this.global.newPayment(5, typeMessage, this.accountNumber, this.ban, this.valuePaymentSplit2, this.subscriberEmail, this.subscriberName + ' ' + this.subscriberLastName, '', value.childPaymentToken, '', this.redirectURL);
      } else {
        if (value.errorNum === 130) {
          this.refresPayment(0);
        } else {
          this.currentPaymentToken = null;
          this.global.notif('Ha Ocurrido un error Inicializando el pago, por favor intente nuevamente');
        }
      }
    }
  }

  validateAmount() {
    if (this.valuePaymentSplit1 === this.amount) {
      return false;
    }
    if (this.valuePaymentSplit1 === (this.amount / 2)) {
      return false;
    }
    if (this.valuePaymentSplit1 < 5) {
      return false;
    }
    if (this.valuePaymentSplit1 > this.amount) {
      return false;
    }
    if (this.amount - this.valuePaymentSplit1 < 5) {
      return false;
    }
    return true;
  }

  calculateValuePaymentSplit2() {
    this.valuePaymentSplit2 = parseFloat((this.amount - this.valuePaymentSplit1).toString()).toFixed(2);
  }

  async goToSplitPaymentFinished() {
    this.loadingScreenService.startLoading(true, 'Estamos verificando su pago ', true, null);
    setTimeout(() => {
      this.loadingScreenService.stopLoading();
      this.processGoToSplitPaymentFinished();
    }, 15000);
  }

  async processGoToSplitPaymentFinished() {
    // call parent
    this.verifyPaymentStatus = await this.services.verifyPaymentStatus(this.paymentToken).toPromise();
    this.global.notif('Se ha efectuado el pago de la factura.');
    this.screenActual = 2;
  }

  private getTransactionStatus(paymentStatus: string, provisioningStatus: string) {
    if (paymentStatus === 'BANK SUCCESS NOT PROVISIONABLE' && provisioningStatus === 'SPLIT TRANSACTION NOT PROVISIONABLE') {
      return 'approved';
    }
    if (paymentStatus === 'BANK PAYMENT SUCCESS' && provisioningStatus === 'PROVISIONED') {
      return 'provisioning';
    }
    if (paymentStatus === 'BANK PAYMENT SUCCESS' && provisioningStatus === 'EXTERNALLY PROVISIONED') {
      return 'provisioning';
    }
    if (paymentStatus === 'BANK PAYMENT SUCCESS' && provisioningStatus === 'PROVISIONING FAILED') {
      return 'noprovisioning';
    }
    return 'rejected';
  }

  verifyIncorrectValueSplit1() {
    if (this.valuePaymentSplit1 < 0) {
      this.valuePaymentSplit1 = 0;
      this.calculateValuePaymentSplit2();
    }

    if (this.valuePaymentSplit1 > this.amount) {
      this.valuePaymentSplit1 = this.amount;
      this.calculateValuePaymentSplit2();
    }
  }
}
