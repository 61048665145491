<div class="wrapper">
    <app-loading-screen></app-loading-screen>
    <!--Se pasa a componente despues-->
    <app-header></app-header>
    <!--Inicia componente del paso a paso-->
    <app-step [step]="2"></app-step>
    <!--Inicio contenido del modulo-->
    <div class="allcont footer-fixed f-black f-reg roboto-r">
        <div class="container">
            <div class="basicrow f-bigtitle roboto-l">
                Opciones
            </div>
            <!-- <div class="opts-i" id="pay_to_account" *ngIf="this.telemarketingPayTax!=null || paymentToken != null" (click)="this.goToPendingPayment();">
                Efectuar Pago Orden Pendiente
                <i class="fa fa-angle-right" aria-hidden="true"></i>
            </div> -->
            <div class="opts-i" id="pay_to_account" (click)="this.goToPayment();">
                Efectuar Pago Factura
                <i class="fa fa-angle-right" aria-hidden="true"></i>
            </div>
<!--            <div class="opts-i" id="buy_additional_packages" (click)="this.goToPackages();">-->
<!--                Compra Paquete Data Adicional-->
<!--                <i class="fa fa-angle-right" aria-hidden="true"></i>-->
<!--            </div>-->

            <div class="basicrow text-center m-top">
                <div class="graybtn next no-m" id="back_btn" (click)="this.backToLogin();"><i class="fa fa-angle-left lefticon" aria-hidden="true"></i> Volver</div>
            </div>
        </div>
    </div>
    <!--Inicia el footer-->
    <app-footer></app-footer>
</div>
