<div class="wrapper">
    <!--Se pasa a componente despues-->
    <div class="menucont">
        <div class="miclaro-logo vcenter">
            <div class="tabcell">
                <img class="nopointer" width="100%" src="../assets/images/miclaro-logo.png">
            </div>
        </div>
        <div class="time hidden-xs">
            <div class="clockcont">
                <div class="clock pull-left">
                    <!-- TODO -->
                    <div class="sh-" id="second-hand"></div>
                    <div class="mh-" id="minute-hand"></div>
                    <div class="hh-" id="hour-hand"></div>
                </div>
            </div>
            <div class="timecont">
                <div class="t-line"></div>
                <span class="f-date" id="date"></span><br />
                <span class="f-time" id="clock"></span>
                <div class="f-time-i" id="time"></div>
            </div>
        </div>
        <div class="autocont full pull-right vcenter">
            <div class="tabcell">
                <div class="btn-group pull-right off-z-index" data-toggle="buttons">
                    <label class="btn btn-danger active" data-langcode="ES">
                        <input type="checkbox" autocomplete="off"> ES
                    </label>
                    <label class="btn btn-danger" data-langcode="EN">
                        <input type="checkbox" autocomplete="off"> EN
                    </label>
                </div>
            </div>
        </div>
    </div>
    <!--Inicia componente del paso a paso-->
    <div class="steps">
        <div id="step-div" class="step-div step-1">
            <div class="def-step">
                <span class="stepcirc text-center vcenter">
                    <span class="tabcell">
                        1
                    </span>
                </span>
                <span class="hidden-xs">Ingrese datos</span>
            </div>
            <div class="stepbar"></div>
        </div>
        <div id="step-div" class="step-div step-2">
            <div class="def-step">
                <span class="stepcirc text-center vcenter">
                    <span class="tabcell">
                        2
                    </span>
                </span>
                <span class="hidden-xs">Monto a pagar</span>
            </div>
            <div class="stepbar"></div>
        </div>
        <div id="step-div" class="step-div step-3">
            <div class="def-step">
                <span class="stepcirc text-center vcenter">
                    <span class="tabcell">
                        3
                    </span>
                </span>
                <span class="hidden-xs">Pago</span>
            </div>
            <div class="stepbar"></div>
        </div>
        <div id="step-div" class="step-div step-4">
            <div class="def-step">
                <span class="stepcirc text-center vcenter">
                    <span class="tabcell">
                        4
                    </span>
                </span>
                <span class="hidden-xs">Confirmación</span>
            </div>
            <div class="stepbar"></div>
        </div>
    </div>
    <!--Inicia contenido del modulo-->
    <div class="nopad f-black f-reg roboto-r">
        <div class="container">
            <div class="basicrow">
                <div class="autocont pull-left f-bigtitle roboto-l">
                    Actualiza Tu Correo Electr&oacute;nico
                </div>
            </div>
            <div class="basicrow bg-gray-u-i allpads bordsdef m-top-ii roboto-b text-justify letrero">
                Mant&eacute;n actualizado tu correo electr&oacute;nico por el cual podr&aacute;s recibir tu factura de
                manera electr&oacute;nica.
            </div>
            <div class="basicrow m-top-i">
                <div class="basicrow">
                    <label class="label-f">
                        &#42; Correo Electr&oacute;nico Actual
                    </label>
                    <input class="inp-f off" [(ngModel)]="this.email">
                </div>
                <div class="basicrow m-top-i">
                    <label class="label-f">
                        &#42; Nuevo Correo Electr&oacute;nico
                    </label>
                    <input placeholder="Ingresa el nuevo correo" class="inp-f" [(ngModel)]="this.email1">
                </div>
                <div class="basicrow m-top-i">
                    <label class="label-f">
                        &#42; Confirmar Correo Electr&oacute;nico
                    </label>
                    <input placeholder="Confirma el nuevo correo" class="inp-f" [(ngModel)]="this.email2">
                </div>
            </div>
            <div class="basicrow m-top-i">
                <div class="logline full"></div>
            </div>
            <div class="basicrow m-top-i text-center">
                <a class="btns def-iv red centr vcenter">
                    <span class="tabcell" (click)="this.continueEmail();this.submitted==true; ">
                        Guardar Cambios&nbsp;&nbsp;<i class="fa fa-angle-right" aria-hidden="true"></i>
                    </span>
                </a>
            </div>
        </div>
    </div>
    <!--Inicia el footer-->
    <div class="footcont">
        <div class="autocont full vcenter pull-left">
            <div class="tabcell">
                <img class="footlogo nopointer" width="100%" src="../assets/images/foot-logo.png">
            </div>
        </div>
        <div class="autocont full vcenter pull-left">
            <div class="tabcell">
                Todos los derechos reservados, Claro 2020
            </div>
        </div>
    </div>
</div>