import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PaymentIframeService } from './payment-iframe.services';
import { LoadingScreenService } from 'src/app/core/services/loading-screen/loading-screen.service';
@Component({
  selector: 'app-iframe-payment',
  templateUrl: './iframe-payment.component.html',
  styleUrls: ['./iframe-payment.component.sass']
})
export class IframePaymentComponent implements OnInit {
  @Input() tokenSSO: string = '';
  @Input() transactionType?:string = 'payment';
  @Input() startTime?:number=0;
  @Input() url: string = '';
  @Input() width: string = '100%';
  @Input() height: string = '460px';
  @Input() frameborder:any = 1;
  @Input() scrolling: string = '';
  @Input() token?:any='';
  @Input() firstName:any;
  @Input() lastName:any;
  @Input() email:any;
  @Input() amount:any;
  @Input() selectBan:any;
  @Input() currentSuscriber:any;
  @Input() locationId:any;
  @Input() invoiceNumber:any;
  @Input() installmentCount:any;
  @Input() provision:any=true;
  @Input() displayConfirmation:any=true;
  @Input() emailNotification:any=true;
  @Input() showInstrument:any=true;
  @Input() stroeInstrument:any=true;
  @Input() useBanZipCode:any=true;
  @Output() result = new EventEmitter();
  @Output() outLoadIframe = new EventEmitter();

  @Output() initPayment = new EventEmitter();
  @Output() cancelPayment = new EventEmitter();
  @Output() hasDimensions = new EventEmitter();
  @Output() errorMessage = new EventEmitter();
  public errorTime:number=10000;
  public urlSafe: SafeResourceUrl;
  public loadIframe: Boolean = false;
  public authorizationNumber: string = '';
  public code: string = '';
  public date: string = '';
  public description: string = '';
  public operationId: string = '';
  public operationType: string = '';
  public paymentMethod: string = '';
  public success: boolean = false;
  public provisioning: any;
  public storedInstrument: any;
  public dataResult: any;
  public paymentCard: any;
  public showAccordion=true;
  public state: string='';
  public messageArrived: boolean=false;
  public textMessageError='<div>En este momento estamos presentando inconvenientes en nuestro sistema.</div><div> Por favor, íntentelo nuevamente.</div>';
  @ViewChild('iframePaymentId') iframe2: ElementRef;
  @HostListener('window:message', ['$event']) onPostMessage(e:any) {
    if (typeof e.data != "object") {
      try {
        this.dataResult = JSON.parse(atob(e.data));
        if(this.dataResult.state=="dimensions"){
          this.messageArrived=true;
          this.height=this.dataResult.data.height;
          this.hasDimensions.next(this.height);
        }
        if(this.dataResult.state=="start"){
            this.initPayment.next();
        }
        if(this.dataResult.state=="canceled"){
          this.cancelPayment.next();
        }
        if (this.dataResult.state == "ath") {
          this.loadingScreenService.startLoading();
           this.service.athPinpadURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.dataResult.data.athURL);
           sessionStorage.setItem('paymentId', this.dataResult.data.paymentID);
         setTimeout(()=>{
          this.loadingScreenService.stopLoading();
         }, 3000)
        }
        if(this.dataResult.state=="paymentResult"){
          if(this.dataResult.data!=""){
            const stringData = this.dataResult.data
            const result = JSON.parse(stringData).paymentResult
            this.dataResult=JSON.parse(atob(result));
            if(this.dataResult.success==true){
              this.authorizationNumber = this.dataResult.authorizationNumber;
              this.code = this.dataResult.code;
              this.date = this.dataResult.date;
              this.description = this.dataResult.description;
              this.operationId = this.dataResult.operationId;
              this.operationType = this.dataResult.operationType;
              this.paymentMethod = this.dataResult.paymentMethod;
              this.success = this.dataResult.success;
              this.provisioning = this.dataResult.provisioning;
              this.storedInstrument = this.dataResult.storedInstrument;
              this.paymentCard= this.dataResult.paymentCard;
              this.result.next(this.dataResult);
            }
            else{
              this.operationId = this.dataResult.operationId;
              if(this.dataResult.description!=""){
                this.textMessageError=this.dataResult.description;
              }
              this.code = this.dataResult.code;
              this.state='error';
            }
          }
          else{
            this.dataResult.description=this.textMessageError;
          }
          this.result.next(this.dataResult);
        }
      }
      catch (e) {
        console.log(e);
      }
    }
  }
  constructor(public sanitizer: DomSanitizer, private service: PaymentIframeService, private loadingScreenService: LoadingScreenService) {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl('');
  }
  ngOnInit(): void {
    this.init();
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      if(this.messageArrived==false){
        this.state='error';
        this.errorMessage.next(true);
      }
    }, this.errorTime);
  }
  tryAgain(){
    this.init();
    this.state='';
  }
  init() {
    this.service.getIframe(this.token,this.tokenSSO,this.firstName,this.lastName,this.email,this.transactionType,this.amount,this.selectBan,this.currentSuscriber,this.provision,this.displayConfirmation,this.emailNotification,this.showInstrument,this.stroeInstrument,this.useBanZipCode,"","","","").subscribe((data: any) => {
      if (data.errorInfo) {
        if (data.errorInfo.hasError == false) {
          let newURL = data.url.toString().replace('https://solution.claropr.com','http://localhost:4201' ) // test local
          this.loadIframe = true;
          setTimeout(()=>{
            this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(data.url + "&showAccordion="+this.showAccordion+"&startTime="+this.startTime);
            // this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(newURL + "&showAccordion="+this.showAccordion+"&startTime="+this.startTime);
          }, 2000)
          setTimeout(() => {
            if(this.messageArrived==false) {
              this.state='error';
              this.errorMessage.next(true);
            }
          }, this.errorTime);
        }
        else {
          this.state='error';
          this.errorMessage.next(true);
        }
      }
      else{
        this.state='error';
        this.errorMessage.next(true);
      }
    });
  }
}
