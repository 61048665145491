import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalService } from 'src/app/core/services/global-services/global.service';
import { ExecutePaymentService } from '../../../../core/services/execute-payment/execute-payment.service';
import { Subject, Subscription } from 'rxjs';
import { LoadingScreenService } from 'src/app/core/services/loading-screen/loading-screen.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirm-prepaid-balance',
  templateUrl: './confirm-prepaid-balance.component.html',
  styleUrls: ['./confirm-prepaid-balance.component.sass']
})
export class ConfirmPrepaidBalanceComponent implements OnInit {
  @Output() goScreen = new EventEmitter();
  @Input() dataPrice;
  @Input() ivuState;
  @Input() ivuAmount;
  public totalToPay;
  public subscriberEmail;
  public banSelected;
  public subscriberName;
  public subscriberLastName;
  public subscriber;
  public token: string = sessionStorage.getItem('token');
  checkIsOpenPaymentModal: Subscription;
  isOpenPaymentModal = false;

  public firstNameIP='';
  public lastNameIP='';
  public emailIP='';
  public amountIP='';
  public selectBanIP='';
  public currentSuscriberIP='';
  @Output() selectBanIPOut = new EventEmitter();
  @Output() currentSuscriberIPOut = new EventEmitter();
  public locationIdIP='';
  public invoiceNumberIP='';
  public installmentCountIP=0;
  public authorizationNumber='';
  public referenceNumber='';
  public displayConfirmation:boolean=false;
  public loadIframe:Boolean=false;
  updatePayment = new Subject();
  private endOfPayment: boolean = false;
  private endOfProcesses: boolean = false;
  private resulPayment: any;
  public paymentStartTime: number = 0;
  constructor(private router: Router,private loadingScreenService: LoadingScreenService, public global: GlobalService, public executePaymentService: ExecutePaymentService) {
  }

  ngOnInit() {
    this.checkIsOpenPaymentModal = this.executePaymentService.isOpenPaymentModal.subscribe((value: boolean) => {
      this.isOpenPaymentModal = value;
    });
    this.updatePayment.subscribe((data: any) => {
      if (this.endOfProcesses == true && this.endOfPayment == true) {
        if (this.resulPayment.success == true) {

        }
        else {
          this.endOfPayment = false;
          this.paymentStartTime = 0;
        }
      }
    });
    this.global.setPaymentMethod();
    this.totalToPay = parseFloat(this.dataPrice) * ( 1 + parseFloat(this.ivuState));
    sessionStorage.setItem('amountToPay', this.totalToPay.toString());
    this.banSelected = JSON.parse(atob(sessionStorage.getItem('getSubscriber'))).accountInfo.BAN;
    this.subscriberEmail = JSON.parse(atob(sessionStorage.getItem('getSubscriber'))).accountInfo.email;
    this.subscriberName = JSON.parse(atob(sessionStorage.getItem('getSubscriber'))).accountInfo.firstName;
    this.subscriberLastName = JSON.parse(atob(sessionStorage.getItem('getSubscriber'))).accountInfo.lastName;
    this.subscriber = JSON.parse(atob(sessionStorage.getItem('getSubscriber'))).subscriberInfo[0].subscriberNumber;

    try {
      let objSubscriber=JSON.parse(atob(sessionStorage.getItem('getSubscriber')));
      this.firstNameIP=objSubscriber.accountInfo.firstName;
      this.lastNameIP=objSubscriber.accountInfo.lastName;
      if(objSubscriber.accountInfo.email!=undefined){
        this.emailIP = objSubscriber.accountInfo.email;
      }
      else{
        this.emailIP=this.global.emailIframePay;
      }
      this.selectBanIP = objSubscriber.accountInfo.BAN;
      this.currentSuscriberIP=objSubscriber.accountInfo.subscriberNumber;
      this.selectBanIPOut.next(this.selectBanIP);
      this.currentSuscriberIPOut.next(this.currentSuscriberIP);
      this.amountIP = this.totalToPay;
    }
    catch (error) {}
  }
  outLoadIframe(event:any){
    this.loadIframe=event;
  }
  goPayment() {
    // const typeMessage = 'prepaid';
    this.global.currentScreen= 2;
  }
  back() {
    this.global.loadFooter=false;
    this.global.isPrepaid = true;
    this.global.prepaidCode='';
    this.global.subscriberReceiver='';
    this.global.onPrepaidStep2=false;
    this.global.currentScreen=1;
    this.router.navigate(['login']);

  }
  errorMessage(e:any){
    this.loadingScreenService.stopLoading();
  }
  updateScreen(event: number) {
    this.goScreen.emit(event + 1);
  }
}
