<div id="wrapper">
  <app-loading-screen></app-loading-screen>
  <app-header></app-header>
  <app-step [step]="2"></app-step>
  <div
    class="allcont f-black footer-fixed-3 f-reg container-center container-resumen"
    *ngIf="this.global.currentScreen == 1"
  >
    <div class="container">
      <div class="basicrow">
        <div class="row">
          <div class="col">
            <div class="autocont pull-left f-bigtitle roboto-l">
              Resumen de factura
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col">
            <div class="payment-info-container">
              <div class="row payment-row first-row">
                <div class="col">
                  <div class="user-ban">
                    <div class="title-ban mb-3">
                      N&uacute;mero de cuenta:
                      <span class="ban">{{ banSelected }} </span>
                    </div>
                  </div>

                  <div class="date-info">
                    <div class="date-info-detail first mb-3">
                      <span class="date-label"
                        >fecha de facturaci&oacute;n</span
                      >
                      <span class="date-detail">{{ billDate }}</span>
                    </div>
                    <div class="date-info-detail date-last mb-3">
                      <span class="date-label">fecha de vencimiento</span>
                      <span class="date-detail">{{ billDueDate }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row payment-row">
                <div class="col">
                  <div class="invoice-amount">
                    Monto a pagar
                    <span class="amount-detal" *ngIf="this.amountToPay">
                      {{ this.amountToPay | currency }}</span
                    >
                    <span class="amount-detal" *ngIf="!this.amountToPay">
                      $0.00</span
                    >
                  </div>
                </div>
              </div>

              <div class="row payment-row last-row" *ngIf="!showPaymentAmounts">
                <div class="col">
                  <div class="amount-selection">
                    <div class="selection-title">
                      Selecciona el monto a pagar
                    </div>

                    <div
                      class="show-list-icon mt-3 mb-3"
                      style="cursor: pointer"
                      (click)="showPaymentAmounts = !showPaymentAmounts"
                    >
                      <img
                        src="../../../../assets/images/dropdown2.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row payment-row last-row" *ngIf="showPaymentAmounts">
                <div class="col">
                  <div class="amount-selection-opened">
                    <div class="selection-title black text-center mb-3">
                      Selecciona el monto a pagar
                    </div>

                    <div class="payment-cards-container">
                      <div class="box-info-list">
                        <div
                          class="mb-2"
                          (click)="selectAmount(1)"
                          [ngClass]="{
                            'box-info': optionSelected != 1,
                            'box-info-active': optionSelected == 1
                          }"
                        >
                          <div
                            [ngClass]="{
                              'info-mark': optionSelected != 1,
                              'info-mark-active': optionSelected == 1
                            }"
                          ></div>
                          <div class="payment-option-container">
                            <div class="title-option">Total</div>
                            <div class="amount-option">
                              {{ fixAmount(billBalance)  }}
                              <div class="span tooltip-info">
                                <img src="../../../../assets/images/tooltip.png" alt="" [title]="optionTitle1">
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="mb-2"
                          (click)="selectAmount(2)"
                          *ngIf="showMinum"
                          [ngClass]="{
                            'box-info': optionSelected != 2,
                            'box-info-active': optionSelected == 2
                          }"
                        >
                          <div
                            [ngClass]="{
                              'info-mark': optionSelected != 2,
                              'info-mark-active': optionSelected == 2
                            }"
                          ></div>
                          <div class="payment-option-container">
                            <div class="title-option">Mínimo</div>
                            <div class="amount-option">
                              {{ fixAmount(validateMinimun())  }}
                              <div class="span tooltip-info">
                                <img src="../../../../assets/images/tooltip.png" alt="" [title]="optionTitle2">
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          *ngIf="canChangeAmount"
                          class="mb-2"
                          (click)="selectAmount(3)"
                          [ngClass]="{
                            'box-info': optionSelected != 3,
                            'box-info-active': optionSelected == 3
                          }"
                          style="padding: unset"
                        >
                          <div
                            [ngClass]="{
                              'info-mark': optionSelected != 3,
                              'info-mark-active': optionSelected == 3
                            }"
                          ></div>
                          <div class="payment-option-container-with-input">
                            <div
                              class="title-option"
                              style="
                                padding: 1rem 1.2rem;
                                border-right: solid 3px #bebebe;
                              "
                            >
                            Otro
                            </div>
                            <div
                              class="input-option end-fix"
                              style="padding: 1rem 1.2rem"
                            >
                              <span *ngIf="!showOtherAmount "> 0.00</span>

                              <div class="end-fix" *ngIf="showOtherAmount && canChangeAmount">
                                <input
                                  [appOnlyAmount]
                                  [(ngModel)]="this.amountToPay"
                                  [disabled]="!allowChangeValue"
                                  class=""
                                  type="text"
                                  id="payment-amount"
                                  style="width: 85px; height: 30px"
                                />
                              </div>
                              <div class="span tooltip-info">
                                <img src="../../../../assets/images/tooltip.png" alt=""  [title]="optionTitle3">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="amount-selection">
                      <div
                      class="show-list-icon mt-3 mb-3"
                      style="cursor: pointer"
                      (click)="showPaymentAmounts = !showPaymentAmounts"
                    >
                      <img
                        src="../../../../assets/images/dropdown1.png"
                        alt=""
                      />
                    </div>
                    </div>
                   
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div class="actions">
                    <div
                      class="btn-pay mb-2"
                      (click)="this.goTo()"
                      [ngClass]="{
                        'active-payment-option': optionSelected
                      }"
                    >
                      Pagar Factura
                    </div>

                    <div
                      class="btn-cancel-operation"
                      (click)="this.backToOptions()"
                    >
                      Cancelar
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="allcont f-black f-reg roboto-r container-center">
    <div class="container" *ngIf="this.global.currentScreen == 2">
      <app-iframe-payment
        [transactionType]="'payment'"
        [startTime]="this.paymentStartTime"
        [width]="this.global.widthIframePay"
        [height]="this.global.heightIframePay"
        [frameborder]="this.global.frameborderIframePay"
        [scrolling]="this.global.scrollingIframePay"
        [token]="this.tokenIframePay"
        [tokenSSO]="''"
        [firstName]="this.firstNameIP"
        [lastName]="this.lastNameIP"
        [email]="this.emailIP"
        [amount]="this.amountIP"
        [selectBan]="this.selectBanIP"
        [currentSuscriber]="this.currentSuscriberIP"
        [locationId]="this.locationIdIP"
        [invoiceNumber]="this.invoiceNumberIP"
        [installmentCount]="this.installmentCountIP"
        [displayConfirmation]="this.displayConfirmation"
        (result)="updateResult($event)"
        (initPayment)="this.global.initPayment()"
        (cancelPayment)="this.backTo()"
        (outLoadIframe)="this.outLoadIframe($event)"
        (errorMessage)="this.errorMessage($event)"
        (hasDimensions)="this.global.hasDimensions($event)"
      >
      </app-iframe-payment>
    </div>
    <div *ngIf="this.global.currentScreen == 3">
      <app-bill
        [banSelected]="this.selectBanIP"
        [subscriberSelected]="this.currentSuscriberIP"
        [amountPay]="amountToPay"
        [confirmNumber]="this.global.authorizationNumber"
        [confirmNumber2]="this.global.referenceNumber"
      >
      </app-bill>
    </div>
    <div *ngIf="this.global.currentScreen == 4">
      <app-payment-declined
        (currentScreen)="
          this.global.currentScreen = $event; this.global.loadFooter = false
        "
      ></app-payment-declined>
    </div>
    <div
      class="allcont f-black f-reg roboto-r"
      *ngIf="this.global.currentScreen == 2"
    >
      <div class="container">
        <div class="basicrow text-center">
          <div class="graybtn next no-m" (click)="this.backTo()">
            <i
              _ngcontent-beq-c286=""
              aria-hidden="true"
              class="fa fa-angle-left lefticon"
            ></i>
            Volver
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer
    *ngIf="this.global.currentScreen != 2 || this.global.loadFooter == true"
  ></app-footer>
</div>
