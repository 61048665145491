<div class="steps">
    <div id="step-div" class="step-div step-1 on">
        <div class="def-step">
            <span class="stepcirc text-center vcenter">
                <span class="tabcell">
                    1
                </span>
            </span>
            <span class="hidden-xs">Ingrese datos</span>
        </div>
        <div class="stepbar"></div>
    </div>
    <div id="step-div" class="step-div step-2 on">
        <div class="def-step">
            <span class="stepcirc text-center vcenter">
                <span class="tabcell">
                    2
                </span>
            </span>
            <span class="hidden-xs">Monto a pagar</span>
        </div>
        <div class="stepbar"></div>
    </div>
    <div id="step-div" class="step-div step-3 on">
        <div class="def-step">
            <span class="stepcirc text-center vcenter">
                <span class="tabcell">
                    3
                </span>
            </span>
            <span class="hidden-xs">Pago</span>
        </div>
        <div class="stepbar"></div>
    </div>
    <div id="step-div" class="step-div step-4 on">
        <div class="def-step">
            <span class="stepcirc text-center vcenter">
                <span class="tabcell">
                    4
                </span>
            </span>
            <span class="hidden-xs">Confirmación</span>
        </div>
        <div class="stepbar"></div>
    </div>
</div>
<div class="nopad f-black f-reg roboto-r">
    <div class="container new-w">
        <div class="basicrow text-center">
            <div class="r-title roboto-r vcenter">
                <img class="r-img" width="100%" src="../assets/images/claro-lines.png">
                <div class="tabcell bottom">
                    <span class="roboto-b">&#161;Gracias&#33;</span> Su pago fue exitoso
                </div>
            </div>
        </div>
        <div class="basicrow">
            <div class="conf-cont roboto-r">
                <i class="fa fa-check-circle conf-icon" aria-hidden="true"></i>
                Número de Confirmación: <span class="roboto-b">{{this.confirmNumber}}</span><br/>
                Cuenta Termina en: <span class="roboto-b">{{this.readLastFour(this.banSelected)}}</span><br/>
                Nro de Tel&eacute;fono : <span class="roboto-b">{{this.subscriberActual}}</span><br/>
                Tipo de Paquete: <span class="roboto-b">Local</span><br/>
                Paquete: <span class="roboto-b">{{this.namePackage}}</span><br/>
            </div>
            <div class="conf-cont-i text-center vcenter roboto-r">
                <div class="tabcell">
                    <span class="f-red roboto-b">
                        Monto Pagado:<br/>
                        <span class="conf-n">{{this.pricePackage|currency}}</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="basicrow">
    <div class="container new-w">
        <div class="linesprt"></div>
    </div>
</div>
<div class="allcont f-black f-reg roboto-r">
    <div class="basicrow text-center">
        <a href="https://www.claropr.com/" target="_blank" rel="noopener noreferrer">
            <img class="appban" width="100%" src="../assets/images/appbanner.png">
            <img class="appban-i" width="100%" src="../assets/images/appbannerv2.png">
        </a>
    </div>
    <div class="basicrow m-top text-center">
        <div class="container">
            <div class="graybtn home hidden-xs" id="close" (click)="this.goHome();">Inicio <i class="fa fa-angle-right righticon" aria-hidden="true"></i></div>
            <a href="https://miclaro.claropr.com/register" class="redbtn r-choice" target="_blank" rel="noopener noreferrer">
                Registro <i class="fa fa-angle-right righticon" aria-hidden="true"></i>
            </a>
        </div>
    </div>
</div>
