import { Component, OnInit } from '@angular/core';
import { Renderer2 } from '@angular/core';
import chat from '../../../assets/chat.js';
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.sass']
})
export class ChatComponent implements OnInit {
  public objChat: chat = new chat();
  constructor(private renderer: Renderer2) { }
  ngOnInit() {
    const script = this.renderer.createElement('script');
    script.src = '../../../assets/chat.js';
    script.onload = () => {
      this.objChat.type='pop';
      this.objChat.title='Mi Claro Express';
      this.objChat.token='';
      this.objChat.SSOAccessToken='';
      this.objChat.region='';
      this.objChat.category='599';
      this.objChat.id='e2f7386f-32d3-44ed-8088-0133d1191124';
      this.objChat.origin='12';
      this.objChat.customerName='';
      this.objChat.accountNumber='';
      this.objChat.email='';
      this.objChat.appType='Web';
    };
    script.onerror = () => {
      console.log('Error al cargar el archivo JavaScript.');
    };
    this.renderer.appendChild(document.body, script); 
  }
  goToChat(){
   this.objChat.getChat();
  }
}