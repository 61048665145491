import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrepaidBuyBalanceComponent } from './prepaid-buy-balance.component';
import { ConfirmPrepaidBalanceComponent } from './components/confirm-prepaid-balance/confirm-prepaid-balance.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PostpaidModule } from '../postpaid/postpaid.module';



@NgModule({
  declarations: [
    PrepaidBuyBalanceComponent,
    ConfirmPrepaidBalanceComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    PostpaidModule,
  ],
  exports: [
    PrepaidBuyBalanceComponent,
    ConfirmPrepaidBalanceComponent
  ]
})
export class PrepaidBuyBalanceModule { }
