import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { GlobalService } from '../../../core/services/global-services/global.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.sass']
})
export class StepComponent implements OnInit, OnChanges {
  @Input() step;
  checkStep: Subscription;
  second = '';
  third = '';
  four = '';
  constructor(public global: GlobalService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {
  this.setClass(Number(this.step));
  this.checkStep = this.global.stepCheck.subscribe((value: number) => {
    this.setClass(value);
  });
  }

  setClass(step) {
    this.second = '';
    this.third = '';

    if (step === 2) {
      this.second = 'on';
      this.third = '';
    }
    if (step === 3) {
      this.second = 'on';
      this.third = 'on';
    }
    if (step === 4) {
      this.second = 'on';
      this.third = 'on';
      this.four = 'on';
    }
  }

}
