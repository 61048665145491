class chat {
    constructor() {
        this.serverUrl = 'http://chatqa.com/api/chat/chatUrl';
        this.type = '';
        this.title = '';
        this.token = '';
        this.SSOAccessToken = '';
        this.region = '';
        this.category = '';
        this.id = '';
        this.origin = '';
        this.customerName = '';
        this.accountNumber = '';
        this.email = '';
        this.appType = '';
    }
    irChatURL() {
        const params = new URLSearchParams(window.location.search);
        this.type = params.get('type');
        this.title = params.get('title');
        this.token = params.get('token');
        this.SSOAccessToken = params.get('SSOAccessToken');
        this.region = params.get('region');
        this.category = params.get('category');
        this.id = params.get('id');
        this.origin = params.get('origin');
        this.customerName = params.get('customerName');
        this.accountNumber = params.get('accountNumber');
        this.email = params.get('email');
        this.appType = params.get('appType');
        this.getChat();
    }
    goToChat() {
        this.getChat();
    }
    getChat() {
        const datos = {
            token: this.SSOAccessToken,
            Region: this.region,
            Category: this.category,
            Id: this.id,
            Origin: this.origin,
            CustomerName: this.customerName,
            AccountNumber: this.accountNumber,
            Email: this.email,
            AppType: this.appType
        };
        console.log(datos);
        fetch(this.serverUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(datos)
        })
        .then(response => response.json())
        .then(data => {
            if (data.hasError == false) {
                this.loadChat(data.url, this.title, this.type);
            }
            else {
                alert(data.errorDisplay)
            }
        })
        .catch(error => {
            alert('Error  durante la solicitud del chat.', error);
        });
    }
    loadChat(url, title = '', type = '') {
        if (type == 'pop') {
            var wind = window.open(url, title, 'width=400,height=580,scrollbars=NO');
            if (wind == null) {
                alert('The popup has been blocked please unblock it in the browser settings.');
            }
        }
        else {
            window.location.href = url;
        }
    }
}
export default chat;