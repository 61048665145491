import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../../../../../core/services/global-services/global.service';
import { ApiService } from '../../../../../core/services/api-services/api.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AlertasComponent } from '../../../../../shared/components/alertas/alertas.component';
import { ExecutePaymentService } from '../../../../../core/services/execute-payment/execute-payment.service';
import { LoadingScreenService } from 'src/app/core/services/loading-screen/loading-screen.service';

@Component({
  selector: 'app-bill',
  templateUrl: './bill.component.html',
  styleUrls: ['./bill.component.sass']
})
export class BillComponent implements OnInit {
  @Input() banSelected;
  @Input() subscriberSelected;
  @Input() amountPay;
  @Input() confirmNumber;
  @Input() confirmNumber2;
  @Input() refundInfo;
  public emailCorr = '';
  public insNumTel = '';

  constructor(private router: Router,
              public global: GlobalService,
              private services: ApiService,
              public dialog: MatDialog,
              public executePaymentService: ExecutePaymentService,private loadingScreenService: LoadingScreenService) {
  }

  ngOnInit() {
    if (this.executePaymentService.isPrepaid) {
      //this.banSelected = this.executePaymentService.subscriberInfo.subscriberNumber;
    } else {
      //this.banSelected = this.executePaymentService.userData.accountInfo.BAN;
    }
    //this.confirmNumber = sessionStorage.getItem('numConfirmation');
    //this.confirmNumber2 = (this.confirmNumber2 === undefined || this.confirmNumber2 === null) ? null : this.confirmNumber2;
  }

  goHome() {
    this.global.setStep(2);
    this.global.currentScreen=1;

    this.global.isPrepaid = true;
    this.global.prepaidCode='';
    this.global.subscriberReceiver='';
    this.global.onPrepaidStep2=false;
    this.global.isStep1 = true;
    this.global.isPrepaid = false;
    this.global.onPrepaidStep2 = false;
    sessionStorage.removeItem('numConfirmation');
    if (this.executePaymentService.isPrepaid) {
      //this.router.navigate(['prepaid_buy_balance']);
      this.router.navigate(['/']);
    } else {
      this.router.navigate(['/']);
      //this.router.navigate(['postpaid_select_option']);
    }
  }

  readLastFour(data) {
    if (!!data) {
      const start = data.substring(5, 9);
      return start;
    }
  }

  async enviarNumtel() {
    if (this.insNumTel == '') {
      this.global.notif('Debe ingresar su telefono');
    } else {
      this.loadingScreenService.startLoading()
      await this.services.sendSms(this.insNumTel, 'ES', sessionStorage.getItem('transactionId'), sessionStorage.getItem('token')).toPromise();
      this.global.notif('SMS enviado con éxito');
      this.loadingScreenService.stopLoading();
    }
  }

  async verificarEmail() {
    if (this.emailCorr === '') {
      this.global.notif('Debe ingresar su e-mail');
    } else if (this.global.validarEmail(this.emailCorr) === false) {
      this.global.notif('Estimado cliente, el Correo Electrónico tiene un formato incorrecto.');
    } else {
      this.loadingScreenService.startLoading();
      await this.services.sendEmail(this.emailCorr, 'ES', sessionStorage.getItem('transactionId'), sessionStorage.getItem('token')).toPromise();
      this.global.notif('Email enviado con éxito');
      this.loadingScreenService.stopLoading();
    }
  }

  displayRefundInfo() {
    let messageValidation = '';
    // no split
    if (this.confirmNumber2 === null) {
      messageValidation += '- N&uacute;mero de Refrencia de Reverso: ' + this.refundInfo.voidedInformation[0].requestId + ' <br>';
      messageValidation += '- N&uacute;mero de Traza en Banco: ' + this.refundInfo.voidedInformation[0].systemTrace + ' <br>';
      messageValidation += '- Fecha de Reverso: ' + this.refundInfo.voidedInformation[0].voidedDate + ' <br>';
      // tslint:disable-next-line:max-line-length
      messageValidation += '- Estatus del Reverso: ' + this.refundInfo.voidedInformation[0].rCode + ' ' + this.refundInfo.voidedInformation[0].rMsg + ' <br>';
    } else {
      if (this.refundInfo.splitPaymentList[0].voidedInformation[0].isVoided) {
        // tslint:disable-next-line:max-line-length
        messageValidation += '- N&uacute;mero de Refrencia de Reverso 1: ' + this.refundInfo.splitPaymentList[0].voidedInformation[0].requestId + ' <br>';
        // tslint:disable-next-line:max-line-length
        messageValidation += '- N&uacute;mero de Traza en Banco 1: ' + this.refundInfo.splitPaymentList[0].voidedInformation[0].systemTrace + ' <br>';
        messageValidation += '- Fecha de Reverso 1: ' + this.refundInfo.splitPaymentList[0].voidedInformation[0].voidedDate + ' <br>';
        // tslint:disable-next-line:max-line-length
        messageValidation += '- Estatus del Reverso 1: ' + this.refundInfo.splitPaymentList[0].voidedInformation[0].rCode + ' ' + this.refundInfo.splitPaymentList[0].voidedInformation[0].rMsg + ' <br>';
        messageValidation += '<br><br>';
      }

      if (this.refundInfo.splitPaymentList[1].voidedInformation[0].isVoided) {
        // tslint:disable-next-line:max-line-length
        messageValidation += '- N&uacute;mero de Refrencia de Reverso 2: ' + this.refundInfo.splitPaymentList[1].voidedInformation[0].requestId + ' <br>';
        // tslint:disable-next-line:max-line-length
        messageValidation += '- N&uacute;mero de Traza en Banco 2: ' + this.refundInfo.splitPaymentList[1].voidedInformation[0].systemTrace + ' <br>';
        messageValidation += '- Fecha de Reverso 2: ' + this.refundInfo.splitPaymentList[1].voidedInformation[0].voidedDate + ' <br>';
        // tslint:disable-next-line:max-line-length
        messageValidation += '- Estatus del Reverso 2: ' + this.refundInfo.splitPaymentList[1].voidedInformation[0].rCode + ' ' + this.refundInfo.splitPaymentList[1].voidedInformation[0].rMsg + ' <br>';
      }
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    // tslint:disable-next-line:max-line-length
    dialogConfig.data = {
      message: messageValidation,
      colorSi: 'colorRed',
      colorNo: undefined,
      btmOk: true,
      btmConfirm: false,
      labelOk: 'Aceptar',
      labelNo: undefined,
      messageHtml: true
    };
    const dialogRef = this.dialog.open(AlertasComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(value => {
    });
  }

  isRefoundData() {
    if (this.refundInfo) {
      if (this.confirmNumber2 === null) {
        return this.refundInfo.voidedInformation[0].isVoided;
      } else {
        // tslint:disable-next-line:max-line-length
        return this.refundInfo.splitPaymentList[0].voidedInformation[0].isVoided || this.refundInfo.splitPaymentList[1].voidedInformation[0].isVoided;
      }
    } else {
      return false;
    }
  }
}
