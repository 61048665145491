<div class="footcont">
  <div class="autocont full vcenter pull-left">
    <div class="tabcell">
      <img class="footlogo nopointer" width="100%" src="../assets/images/foot-logo.png">
    </div>
  </div>
  <div class="autocont full vcenter pull-left">
    <div class="tabcell">
      Todos los derechos reservados, Claro 2021
    </div>
  </div>
</div>
