import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import {
  AccountDetails,
  Subscriber,
  AddChildPayment,
  ApplyPayment,
  GetPaymentInfo,
  GetToken,
  GetVerifyEmail,
  Login,
  NewPayment,
  Permissions,
  UpdatePersonalData,
  VerifyCode,
  VerifyEmail,
  VerifyPaymentStatus,
  Keys,
  AuthorizePaymentRequest, LoginData
} from '../../interfaces/interfaces';
import { EncryptWithKeyService } from '../encrypt-with-key/encrypt-with-key.service';
import { environment } from '../../../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private otpUrl = `${environment.deployAssetsPath}/assets/data/`;
  private urlService = '/api';
  constructor(private http: HttpClient, private router: Router, private encrypt: EncryptWithKeyService) { }
  login(Username, Password) {
    const form = new FormData();
    form.append('Username', Username);
    form.append('Password', Password);
    return this.http.post<Login>(this.urlService + '/Authenticate/Login', form);
  }
  getConfig() {
    return this.http.get("./assets/config.json");
  }
  // tslint:disable-next-line:no-shadowed-variable
  accountDetails(token, subscriber, account) {
    const form = new FormData();
    form.append('token', token);
    form.append('subscriber', subscriber);
    form.append('account', account);
    return this.http.post<AccountDetails>(this.urlService + '/Authenticate/AccountDetails', form);
  }
  permissions(token, accountType, name, lastName) {
    const form = new FormData();
    form.append('token', token);
    form.append('accountType', accountType);
    form.append('name', name);
    form.append('lastName', lastName);
    return this.http.post<Permissions>(this.urlService + '/Authenticate/permissions', form);
  }
  // tslint:disable-next-line:no-shadowed-variable
  subscriber(token, accountNumber, ssn, subscriber) {
    const form = new FormData();
    form.append('token', token);
    form.append('accountNumber', accountNumber);
    form.append('ssn', ssn);
    form.append('subscriber', subscriber);
    return this.http.post<Subscriber>(this.urlService + '/Authenticate/subscriber', form);
  }
  verifyEmail(accountNumber, channel, email) {
    const form = new FormData();
    form.append('accountNumber', accountNumber);
    form.append('channel', channel);
    form.append('email', email);
    return this.http.post<VerifyEmail>(this.urlService + '/Authenticate/verifyEmail', form);
  }
  verifyCode(code) {
    const form = new FormData();
    form.append('code', code);
    return this.http.post<VerifyCode>(this.urlService + '/Authenticate/verifyCode', form);
  }
  createTransaction(channell, transactionType, code, token) {
    const form = new FormData();
    form.append('token', token);
    form.append('channell', channell);
    form.append('transactionType', transactionType);
    form.append('code', code); // token
    return this.http.post<any>(this.urlService + '/Authenticate/createTransaction', form);
  }
  // tslint:disable-next-line:max-line-length
  updateTransaction(id, statusCode, confirmationNumber, autorizationNumber, statusDescription, serviceResponse, transactionStatus, token, urlId, amount, type, paymentStatus,
                    // tslint:disable-next-line:no-shadowed-variable
                    accountType, accountSubtype, accountNumber, subscriber, totalAmount, name) {
    const form = new FormData();
    form.append('token', token);
    form.append('id', id);
    form.append('statusCode', statusCode);
    form.append('confirmationNumber', confirmationNumber);
    form.append('autorizationNumber', autorizationNumber);
    form.append('statusDescription', statusDescription);
    form.append('serviceResponse', serviceResponse);
    form.append('transactionStatus', transactionStatus);
    form.append('urlId', urlId);
    form.append('amount', amount);
    form.append('type', type);
    form.append('paymentStatus', paymentStatus);

    form.append('accountType', accountType);
    form.append('accountSubtype', accountSubtype);
    form.append('accountNumber', accountNumber);
    form.append('subscriber', subscriber);
    form.append('totalAmount', totalAmount);
    form.append('name', name);
    return this.http.post<any>(this.urlService + '/Authenticate/updateTransaction', form);
  }
  // tslint:disable-next-line:no-shadowed-variable
  sendSms(subscriber, language, transactionId, token) {
    const form = new FormData();
    form.append('token', token);
    form.append('subscriber', subscriber);
    form.append('language', language);
    form.append('transactionId', transactionId);
    return this.http.post<any>(this.urlService + '/Authenticate/sendSms', form);
  }

  sendEmail(email, language, transactionId, token) {
    const form = new FormData();
    form.append('token', token);
    form.append('email', email);
    form.append('language', language);
    form.append('transactionId', transactionId);
    return this.http.post<any>(this.urlService + '/Authenticate/sendEmail', form);
  }

  findByAuth(confirmation, authorization, token) {
    const form = new FormData();
    form.append('token', token);
    form.append('confirmation', confirmation);
    form.append('authorization', authorization);
    return this.http.post<any>(this.urlService + '/Authenticate/findByAuth', form);
  }

  getPaymentInfo(paymentToken) {
    const form = new FormData();
    form.append('paymentToken', paymentToken);
    return this.http.post<GetPaymentInfo>(this.urlService + '/Authenticate/getPaymentInfo', form);
  }

  addChildPayment(parentPaymentToken, paymentAmount) {
    const form = new FormData();
    form.append('parentPaymentToken', parentPaymentToken);
    form.append('paymentAmount', paymentAmount);
    return this.http.post<AddChildPayment>(this.urlService + '/Authenticate/addChildPayment', form);
  }

  getToken() {
    const form = new FormData();
    return this.http.post<GetToken>(this.urlService + '/Authenticate/getToken', form);
  }

  newPayment(productId, offerId, paymentMode = null) {
    const form = new FormData();
    form.append('productId', productId);
    form.append('merchantCodeId', offerId);
    form.append('paymentMode', paymentMode);
    return this.http.post<NewPayment>(this.urlService + '/Authenticate/newPayment', form);
  }

  applyPayment(offerId, productId, paymentOptionId, paymentAmount,
               customerEmail, subscriberNumber, subscriberAccountNumber,
               subscriberFullName, subscriberAddress1, subscriberAddress2,
               subscriberCity, subscriberState, subscriberZipCode,
               transactionDescription, banSend, subscribeSend,
               locationId, invoiceNumber, extendedData = '') {
    const form = new FormData();
    form.append('productId', btoa(productId));
    form.append('paymentOptionId', btoa(paymentOptionId));
    form.append('paymentAmount', btoa(paymentAmount));
    form.append('customerEmail', btoa(customerEmail));
    form.append('subscriberNumber', btoa(subscriberNumber));
    form.append('subscriberAccountNumber', btoa(subscriberAccountNumber));
    form.append('subscriberFullName', btoa(subscriberFullName));
    form.append('subscriberAddress1', btoa(subscriberAddress1));
    form.append('subscriberAddress2', btoa(subscriberAddress2));
    form.append('subscriberCity', btoa(subscriberCity));
    form.append('subscriberState', btoa(subscriberState));
    form.append('subscriberZipCode', btoa(subscriberZipCode));
    form.append('transactionDescription', btoa(transactionDescription));
    form.append('offerId', offerId);
    form.append('subscriberAccountNumberSend', banSend);
    form.append('receiverSubsNumber', subscribeSend);
    form.append('locationId', locationId);
    form.append('invoiceNumber', invoiceNumber);
    form.append('extendedData', extendedData);
    return this.http.post<ApplyPayment>(this.urlService + '/Authenticate/applyPayment', form);
  }

  // tslint:disable-next-line:max-line-length
  authorizePayment(dto: AuthorizePaymentRequest) {
    const form = new FormData();
    const payload = this.encrypt.secureData(JSON.stringify(dto));
    form.append('data', payload);
    return this.http.post<VerifyPaymentStatus>(this.urlService + '/Authenticate/AuthorizePaymentEnc', form);
    // return this.http.post<VerifyPaymentStatus>(this.urlService + '/Authenticate/AuthorizePayment', form);
  }

  verifyPaymentStatus(paymentToken) {
    const form = new FormData();
    form.append('paymentToken', paymentToken);
    return this.http.post<VerifyPaymentStatus>(this.urlService + '/Authenticate/verifyPaymentStatus', form);
  }

  getVerifyEmail(email) {
    const form = new FormData();
    form.append('email', email);
    return this.http.post<GetVerifyEmail>(this.urlService + '/Authenticate/GetVerifyEmail', form);
  }

  updatePersonalData(token, BAN, PhoneNumber, PhoneNumber2, Email) {
    const form = new FormData();
    form.append('token', token);
    form.append('BAN', BAN);
    form.append('PhoneNumber', PhoneNumber);
    form.append('PhoneNumber2', PhoneNumber2);
    form.append('Email', Email);
    return this.http.post<UpdatePersonalData>(this.urlService + '/Authenticate/UpdatePersonalData', form);
  }

  getValidationCode(subscriber) {
    // const payload = {subscriber};
    const form = new FormData();
    form.append('subscriber', subscriber);
    if (environment.localApi) {
      return this.getLocalData('getValidationCode.json');
    } else {
      return this.http.post(this.urlService + '/Authenticate/GetValidationCode', form);
    }
  }

  validateCode(subscriber, code) {
    // const payload = {subscriber,code};
    const form = new FormData();
    form.append('subscriber', subscriber);
    form.append('code', code);
    if (environment.localApi) {
      return this.getLocalData('validateCode.json');
    } else {
      return this.http.post(this.urlService +  '/Authenticate/validateCode', form);
    }
  }

  getKeys() {
    const url = environment.localApi ? this.otpUrl + 'getKeys.json' : this.urlService + '/Authenticate/GetKeys';
    return this.http.get<Keys>(url , {headers: this.getHelpHeaders()});
  }

  getSetting() {
    return this.getLocalData('data.json');
  }

  getLocalData(param) {
    return this.http.get(this.otpUrl + param);
  }

  getHelpHeaders(): HttpHeaders {
    let header = new HttpHeaders();
    header = header.append('authToken', 'QADcsgShNdpN6M2y');
    header = header.append('appKey', 'COLLECTIONPATH');
    header = header.append('content-type', 'application/json; charset=utf-8');
    return header;
  }

  authenticatePin(dto: LoginData) {
    const form = new FormData();
    form.append('dataToken', btoa(JSON.stringify(dto)));
    return this.http.post<any>(this.urlService + '/Authenticate/authenticatePin', form);
  }

  decryptTokenByCrypto(data:any) {
    var form = new FormData();
    form.append("data", data);
    return this.http.post<any>(this.urlService + '/Authenticate/DecryptTokenByCrypto', form);
  }

  getTransactionStatus(data:any) {
    var form = new FormData();
    form.append("data", data);
    return this.http.post<any>(this.urlService + '/Authenticate/GetTransactionStatus', form);
  }


}
