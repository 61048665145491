import { Component, OnInit } from "@angular/core";
import { LoadingScreenService } from "../../core/services/loading-screen/loading-screen.service";
import { Subscription } from "rxjs";
import { GlobalService } from "../../core/services/global-services/global.service";
import { Router } from "@angular/router";
import { ApiService } from "src/app/core/services/api-services/api.service";

@Component({
  selector: "app-prepaid-buy-balance",
  templateUrl: "./prepaid-buy-balance.component.html",
  styleUrls: ["./prepaid-buy-balance.component.sass"],
})
export class PrepaidBuyBalanceComponent implements OnInit {
  public planPrice: number;
  public planPriceString: string;
  public dataPrice;
  public ivuState = 0.115;
  public ivuAmount;
  public freeAmountValue = "0";
  public otherValue = false;
  public wallet;
  public subscriber;
  public balancedPrepaid;
  public selectBanIP: any = "";
  public currentSuscriberIP: any = "";
  paymentReceived: Subscription;
  public totalToPay: number = 0;

  constructor(
    private loadingScreenService: LoadingScreenService,
    public global: GlobalService,
    private router: Router,
    private services: ApiService
  ) {}

  ngOnInit() {
    sessionStorage.setItem("ath-route", "recharge");
    this.global.setStep(2);
    this.paymentReceived = this.global.paymentReceived.subscribe(
      (value: boolean) => {
        if (value == true) {
          this.loadingScreenService.stopLoading();
          this.global.notif("Recarga exitosa.");
          this.global.currentScreen = 3;
        }
      }
    );
    this.subscriber = JSON.parse(
      atob(sessionStorage.getItem("getSubscriber"))
    ).subscriberInfo[0].subscriberNumber;
    const planPrice = JSON.parse(
      atob(sessionStorage.getItem("getSubscriber"))
    ).amount;
    this.planPrice = planPrice ? planPrice.toString().replace("$", "") : 0;
    this.planPriceString = "$" + this.planPrice;
    this.balancedPrepaid = JSON.parse(
      atob(sessionStorage.getItem("getSubscriber"))
    ).subscriberInfo[0].prepaidBalance;

    if (this.planPrice > 0) {
      this.otherValue = false;
      this.dataPrice = this.planPriceString.split("$").join("");
      this.ivuAmount = parseFloat(this.dataPrice) * this.ivuState;
      this.totalToPay = Number(this.dataPrice) + Number(this.ivuAmount);
    }
    this.athProcess();
  }
  goToConfirm() {
    if (this.totalToPay == 0) {
      this.global.notif("Por favor seleccione un monto.");
    } else {
      if (this.totalToPay <= 300) {
        if (this.totalToPay >= 5) {
          this.loadingScreenService.startLoading();
          this.global.currentScreen = 2;
        } else {
          this.global.notif("El monto no puede ser menor a $5.00.");
        }
      } else {
        this.global.notif("El monto no puede ser mayor a $300.00.");
      }
    }
  }
  optionSelected(dataPrice) {
    this.dataPrice = dataPrice.split("$").join("");
    this.ivuAmount = parseFloat(this.dataPrice) * this.ivuState;
    this.totalToPay = Number(this.dataPrice) + Number(this.ivuAmount);
    if (this.otherValue == true) {
      const check = document.getElementById("radioG6") as HTMLInputElement;
      check.checked = true;
      // document.getElementById().spellcheck =true;
    } else {
      const check = document.getElementById("radioG6") as HTMLInputElement;
      check.checked = false;
    }
  }

  goBack() {
    this.global.isPrepaid = true;
    this.global.prepaidCode = "";
    this.global.subscriberReceiver = "";
    this.global.onPrepaidStep2 = false;
    this.global.currentScreen = 1;
    this.router.navigate(["login"]);
  }

  athProcess() {
    if (JSON.parse(sessionStorage.getItem("athData")!!)) {
      this.loadingScreenService.stopLoading();
      let dataAth = JSON.parse(sessionStorage.getItem("athData")!!);
      if (dataAth.cancelled) {
        this.global.notif(
          "Has cancelado el proceso de pago por favor intenta nuevamente."
        );
        sessionStorage.removeItem("athData");
      } else {
        this.loadingScreenService.startLoading();
        this.getTransactionStatus(btoa(sessionStorage.getItem("athData")!!));
      }
    }
  }

  getTransactionStatus(data: any, typeResult = "") {
    this.services.getTransactionStatus(data).subscribe(
      (resp) => {
        this.decryptTokenByCrypto(resp.dataToken);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  decryptTokenByCrypto(data: any) {
    this.services.decryptTokenByCrypto(data).subscribe(
      (resp) => {
        console.log(resp);
        let dataResult = JSON.parse(
          atob(resp.iframePaymentResult.paymentResult)
        );
        console.log(dataResult);
        // this.resultPaymentIframe(dataResult);
        this.global.resultPaymentIframe(dataResult);
        sessionStorage.removeItem('athData')
        this.loadingScreenService.stopLoading();
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
