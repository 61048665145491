<div class="allcont footer-fixed-2 f-black f-reg roboto-r" *ngIf="this.global.currentScreen == 1">
    <div class="container">
        <div class="basicrow">
            <div class="autocont pull-left f-bigtitle roboto-l">
                Confirmar Recarga 
            </div>
        </div>
        
        <div class="billcont">
            <div class="basicrow">
                <div class="autocont pull-left">Nro de Cuenta</div>

                <div class="autocont pull-right roboto-b">{{this.banSelected}}
                </div>
            </div>

            <div class="basicrow bill-marg">
                <div class="autocont pull-left">Nro de Tel&eacute;fono</div>

                <div class="autocont pull-right roboto-b">{{this.subscriber}}</div>
            </div>

            <div class="basicrow bill-marg">
                <div class="autocont pull-left">Plan</div>

                <div class="autocont pull-right roboto-b">{{this.dataPrice | currency}}</div>
            </div>

            <div class="basicrow bill-marg">
                <div class="autocont pull-left">Impuesto</div>

                <div class="autocont pull-right roboto-b">{{this.ivuState * this.dataPrice | currency}}</div>
            </div>

            <div class="basicrow bill-marg f-red">
                <div class="autocont pull-left">TOTAL A PAGAR</div>

                <div class="autocont pull-right roboto-b" >{{this.totalToPay| currency}}</div>

            </div>
        </div>

        <div class="basicrow text-center m-top">

            <div class="graybtn next pull-left hidden-xs" id="back_btn" (click)="this.back()"><i class="fa fa-angle-left lefticon" aria-hidden="true"></i> Volver</div>
            <div class="errortest redbtn next pull-right" id="continue_btn" (click)="this.goPayment()">Continuar <i class="fa fa-angle-right righticon" aria-hidden="true"></i></div>
        </div>
    </div>
</div>
<div class="allcont f-black f-reg roboto-r" *ngIf="this.global.currentScreen == 2">
    <div class="container">
        <app-iframe-payment
        [transactionType]="'prepaid'"
        [startTime]="this.paymentStartTime"
        [width]="this.global.widthIframePay"
        [height]="this.global.heightIframePay"
        [frameborder]="this.global.frameborderIframePay"
        [scrolling]="this.global.scrollingIframePay"
        [token]="this.tokenIframePay"
        [tokenSSO]="''"
        [firstName]="this.firstNameIP"
        [lastName]="this.lastNameIP"
        [email]="this.emailIP"
        [amount]="this.amountIP"
        [selectBan]="this.selectBanIP"
        [currentSuscriber]="this.currentSuscriberIP"
        [locationId]="this.locationIdIP"
        [invoiceNumber]="this.invoiceNumberIP"
        [installmentCount]="this.installmentCountIP"
        [displayConfirmation]="this.displayConfirmation"
        (result)="this.global.resultPaymentIframe($event)"
        (initPayment)="this.global.initPayment()"
        (cancelPayment)="this.back()"
        (outLoadIframe)="this.outLoadIframe($event)"
        (errorMessage)="this.errorMessage($event)"
        (hasDimensions)="this.global.hasDimensions($event)">
        >
      </app-iframe-payment>
    </div>
</div>

  <div *ngIf="this.global.currentScreen==4">
    <app-payment-declined 
    (currentScreen)="this.global.currentScreen=$event;this.global.loadFooter=false;"
    ></app-payment-declined>
  </div>
  <div class="allcont f-black f-reg roboto-r" *ngIf="this.global.currentScreen == 2">
    <div class="container">
      <div class="basicrow text-center m-top" style="margin-bottom: 25px;">
        <div class="graybtn next no-m" (click)="this.back();">
          <i _ngcontent-beq-c286="" aria-hidden="true" class="fa fa-angle-left lefticon"></i> Volver
        </div>
      </div>
    </div>
  </div>