import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { GeneratedUrlComponent } from './generated-url.component';
import { MatSlideToggleModule } from '@angular/material';



@NgModule({
  declarations: [
    GeneratedUrlComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    MatSlideToggleModule
  ],
  exports: [
    GeneratedUrlComponent
  ]
})
export class GeneratedUrlModule { }
