<div id="wrapper">
    <!--Se pasa a componente despues-->
    <app-header></app-header>
    <!--Inicia componente del paso a paso-->
  <app-step [step]="3"></app-step>
    <!--Inicia contenido del modulo-->
    <div class="allcont f-black f-reg roboto-r">
        <div class="container">
            <div class="billcont">
                <div class="basicrow f-red">
                    <div class="autocont pull-left">Balance a Pagar:</div>
                    <div class="autocont pull-right roboto-b">&#36;{{this.balance}}</div>
                </div>
            </div>
            <div class="linesprt-i"></div>
            <form id="form-payment">
                <div class="basicrow">
                    <div class="basicrow f-bigtitle roboto-l">
                        Datos de la Tarjeta
                    </div>
                </div>
                <div class="basicrow">
                    <div class="basicrow m-top-i text-justify">
                        &#42; Nombre:
                    </div>
                    <div class="tlf-info roboto-r">
                        <input class="inpdef" placeholder="Nombre y Apellido" type="text" name="name">
                    </div>
                </div>
                <div class="basicrow">
                    <div class="row">
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <div class="basicrow">
                                <div class="basicrow m-top-i text-justify">
                                    &#42; Tarjeta:
                                </div>
                                <div class="tlf-info roboto-r">
                                    <input class="inpdef" placeholder="Número de Tarjeta" type="text" name="card_number"
                                        maxlength="19" autocomplete="off">
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <div class="basicrow">
                                <div class="basicrow m-top-i text-justify">
                                    &#42; Fecha de vencimiento:
                                </div>
                                <div class="tlf-info roboto-r">
                                    <div class="row">
                                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                            <select class="inpdef" id="selected-month" name="month">
                                                <option disabled selected hidden value="month">Mes</option>
                                                <option value="01">01</option>
                                                <option value="02">02</option>
                                                <option value="03">03</option>
                                                <option value="04">04</option>
                                                <option value="05">05</option>
                                                <option value="06">06</option>
                                                <option value="07">07</option>
                                                <option value="08">08</option>
                                                <option value="09">09</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                            </select>
                                        </div>
                                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                            <select class="inpdef" name="year" id="selected-year">
                                                <option disabled selected hidden value="month">Año</option>
                                                <option value="2019">2019</option>
                                                <option value="2020">2020</option>
                                                <option value="2021">2021</option>
                                                <option value="2022">2022</option>
                                                <option value="2023">2023</option>
                                                <option value="2024">2024</option>
                                                <option value="2025">2025</option>
                                                <option value="2026">2026</option>
                                                <option value="2027">2027</option>
                                                <option value="2028">2028</option>
                                                <option value="2029">2029</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="basicrow">
                    <div class="basicrow m-top-i text-justify">
                        Tipo de Tarjeta
                    </div>
                    <div class="row">
                        <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
                            <div class="cardbox cards-c vcenter" data-type="VISA">
                              <div class="tabcell">
                                <img width="100%" src="../assets/images/visa-card.png">
                              </div>
                            </div>
                        </div>
                        <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
                            <div class="cardbox cards-c vcenter" data-type="MASTER">
                                <div class="tabcell">
                                    <img width="100%" src="../assets/images/masterc-card.png">
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
                            <div class="cardbox cards-c vcenter" data-type="AMERICAN">
                              <div class="tabcell">
                                <img width="100%" src="../assets/images/amex-card.png">
                              </div>
                            </div>
                        </div>
                        <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
                            <div class="cardbox cards-c vcenter" data-type="ATH">
                                <div class="tabcell">
                                    <img width="100%" src="../assets/images/ath-card.png">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="basicrow" id="cvv-container">
                    <div class="row">
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <div class="basicrow">
                                <div class="basicrow m-top-i text-justify">
                                    &#42; Código de Seguridad:
                                </div>
                                <div class="tlf-info roboto-r">
                                    <input class="inpdef" placeholder="CVV" type="text" id="CVV" name="CVV" type="password" pattern="[0-9]*" inputmode="numeric">
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <div class="basicrow">
                                <div class="basicrow m-top-i text-justify">
                                    &#42; Código Postal:
                                </div>
                                <div class="tlf-info roboto-r">
                                    <input class="inpdef" placeholder="XXXXX" type="text" id="zip" name="zip" pattern="[0-9]*" inputmode="numeric">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="linesprt-i"></div>
            <div class="basicrow text-center">
                <div class="redbtn next pull-right" id="pay_btn" (click)="this.goTo();">Continuar <i class="fa fa-angle-right righticon" aria-hidden="true"></i></div>
                <div class="graybtn next pull-left visible-xs" id="back_btn" (click)="this.backTo();"><i class="fa fa-angle-left lefticon" aria-hidden="true"></i> Volver</div>
            </div>
        </div>
    </div>
    <!--Inicia el footer-->
    <app-footer></app-footer>
</div>
