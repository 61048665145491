import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../../../../../core/services/global-services/global.service';
@Component({
  selector: 'app-bill-buy-package',
  templateUrl: './bill-buy-package.component.html',
  styleUrls: ['./bill-buy-package.component.sass']
})
export class BillBuyPackageComponent implements OnInit {
  @Input() confirmNumber;
  @Input() banSelected;
  @Input() subscriberActual;
  @Input() namePackage;
  @Input() pricePackage;
  public emailCorr = '';
  public insNumTel = '';
  constructor(private router: Router, public global: GlobalService) { }

  ngOnInit() {
  }
  goHome() {
    this.global.setStep(2);
    this.global.isStep1 = true;
    this.global.currentScreen=1;
    this.global.isPrepaid = false;
    this.global.onPrepaidStep2 = false;
    this.router.navigate(['postpaid_select_option']);
  }
  readLastFour(data) {
    if (!!data) {
      const start = data.substring(5, 9);
      return start;
    }
  }
  enviarNumtel() {
    if (this.insNumTel == '') {
      this.global.notif('Debe ingresar su telefono');
    } else {
      this.global.notif('numero insertado con exito');
    }
  }
  verificarEmail() {
    if (this.emailCorr === '') {
      this.global.notif('Debe ingresar su e-mail');
    } else if (this.global.validarEmail(this.emailCorr) === false) {
      this.global.notif('Estimado cliente, el Nuevo Correo Electrónico tiene un formato incorrecto.');
    } else {
      this.global.notif('ok');
    }
  }
}
