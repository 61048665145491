<diV *ngIf="this.state == 'error'">
  <div class="container-button">
    <div class="btntxt" (click)="this.tryAgain()">
      <div class="container-declined">
        <div style="padding-top: 20px; padding-bottom: 20px">
          <img
            width="48px"
            src="../../../../assets/images/icon_validacionincorrecta.png"
          />
        </div>
        <div
          class="txt-message-error"
          [innerHTML]="this.textMessageError | safeHtml"
        ></div>
      </div>
    </div>
  </div>
</diV>
<div style="text-align: center" *ngIf="this.state != 'error'">
  <iframe
    #iframeIdPayment
    id="iframePaymentId"
    [ngStyle]="{ border: this.frameborder === 0 ? '0' : '1' }"
    scrolling="{{ this.scrolling }}"
    [src]="this.urlSafe"
    width="{{ this.width }}"
    height="{{ this.height }}"
    *ngIf="this.loadIframe && !service.athPinpadURL"
  ></iframe>
  <iframe
    #ath
    id="ath"
    [ngStyle]="{ border: this.frameborder === 0 ? '0' : '1' }"
    scrolling="{{ this.scrolling }}"
    [src]="service.athPinpadURL"
    style="width: 100%; height: 600px"
    *ngIf="this.loadIframe && service.athPinpadURL"
  >
  </iframe>
</div>
