import { Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appOnlyAmount]'
})
export class OnlyAmountDirective {
    @Input('appOnlyAmount') date: string;
    //private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);

    private regex: RegExp = /^\d*(\.\d{0,2})?$/;

    constructor(private el: ElementRef) {}
  
    @HostListener('input', ['$event'])
    onInputChange(event: InputEvent) {
      const input = this.el.nativeElement.value;
      const lastChar = input.slice(-1);
      const count = (input.match(/\./g) || []).length;
      if (lastChar === '.' && count > 1) {
        this.el.nativeElement.value = input.slice(0, -1);
        return;
      }
      if (!this.isValidInput(input)) {
        this.el.nativeElement.value = input.slice(0, -1);
        return;
      }
    }
    private isValidInput(input: string): boolean {
      return this.regex.test(input);
    }
  }