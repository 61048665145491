import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../../core/services/api-services/api.service';
import { LoadingScreenService } from '../../../core/services/loading-screen/loading-screen.service';
import { GlobalService } from '../../../core/services/global-services/global.service';

@Component({
  selector: 'app-postpaid-select-option',
  templateUrl: './postpaid-select-option.component.html',
  styleUrls: ['./postpaid-select-option.component.sass']
})
export class PostpaidSelectOptionComponent implements OnInit {
  public telemarketingPayTax;
  public subscriberInfo;
  public paymentToken = null;

  constructor(private loadingScreenService: LoadingScreenService,
              private router: Router,
              private services: ApiService,
              private global: GlobalService) {
    this.global.setStep(2);
  }

  ngOnInit() {
    this.global.session();
    
    // this.global.showSplash = true;
    if (sessionStorage.getItem('telemarketing_pay_tax') !== null) {
      this.telemarketingPayTax = JSON.parse(atob(sessionStorage.getItem('telemarketing_pay_tax')));
    }
    if (JSON.parse(atob(sessionStorage.getItem('subscriberInfo'))) !== null) {
      this.subscriberInfo = JSON.parse(atob(sessionStorage.getItem('subscriberInfo')));
    }
    if (sessionStorage.getItem('paymentToken') !== null) {
      this.paymentToken = sessionStorage.getItem('paymentToken');
    }
  }

  backToLogin() {
    this.loadingScreenService.startLoading();
    this.router.navigate(['login']);
    this.loadingScreenService.stopLoading();
  }

  goToPendingPayment() {
    this.loadingScreenService.startLoading();
    this.global.setStep(1);
    this.global.currentScreen=1;
    this.router.navigate([this.paymentToken !== null ? 'confirm_installment_tax' : 'confirm_telemarketing_tax']);
    this.loadingScreenService.stopLoading();
  }

  goToPayment() {
    // if (this.telemarketingPayTax == null) {
      this.loadingScreenService.startLoading();
      this.global.setStep(1);
      this.global.currentScreen=1;
      this.router.navigate(['payment_amount']);
      this.loadingScreenService.stopLoading();
    // }
  }

  goToPackages() {
    if (this.telemarketingPayTax == null && this.subscriberInfo.length > 0) {
      this.loadingScreenService.startLoading();
      this.router.navigate(['additional_packages']);
      this.loadingScreenService.stopLoading();
    }
  }

}
