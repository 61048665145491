import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
export interface dataCreateOrder {
  catalogId: number,
  catalogName: string,
  errorDesc: string,
  errorInter: string,
  errorNum: number,
  hasError: boolean,
  plans: object,
  productId: number
}
interface token {
  token: string
}
@Injectable({
  providedIn: 'root'
})
export class PaymentIframeService {
  athPinpadURL: any = ""
  private urlService: string = "/api";
  public httpOptions = {
    headers: new HttpHeaders({
      Authorization: `Bearer `
    })
  };
  constructor(private http: HttpClient, private router: Router) { }
    setConfig(token:string,basePath:string) {
    const result = new Observable(observer => {
      //this.global.basePath=basePath;
      if(sessionStorage.getItem("token")!=null && sessionStorage.getItem("token")!=undefined){
        //this.global.token=token;
      }
      this.httpOptions = {
        headers: new HttpHeaders({
          //Authorization: `Bearer ${this.global.token}`
        })
      };
      observer.next();
      observer.complete();
      observer.unsubscribe();
    });
    return result;
  }
  setToken(token:any){
    if(token!=null && token!=undefined){
      sessionStorage.setItem("token", token);
      this.httpOptions = {
        headers: new HttpHeaders({
          Authorization: `Bearer ${token}`
        })
      };
    }
  }
  getIframe(token:string,ssoToken:string,firstName:string,lastName:string,email:string,transactionType:string,amount:string,selectBan:string,currentSuscriber:string,provision:boolean,displayConfirmation:boolean,emailNotification:boolean,showInstrument:boolean,stroeInstrument:boolean,useBanZipCode:boolean
    ,locationId:string,invoiceNumber:string,installmentCount:string,paymentItems:string) {
    this.setToken(token);
    var form = new FormData();
    form.append("ssoToken", ssoToken);
    form.append("firstName", firstName);
    form.append("lastName", lastName);
    form.append("email", email);
    form.append("transactionType", transactionType);
    form.append("amount", amount);
    form.append("selectBan", selectBan);
    form.append("currentSuscriber", currentSuscriber);
    form.append("provision", provision.toString());
    form.append("displayConfirmation", displayConfirmation.toString());
    form.append("emailNotification", emailNotification.toString());
    form.append("showInstrument", showInstrument.toString());
    form.append("stroeInstrument", stroeInstrument.toString());
    form.append("useBanZipCode", useBanZipCode.toString());
    form.append("locationId", locationId);
    form.append("invoiceNumber", invoiceNumber);
    form.append("installmentCount", installmentCount);
    form.append("paymentItems", paymentItems);
    return this.http.post<dataCreateOrder>(this.urlService + '/Payment/getIframe', form, this.httpOptions);
  }
}
