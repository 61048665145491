<div class="wrapper ">
  <app-loading-screen></app-loading-screen>
  <app-chat></app-chat>
 <app-header></app-header>
 <app-step [step]="1"></app-step>
  <!--Contenido para la primera pantalla-->
  <div class="allcont f-black f-reg roboto-r footer-fixed" *ngIf="this.global.isStep1">
    <div class="container" *ngIf="!this.global.isPrepaid">
        <div class="basicrow f-bigtitle roboto-l center-title">
           Indique el tipo de Servicio
        </div>

        <div class="basicrow text-center user-tipe">
            <div class="redbtn" (click)="this.postPaidUser();">Pagar Facturas <i class="fa fa-angle-right righticon" aria-hidden="true"></i></div>
            <div class="redbtn" (click)="this.prepaidUser();">Recargar Prepago <i class="fa fa-angle-right righticon" aria-hidden="true"></i></div>
        </div>
    </div>
  </div>


  <div class="allcont f-black f-reg roboto-r footer-fixed" *ngIf="!this.global.isStep1">
      <div class="container" *ngIf="!this.global.isPrepaid">
          <div class="basicrow f-bigtitle roboto-l">
              Ingresa datos de la cuenta
          </div>
        <form [formGroup]="loginForm">
          <div class="basicrow">
            <div class="basicrow m-top-i text-justify">
              &#42; Número de cuenta o de teléfono
            </div>
            <div class="tlf-info roboto-r">
              <input
                [appOnlyNumber]
                formControlName="number"
                class="inpdef"
                type="text"
                id="subscriber"
                name="subscriber"
                maxlength="10"
              >
            </div>
          </div>
          <div class="basicrow">
            <div class="basicrow m-top-i text-justify">
              &#42; 4 Últimos del Seguro Social
            </div>
            <div class="tlf-info roboto-r">
              <input
                [appOnlyNumber]
                class="inpdef"
                type="password"
                id="ssn"
                name="ssn"
                formControlName="ssn"
                pattern="[0-9]*"
                inputmode="numeric"
                maxlength="4"
                autocomplete="off">
            </div>
          </div>
          <!--
          <div class="box-captcha " *ngIf="global.siteKey">
            <div class="h-captcha">
              <div class="content-captcha">
                <ngx-recaptcha2 #captchaElem [siteKey]="global.siteKey" formControlName="recaptcha">
                </ngx-recaptcha2>
              </div>
            </div>
          </div>
          -->
          <div class="basicrow text-center user-tipe" id="continue_btn">
            <div class="graybtn" (click)="backToStep1()"><i class="fa fa-angle-left lefticon" aria-hidden="true"></i> Regresar</div>
            <div
              (click)="this.validateData();"
              [ngClass]="{
              'redbtn': this.loginForm.valid,
               'graybtn': !this.loginForm.valid
               }"
            >
              Continuar
              <i class="fa fa-angle-right righticon" aria-hidden="true"></i>
            </div>
          </div>
        </form>

      </div>
      <div class="container" *ngIf="this.global.isPrepaid">
        <div *ngIf="!global.onPrepaidStep2">
            <div class="basicrow f-bigtitle roboto-l">
                Validación de Usuario Prepago
             </div>
             <div class="basicrow">
                 <div class="basicrow m-top-i text-justify">
                     &#42; Ingrese su número telefónico
                 </div>
                 <div class="tlf-info roboto-r">
                     <input [appOnlyNumber]
                            [(ngModel)]="this.global.subscriberReceiver"
                            class="inpdef"
                            type="text"
                            id="code"
                            name="code"
                            maxlength="10">
                 </div>
             </div>

             <div class="basicrow text-center user-tipe" id="continue_btn">
                <div class="graybtn" (click)="backToStep1()"><i class="fa fa-angle-left lefticon" aria-hidden="true"></i> Regresar </div>
                 <div class="redbtn" (click)="getValidationCode()">Continuar <i class="fa fa-angle-right righticon" aria-hidden="true"></i></div>
             </div>
        </div>
        <div *ngIf="this.global.onPrepaidStep2">
            <div class="basicrow f-bigtitle roboto-l">
                Validación de Usuario Prepago
             </div>
             <div class="basicrow">
                 <div class="basicrow m-top-i text-justify">
                     &#42; Ingrese el código recibido en su dispositivo
                 </div>
                 <div class="tlf-info roboto-r">
                     <input [appOnlyNumber] [(ngModel)]="this.global.prepaidCode"  class="inpdef" type="text" id="code" name="code" maxlength="10">
                 </div>
             </div>

             <div class="basicrow text-center user-tipe" id="continue_btn">
                <div class="graybtn" (click)="backToPrepaidStep1()"><i class="fa fa-angle-left lefticon" aria-hidden="true"></i> Regresar </div>
                <div class="redbtn" (click)="validateCode()">Continuar <i class="fa fa-angle-right righticon" aria-hidden="true"></i></div>
             </div>
        </div>

    </div>
  </div>
  <!--Fin contenido primera pantalla-->
    <app-footer></app-footer>
</div>
