import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { GeneratedUrlComponent } from './pages/generated-url/generated-url.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { AdditionalPackagesComponent } from './pages/postpaid/additional-packages/additional-packages.component';
import { ConfirmPackageComponent } from './pages/postpaid/confirm-package/confirm-package.component';
import { PaymentAmountComponent } from './pages/postpaid/payment-amount/payment-amount.component';
import { PaymentInfoComponent } from './pages/postpaid/payment-info/payment-info.component';
import { PostpaidSelectOptionComponent } from './pages/postpaid/postpaid-select-option/postpaid-select-option.component';
import { PrepaidBuyBalanceComponent } from './pages/prepaid-buy-balance/prepaid-buy-balance.component';
import { ConfirmInstallmentTaxComponent } from './pages/tax/confirm-installment-tax/confirm-installment-tax.component';
import { ConfirmTelemarketingTaxComponent } from './pages/tax/confirm-telemarketing-tax/confirm-telemarketing-tax.component';
import { UpdateEmailComponent } from './pages/update-email/update-email.component';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
};
const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'postpaid_select_option',
    component: PostpaidSelectOptionComponent
  },
  {
    path: 'payment_amount',
    component: PaymentAmountComponent
  },
  {
    path: 'additional_packages',
    component: AdditionalPackagesComponent
  },
  {
    path: 'payment_info',
    component: PaymentInfoComponent
  },
  {
    path: 'confirm_package',
    component: ConfirmPackageComponent
  },
  {
    path: 'update_email',
    component: UpdateEmailComponent
  },
  {
    path: 'confirm_telemarketing_tax',
    component: ConfirmTelemarketingTaxComponent
  },
  {
    path: 'confirm_installment_tax',
    component: ConfirmInstallmentTaxComponent
  },
  {
    path: 'prepaid_buy_balance',
    component: PrepaidBuyBalanceComponent
  },
  {
    path: 'generated_url/:code',
    component: GeneratedUrlComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
