import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';

import { AlertasComponent } from './shared/components/alertas/alertas.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {  MatSlideToggleModule, MatProgressSpinnerModule} from '@angular/material';
import { HttpClientModule} from '@angular/common/http';
import { ApiService } from './core/services/api-services/api.service';
import { Bridge } from './bridge';
import { LoadingScreenComponent } from './shared/components/loading-screen/loading-screen.component';

import { CarouselModule } from 'ngx-bootstrap/carousel';
import { SharedModule } from './shared/shared.module';
import { PostpaidModule } from './pages/postpaid/postpaid.module';
import { LoginModule } from './pages/login/login.module';
import { PrepaidBuyBalanceModule } from './pages/prepaid-buy-balance/prepaid-buy-balance.module';
import { HomeModule } from './pages/home/home.module';
import { UpdateEmailModule } from './pages/update-email/update-email.module';
import { TaxModule } from './pages/tax/tax.module';
import { GeneratedUrlModule } from './pages/generated-url/generated-url.module';
import { AppRoutingModule } from './app-routing.module';
import { NgxCaptchaModule } from 'ngx-captcha';
import { SafeHtmlPipeModule } from './shared/safe-html-pipe/safe-html-pipe.module';
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    GeneratedUrlModule,
    HomeModule,
    LoginModule,
    PostpaidModule,
    PrepaidBuyBalanceModule,
    SharedModule,
    TaxModule,
    UpdateEmailModule,
    CarouselModule.forRoot(),
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    SafeHtmlPipeModule

  ],
  providers: [ApiService, Bridge, LoadingScreenComponent],
  bootstrap: [AppComponent],
  entryComponents: [AlertasComponent]
})
export class AppModule { }
