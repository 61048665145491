<div id="wrapper">
    <app-loading-screen></app-loading-screen>
    <!--Se pasa a componente despues-->
    <app-header></app-header>
    <!--Inicia componente del paso a paso-->
    <app-step [step]="2"></app-step>
    <!--Inicia contenido del modulo-->
    <div class="nopad f-black f-reg roboto-r">
        <div class="container container-m-w">
            <div class="basicrow">
                <div class="autocont pull-left f-bigtitle roboto-l">
                    Listado de Paquetes
                </div>
                <div class="autocont full-w pull-right">
                    <select [(ngModel)]="this.subscriberList" class="n-sel full-sel" id="select_susbcribers" (change)="this.refreshDataTo()">
                        <option *ngFor="let inf of this.info; let i = index" [ngValue]="inf.subscriberNumber">{{inf.subscriberNumber}}</option>
                    </select>
                </div>
            </div>
            <div class="basicrow m-top-i">
                <div class="packs-cont">
                    <div (click)="this.statusChange(1);" [className]="this.selTab===1 ? 'pack-t on ' : 'pack-t'">
                        Local
                    </div>
                    <div (click)="this.statusChange(2);" [className]="this.selTab===2 ? 'pack-t on ' : 'pack-t'">
                        Roaming
                    </div>
                </div>
            </div>
            <div id="additional_local_packages_cont" [ngStyle]="{'display':this.selTab === 1 ? 'block' : 'none' }">
                <div *ngIf="this.offerList.length==0">
                        <div class="userinfo full">
                                <div class="basicrow contenedor-msj">
                                    <div class="basicrow f-bmed roboto-b m-bott m-top usag-text bot-mob">
                                        Actualmente no cuentas con Paquetes Adicionales
                                    </div>
                                </div>
                        </div>
                </div>
                <div *ngIf="this.offerList.length>0">
                    <div class="caja-ofertas" *ngFor="let addpaq of this.offerList; let i = index">
                        <div class="packbox">
                            <div class="packtitle">
                                {{addpaq.displayName}}
                            </div>
                            <div class="packprice">
                                <div class="basicrow f-price text-center">
                                    {{addpaq.price}}
                                </div>
                                <div class="basicrow">
                                    <div class="redbtn" id="buy_btn"
                                        (click)="this.goToBuy(addpaq.displayName,addpaq.price,addpaq.offerId);">
                                        Comprar<i class="fa fa-shopping-cart righticon" aria-hidden="true"></i></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="roaming_additional_packages" [ngStyle]="{'display':this.selTab === 2 ? 'block' : 'none' }">
                    <div *ngIf="this.roamingList.length==0">
                            <div class="userinfo full">
                                <div class="basicrow contenedor-msj">
                                    <div class="basicrow f-bmed roboto-b m-bott m-top usag-text bot-mob">
                                        Actualmente no cuentas con Roaming
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div *ngIf="this.roamingList.length>0">
                            <div class="caja-ofertas" *ngFor="let addpaq of this.roamingList; let i = index">
                                    <div class="packbox">
                                        <div class="packtitle">
                                            {{addpaq.displayName}}
                                        </div>
                                        <div class="packprice">
                                            <div class="basicrow f-price text-center">
                                                {{addpaq.price}}
                                            </div>
                                            <div class="basicrow">
                                                <div class="redbtn" id="buy_btn"
                                                    (click)="this.goToBuy(addpaq.displayName,addpaq.price,addpaq.offerId);">
                                                    Comprar<i class="fa fa-shopping-cart righticon" aria-hidden="true"></i></div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                    </div>
            </div>
        </div>
    </div>
    <div class="basicrow">
        <div class="container container-m-w">
            <div class="linesprt"></div>
        </div>
    </div>
    <div class="allcont f-black f-reg roboto-r">
        <div class="container container-m-w">
            <div class="basicrow text-center">
                <div (click)="this.backToOptions();" class="graybtn next no-m" id="back_btn"><i
                        class="fa fa-angle-left lefticon" aria-hidden="true"></i> Volver</div>
            </div>
        </div>
    </div>
    <!--Inicia el footer-->
    <app-footer></app-footer>
</div>
