<div id="wrapper">
    <app-loading-screen></app-loading-screen>
    <!--Se pasa a componente despues-->
    <app-header></app-header>
    <!--Inicia componente del paso a paso-->
    <app-step [step]="2" *ngIf="screenActual == 1"></app-step>

    <!--Inicia contenido del modulo-->
    <div class="allcont f-black f-reg roboto-r"*ngIf="this.screenActual==1 && !executePaymentService.addressFieldsNeedToBeFilled && !executePaymentService.isFirstStep && !executePaymentService.applyPaymentValidation">
        <div class="container">
            <div class="basicrow">
                <div class="autocont pull-left f-bigtitle roboto-l">
                    Confirmar Paquete
                </div>
            </div>
            <div class="billcont">
                <div class="basicrow ">
                    <div class="autocont pull-left">Nro de Cuenta</div>
                    <div class="autocont pull-right roboto-b">{{this.banSelected}}</div>
                </div>
                <div class="basicrow bill-marg">
                    <div class="autocont pull-left">Nro de Tel&eacute;fono</div>
                    <div class="autocont pull-right roboto-b">{{this.subscriberActual}}</div>
                </div>
                <div class="basicrow bill-marg">
                    <div class="autocont pull-left">Tipo de Paquete</div>
                    <div class="autocont pull-right roboto-b">Local</div>
                </div>
                <div class="basicrow bill-marg">
                    <div class="autocont pull-left">Paquete</div>
                    <div class="autocont pull-right roboto-b">{{this.namePackage}}</div>
                </div>
                <div class="basicrow bill-marg f-red">
                    <div class="autocont pull-left">TOTAL A PAGAR</div>
                    <div class="autocont pull-right roboto-b" >{{this.pricePackage}}</div>
                </div>
            </div>
            <div class="basicrow text-center m-top">
                <div (click)="this.goTo();" class="redbtn next pull-right" id="continue_btn">Continuar <i class="fa fa-angle-right righticon" aria-hidden="true"></i></div>
                <div (click)="this.backTo();" class="graybtn next pull-left visible-xs" id="back_btn"><i class="fa fa-angle-left lefticon" aria-hidden="true"></i> Volver</div>
            </div>
        </div>
    </div>
    <div *ngIf="this.screenActual==2">
        ----
        <app-bill-buy-package 
        [confirmNumber]="this.confirmNumber" 
        [banSelected]="this.banSelected"
        [subscriberActual]="this.subscriberActual" 
        [namePackage]="this.namePackage"
        [pricePackage]="this.valueAmount"></app-bill-buy-package>
    </div>
    <app-payment-modal (screenActual)='updateScreen($event)'></app-payment-modal>
    <!--Inicia el footer-->
    <app-footer></app-footer>
  
</div>
