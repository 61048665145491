import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from '../../app.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../core/services/api-services/api.service';
import { LoadingScreenService } from '../../core/services/loading-screen/loading-screen.service';
import { GlobalService } from '../../core/services/global-services/global.service';
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import { PaymentMethodActive, ValidateCode } from 'src/app/core/interfaces/interfaces';
import { ExecutePaymentService } from 'src/app/core/services/execute-payment/execute-payment.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public accountInfo1: any;
  public continue: Subscription;
  constructor(public global: GlobalService, private loadingScreenService: LoadingScreenService,
              private router: Router, private formBuilder: FormBuilder,
              public appComponent: AppComponent, private services: ApiService,
              public dialog: MatDialog, public executePaymentService: ExecutePaymentService) {
                this.global.getCapchaKey();
                this.createForm();
  }

  ngOnInit() {
    this.global.signOff();
    this.global.setStep(1);
    this.loadToken();
    this.global.isFlashPayment = false;
    this.continue = this.global.continue.subscribe((data: any) => {
      this.saveData(data);
    });
    this.evaluateDataFromRedirect()
  }

  saveData(data) {
    sessionStorage.setItem('getSubscriber', btoa(JSON.stringify(data.object)));
    sessionStorage.setItem('subscriber', btoa(data.object.accountInfo.subscriberNumber));
    this.verifyAddress(data);
  }

  verifyAddress(data) {
    if (JSON.parse(atob(sessionStorage.getItem('getSubscriber'))).accountInfo.address &&
      JSON.parse(atob(sessionStorage.getItem('getSubscriber'))).accountInfo.city &&
      JSON.parse(atob(sessionStorage.getItem('getSubscriber'))).accountInfo.zip) {
      this.executePaymentService.isValidAddress = true;
    } else {
      this.executePaymentService.isValidAddress = false;
    }

    this.ckeckAccountType(data);
  }

  ckeckAccountType(data) {
    const typeAccountMobile = this.global.checkTypeAccount(data.object.accountInfo.accountType, data.object.accountInfo.accountSubtype);
    if (typeAccountMobile === 'prepago') {
      if (data.object.planPrice == 0) {
        data.object.planPrice = '';
      }
      sessionStorage.setItem('prepaid_balances', btoa(JSON.stringify(data.object)));
      this.router.navigate(['prepaid_buy_balance']);
    } else {
      // this.verifyEmail();
      this.loadingScreenService.stopLoading();
      this.router.navigate(['postpaid_select_option']);
    }
  }

  loadToken() {
    this.loadingScreenService.startLoading();
    this.services.getToken().subscribe(data => {
      if (data.hasError == false) {
        this.global.tokenMiClaro = data.object.token;
        sessionStorage.setItem('token', data.object.token);
        this.loadingScreenService.stopLoading();
      }
    });
  }

  validateData() {
    if (this.loginForm.valid) {
      this.global.subscriberReceiver = this.loginForm.value.number;
      this.global.ssnReceiver = this.loginForm.value.ssn;
      this.global.login(this.global.tokenMiClaro);
    }

  }

  verifyEmail() {
    this.accountInfo1 = JSON.parse(atob(sessionStorage.getItem('getSubscriber'))).accountInfo;
    this.services.verifyEmail(this.accountInfo1.BAN, 'collection-path', this.accountInfo1.email).subscribe(data => {
      // opciones de telemarketing
      /*
      if(response.object.telemarketingPayTax !== null && response.object.telemarketingPayTax !== undefined){
        app.utils.storage.setSessionItem('telemarketing_pay_tax', response.object.telemarketingPayTax);
      }
      */
      if (data.hasError == false) {
        this.router.navigate(['postpaid_select_option']);
        this.loadingScreenService.stopLoading();
      } else {
      }
    });
  }

  postPaidUser() {
    this.basicVisualReset();
    this.global.isPrepaid = false;

  }

  prepaidUser() {
    this.basicVisualReset();
    this.global.isPrepaid = true;
  }

  backToStep1() {
    this.global.isStep1 = true;
    this.global.isPrepaid = false;
    this.global.onPrepaidStep2 = false;
    this.global.subscriberReceiver = '';
    this.global.subscriberReceiver = '';
    this.global.ssnReceiver = '';
  }

  basicVisualReset() {
    this.global.isStep1 = false;
    this.global.onPrepaidStep2 = false;
  }

  backToPrepaidStep1() {
    this.global.onPrepaidStep2 = false;
    this.global.prepaidCode = '';
  }

  getValidationCode() {
    if (this.global.subscriberReceiver === '') {
      this.global.notif('Número de telefono obligatorio');
      return;
    } else if (this.global.subscriberReceiver.length < 10) {
      this.global.notif('Número de telefono incorrecto');
      this.global.subscriberReceiver = '';
      return;
    }

    this.loadingScreenService.startLoading();
    this.services.getValidationCode(this.global.subscriberReceiver).subscribe(
      (resp: any) => {
        this.loadingScreenService.stopLoading();
        if (!resp.hasError) {
          this.global.onPrepaidStep2 = true;
        } else {
          this.global.notif(resp.errorDesc);
        }
      },
      error => {
        this.loadingScreenService.stopLoading();
        this.global.notif(error);
      }
    );
  }
  validateCode() {
    if (this.global.prepaidCode === '') {
      this.global.notif('Código de confirmación obligatorio');
      return;
    }
    this.loadingScreenService.startLoading();
    this.services.validateCode(this.global.subscriberReceiver, this.global.prepaidCode).subscribe(
      (resp: ValidateCode) => {
        this.loadingScreenService.stopLoading();
        if (!resp.hasError) {
          this.global.getSubcriberInfo(this.global.tokenMiClaro, '', '', this.global.subscriberReceiver, true);
        } else {
          this.global.notif('No se ha podido validar el código, intente nuevamente');
          this.backToPrepaidStep1();
        }
      },
      error => {
        this.loadingScreenService.stopLoading();
        this.global.notif(error);
      }
    );
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      number: ['', Validators.required],
      ssn: ['', Validators.required],
      remember: false,
      //recaptcha: ['', Validators.required]
    });
  }

  evaluateDataFromRedirect() {
    const athData: string = this.router.parseUrl(this.router.url).queryParams[
      'apprCode'
    ];

    const sessionToken = this.router.parseUrl(this.router.url).queryParams[
      'st'
    ];

    const typeResult = this.router.parseUrl(this.router.url).queryParams[
      'type'
    ];

    const errorCode  = this.router.parseUrl(this.router.url).queryParams[
      'errCode'
    ];

    const cancelled  = this.router.parseUrl(this.router.url).queryParams[
      'cancelled'
    ];

    const utm_source  = this.router.parseUrl(this.router.url).queryParams[
      'utm_source'
    ];

    const utm_medium  = this.router.parseUrl(this.router.url).queryParams[
      'utm_medium'
    ];

    const utm_campaign  = this.router.parseUrl(this.router.url).queryParams[
      'utm_campaign'
    ];

    const utm_term  = this.router.parseUrl(this.router.url).queryParams[
      'utm_term'
    ];

    // const gclid  = this.router.parseUrl(this.router.url).queryParams[
    //   'gclid'
    // ];


    const paymenToken = this.router.parseUrl(this.router.url).queryParams['pt'];

    if (utm_source || utm_medium || utm_campaign || utm_term) {
      this.router.routerState.snapshot.url.toString()
      let urlBase = this.router.routerState.snapshot.url.toString().split('?')[0]
      this.router.navigate([urlBase]);
    }

    if (athData || errorCode) {
      const athList = athData.split(',');
      const authorizationNumber = athList[0];
      const confirmationNumber = athList[1];
      const dto = {
        paymentId:sessionToken? sessionToken: sessionStorage.getItem('paymentId') ,
        paymenToken:paymenToken? paymenToken: '' ,
        typeResult:  typeResult? typeResult: '',
        authorizationNumber:authorizationNumber? authorizationNumber: '' ,
        confirmationNumber:confirmationNumber? confirmationNumber: '' ,
        errorCode:errorCode? errorCode: ''
      }
      let dtoString = JSON.stringify(dto)
      sessionStorage.setItem('athData', dtoString);
      const athRoute =   sessionStorage.getItem('ath-route')
      if (athRoute){
        this.router.navigate(["payment_amount"]).then()
      } else {
        this.router.navigate(["prepaid_buy_balance"]).then()
      }


     } else if (cancelled) {
        const dto = {
          cancelled: cancelled
        }
        let dtoString = JSON.stringify(dto)
        sessionStorage.setItem('athData', dtoString);
        this.router.navigate(["payment_amount"]).then()
    }
  }

  checkPreviusRoute() {
    let athRoute = sessionStorage.getItem('ath-route')
    if (athRoute ==  "payment") {
      this.router.navigate(["payment_amount"]).then()
    } else  if (athRoute ==  "recharge") {
      this.router.navigate(["prepaid_buy_balance"]).then()
    }
   }
}
