<div *ngIf="false">
    <div class="chatWeb chat-cont first-step-bs text-center chatVisible" (click)="this.goToChat()">
        <a class="chatelement">
            <span class="chate-icon text-center">
                <img width="100%" class="imgOriginal" src="../../assets/images/clarobot_1.png">
                <img width="100%" class="imgBord" src="../../assets/images/clarobot_2.png">
            </span>
        </a>
    </div>
</div>
