import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';



@Injectable({
  providedIn: 'root'
})

export class EncryptWithKeyService {
  private ENCRYPTION_KEY: string;
  private ENCRYPTION_IV: string;
  private CYPHEROPTION: any;
  setKeys(key, iv) {
    this.ENCRYPTION_KEY = key;
    this.ENCRYPTION_IV = iv;
    this.CYPHEROPTION = {
      iv: CryptoJS.enc.Utf8.parse(this.ENCRYPTION_IV)
    };
  }

  secureData(data) {
    // tslint:disable-next-line:max-line-length
    return CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(this.ENCRYPTION_KEY), this.CYPHEROPTION).toString();
  }

  decrypt(tokenEncrypted) {
    // tslint:disable-next-line:max-line-length
    return CryptoJS.AES.decrypt(tokenEncrypted, CryptoJS.enc.Utf8.parse(this.ENCRYPTION_KEY), this.CYPHEROPTION).toString(CryptoJS.enc.Utf8);
  }

}
