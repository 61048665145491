import { Injectable } from "@angular/core";
import { ApiService } from "../api-services/api.service";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { AlertasComponent } from "../../../shared/components/alertas/alertas.component";
import { LoadingScreenService } from "../loading-screen/loading-screen.service";
import { Router } from "@angular/router";
import { Subject, Subscription } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class GlobalService {
  public referredSubscriber;
  public referredAccount;
  public ssnReceiver = "";
  public subscriberReceiver = "";
  public tokenMiClaro;
  public amountToPay2 = 0;
  public amountToPay3 = "";
  public siteKey: string = "";
  public urlPayment;
  public cancel = false;
  public loadObserver = new Subject();
  public loadObserver2 = new Subject();
  public verificarPago = new Subject();
  public continue = new Subject();
  public paymentReceived = new Subject();
  public chatStatus = new Subject(); // suscripcion para abrir el chat
  public uptypeAccount = new Subject(); // suscripcion al tipo de cuenta
  public loadingPayment: Subscription;
  public paymentMethodActive = "";
  public isPrepaid = false;
  public isStep1 = true;
  public onPrepaidStep2 = false;
  public prepaidCode = "";
  public stepCheck = new Subject();
  private sessionCounter = 1;
  private minutes = 20;
  private timeout = 60000;
  private checker = false;
  isFlashPayment = true;
  showSplash = false;

  public loadFooter = false;
  public paymentDescription: string = "";
  public currentScreen: any = 1;
  public authorizationNumber: string = "";
  public referenceNumber: string = "";
  public paymentStartTime: number = 0;
  public widthIframePay = "100%";
  public heightIframePay = "460px";
  public frameborderIframePay = 0;
  public scrollingIframePay = "";
  public tokenIframePay = "";
  public emailIP = "";
  public emailIframePay: string = "";
  public configLoaded: boolean = false;
  public hasProvision: boolean = false;
  processDone = false;
  constructor(
    private router: Router,
    public dialog: MatDialog,
    public services: ApiService,
    private loadingScreenService: LoadingScreenService
  ) {
    this.services.getConfig().subscribe(
      (data: any) => {
        this.paymentStartTime = data.paymentStartTime;
        this.widthIframePay = data.widthIframePay;
        this.heightIframePay = data.heightIframePay;
        this.frameborderIframePay = data.frameborderIframePay;
        this.scrollingIframePay = data.scrollingIframePay;
        this.emailIframePay = data.emailIframePay;
        this.configLoaded = true;
      },
      (error) => this.errorHandling(error)
    );
  }
  hasDimensions(e: any) {
    if (this.loadFooter == false) {
      this.loadingScreenService.stopLoading();
    }
    this.loadFooter = true;
  }
  initPayment() {
    this.loadingScreenService.startLoading();
  }
  resultPaymentIframe(e: any) {
    try {
      this.loadingScreenService.stopLoading();
      this.loadFooter = false;
      this.screenUp();
      this.bindWithCollectionPath(e);
      if (e.success == true) {
        this.setStep(4);
        this.authorizationNumber = e.authorizationNumber;
        this.referenceNumber = e.provisioning.referenceNumber;
        this.paymentDescription = e.description;
        sessionStorage.setItem("transactionId", this.authorizationNumber);
        if (e.provisioning) {
          if (e.provisioning.success == true) {
            this.hasProvision = true;
          }
        }
        this.currentScreen = 3;
      } else if (e.success == false && e.code == 6) {
        this.setStep(3);
        this.paymentDescription = e.description;
        this.currentScreen = 4;
      } else {
        this.paymentDescription = e.description;
        this.setStep(3);
        this.currentScreen = 4;
      }
    } catch (error) {}
  }

  bindWithCollectionPath(e: any) {
    this.getTransactionId(e);
  }

  getTransactionId(e: any) {
    if (!this.processDone) {
      this.processDone = true;
      this.services
        .createTransaction(
          "MEPMMTCRDT",
          "POSTPAID_PAY_INVOICE",
          "",
          sessionStorage.getItem("token")
        )
        .subscribe(
          (resp: any) => {
            const transaction = resp;
            sessionStorage.setItem(
              "transactionIdSplit1",
              transaction.object.id
            );
            sessionStorage.setItem("transactionId", transaction.object.id);
            setTimeout(() => {
              let banSelected = JSON.parse(
                atob(sessionStorage.getItem("getSubscriber"))
              ).accountInfo.BAN;
              let subscriberName = JSON.parse(
                atob(sessionStorage.getItem("getSubscriber"))
              ).accountInfo.firstName;
              let subscriberLastName = JSON.parse(
                atob(sessionStorage.getItem("getSubscriber"))
              ).accountInfo.lastName;
              let amountToPay = sessionStorage.getItem("amountToPay");
              this.updateTransaction(
                e,
                amountToPay,
                banSelected,
                subscriberName,
                subscriberLastName
              );
            }, 1000);
          },
          (error) => {
            this.notif(error.errorDesc);
          }
        );
    }
  }

  updateTransaction(
    verifyPaymentStatus,
    amountToPay,
    banSelected,
    subscriberName,
    subscriberLastName
  ) {
    let id = sessionStorage.getItem("transactionIdSplit1") ?? "";
    // this.loadingScreenService.startLoading();
    this.services
      .updateTransaction(
        id,
        verifyPaymentStatus.code == 0 ? "0000" : verifyPaymentStatus.code,
        verifyPaymentStatus.provisioning.referenceNumber,
        verifyPaymentStatus.authorizationNumber,
        verifyPaymentStatus.success +
          "###" +
          verifyPaymentStatus.provisioning.success,
        verifyPaymentStatus.description,
        this.getTransactionStatusReview(
          verifyPaymentStatus.success,
          verifyPaymentStatus.provisioning.success
        ),
        sessionStorage.getItem("token"),
        Number(sessionStorage.getItem("urlId") ?? "0"),
        amountToPay,
        "POSTPAID_PAY_INVOICE",
        "completed",
        JSON.parse(atob(sessionStorage.getItem("getSubscriber"))).accountInfo
          .accountType,
        JSON.parse(atob(sessionStorage.getItem("getSubscriber"))).accountInfo
          .accountSubtype,
        banSelected,
        banSelected,
        amountToPay,
        subscriberName + " " + subscriberLastName
      )
      .subscribe((data) => {
        this.loadingScreenService.stopLoading();
        setTimeout(()=>{
          this.processDone = false
        }, 8000)
      });
  }

  private getTransactionStatusReview(paymentStatus, provisioningStatus) {
    if (paymentStatus === true && provisioningStatus === true) {
      return "provisioning";
    }

    if (paymentStatus === true && provisioningStatus === false) {
      return "approved";
    }

    if (paymentStatus === false && provisioningStatus === false) {
      return "rejected";
    }
  }

  errorHandling(e: any) {
    this.notif(
      "Estimado cliente, ha ocurrido un error por favor intentelo mas tarde."
    );
    this.loadingScreenService.stopLoading();
  }
  irChat() {
    this.chatStatus.next(true);
  }

  updateTypeAccount(value) {
    this.uptypeAccount.next(value);
  }

  refreshDataTo(token) {}
  screenUp() {
    window.scroll({
      top: 60,
      left: 0,
      behavior: "smooth",
    });
  }
  login(token) {
    if (this.subscriberReceiver === null || this.subscriberReceiver === "") {
      this.notif("Número de teléfono obligatorio");
    } else if (
      this.subscriberReceiver.length < 9 ||
      this.subscriberReceiver.length > 10
    ) {
      this.notif("Número de teléfono o cuenta incompleto o inválido");
    } else if (
      this.subscriberReceiver.length == 10 &&
      (this.ssnReceiver === null || this.ssnReceiver === "")
    ) {
      this.notif("Número del SSN obligatorio");
    } else if (
      this.subscriberReceiver.length == 9 &&
      (this.ssnReceiver === null || this.ssnReceiver === "")
    ) {
      this.notif("Número del SSN obligatorio");
    } else if (
      this.subscriberReceiver.length == 10 &&
      this.ssnReceiver.length < 4
    ) {
      this.notif("SSN incompleto");
    } else if (
      this.subscriberReceiver.length == 9 &&
      this.ssnReceiver.length < 4
    ) {
      this.notif("SSN incompleto");
    } else {
      this.subscriberReceiver = this.subscriberReceiver.trim();
      const payload = {
        subscriber:
          this.subscriberReceiver.length === 10 ? this.subscriberReceiver : "",
        account:
          this.subscriberReceiver.length === 9 ? this.subscriberReceiver : "",
        last4PinCode: this.ssnReceiver,
      };
      this.loadingScreenService.startLoading();
      this.services.authenticatePin(payload).subscribe(
        (data: any) => {
          this.loadingScreenService.stopLoading();
          if (data.SSOAccessToke != "") {
            sessionStorage.setItem("SSOAccessToken", data.SSOAccessToken);
          }

          if (!data.hasError) {
            this.callGetSubscriber(token);
          } else {
            this.loadingScreenService.stopLoading();
            this.notif(data.errorDesc);
          }
        },
        (error) => {
          this.loadingScreenService.stopLoading();
          this.notif(
            "Ha ocurrido un error por favor, intente nuevamente más tarde"
          );
        }
      );
    }
  }

  callGetSubscriber(token) {
    if (this.subscriberReceiver.length == 10) {
      this.getSubcriberInfo(
        token,
        "",
        this.ssnReceiver,
        this.subscriberReceiver
      );
    } else if (this.subscriberReceiver.length == 9) {
      this.getBanInfo(token, this.subscriberReceiver, this.ssnReceiver, "");
    }
  }

  getSubcriberInfo(token, account, ssn, subscriber, isPrepaid = false) {
    this.loadingScreenService.startLoading();
    this.services
      .subscriber(token, account, ssn, subscriber)
      .subscribe((resp: any) => {
        const respString: string = resp.dataToken.replace(
          "pIvhi9.qodmyxcyhqow.",
          ""
        );
        const data = JSON.parse(atob(atob(atob(respString))));
        this.getSubscriberData(data, isPrepaid, ssn);
      });
  }

  getSubscriberData(data, isPrepaid, ssn) {
    if (data.hasError == false) {
      let completeSSN: string = data.object.accountInfo.SSN;
      let initIndex = completeSSN.length - 4;
      if (!isPrepaid) {
        if (completeSSN.substring(initIndex, completeSSN.length) === ssn) {
          this.completeLoginProcess(data);
        } else {
          const message = "La información suministrada es incorrecta";
          this.loadingScreenService.stopLoading();
          this.notif(message);
        }
      } else {
        this.completeLoginProcess(data);
      }
    } else {
      const message =
        "Número de teléfono inválido o perteneciente a otra operadora telefónica";
      this.errorHandlingData(data, message);
    }
  }

  completeLoginProcess(data) {
    this.generalDataManipulation(data);
    // INSTALLMENT
    if (data.object.token !== null && data.object.token !== undefined) {
      this.installmentManipulation(data);
    } else {
      this.communicateData(data);
    }
  }

  getBanInfo(token, account, ssn, subscriber, isPrepaid = false) {
    this.loadingScreenService.startLoading();
    this.services
      .subscriber(token, account, ssn, subscriber)
      .subscribe((resp: any) => {
        const respString: string = resp.dataToken.replace(
          "pIvhi9.qodmyxcyhqow.",
          ""
        );
        const data = JSON.parse(atob(atob(atob(respString))));
        this.getSubscriberData(data, isPrepaid, ssn);
      });
  }

  generalDataManipulation(data) {
    if (
      data.object.telemarketingPayTax !== null &&
      data.object.telemarketingPayTax !== undefined
    ) {
      sessionStorage.setItem(
        "telemarketing_pay_tax",
        btoa(JSON.stringify(data.object.telemarketingPayTax))
      );
    }
    if (
      data.object.subscriberInfo !== null &&
      data.object.subscriberInfo !== undefined
    ) {
      sessionStorage.setItem(
        "subscriberInfo",
        btoa(JSON.stringify(data.object.subscriberInfo))
      );
    }
  }

  installmentManipulation(data) {
    // INSTALLMENT
    sessionStorage.setItem("getSubscriber", btoa(JSON.stringify(data.object)));
    // tslint:disable-next-line:max-line-length
    sessionStorage.setItem(
      "subscriber",
      btoa(
        data.object.accountInfo.subscriberNumber !== ""
          ? data.object.accountInfo.subscriberNumber
          : data.object.subscriberInfo[0].subscriberNumber
      )
    );
    sessionStorage.setItem(
      "subscriberInfo",
      btoa(JSON.stringify(data.object.subscriberInfo))
    );
    sessionStorage.setItem("paymentToken", data.object.token);
    sessionStorage.setItem("urlId", data.object.urlId);
    this.services.getPaymentInfo(data.object.token).subscribe((value) => {
      if (value.hasError) {
        sessionStorage.removeItem("paymentToken");
      }
      this.continue.next(data);
      this.loadingScreenService.stopLoading();
    });
  }

  communicateData(data) {
    this.continue.next(data);
    this.loadingScreenService.stopLoading();
  }

  errorHandlingData(data, message) {
    if (data.code == "001") {
      this.notif("Disculpe, la sesión de la aplicación ha expirado");
    } else if (data.code == "002") {
      this.notif(message);
    }
    this.loadingScreenService.stopLoading();
  }

  notif(msg) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { message: msg };
    this.dialog.open(AlertasComponent, dialogConfig);
  }

  notifSignOff(msg) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { message: msg, session: true };
    this.dialog.open(AlertasComponent, dialogConfig);
  }

  session() {
    sessionStorage.setItem("session", "true");
    this.sessionCounter = 1;
    setTimeout(() => {
      if (this.checker == false) {
        this.checker = true;
        this.activeSession(0, false);
      }
    }, this.timeout);
  }

  activeSession(sw, close) {
    if (close == false) {
      if (sw == 0) {
        if (this.sessionCounter == this.minutes) {
          if (sessionStorage.getItem("session") == "true") {
            this.notifSignOff("Su sesión se ha vencido.");
            this.signOff();
          }
        } else {
          if (sessionStorage.getItem("session") == "true") {
            this.sessionCounter++;
            setTimeout(() => {
              this.activeSession(0, false);
            }, this.timeout);
          }
        }
      } else {
        this.sessionCounter = 1;
        this.notifSignOff("Su sesión se ha vencido.");
        this.signOff();
      }
    } else {
      this.sessionCounter = 1;
      sessionStorage.setItem("session", "false");
    }
  }

  validateSession() {
    if (
      sessionStorage.getItem("session") == "false" ||
      sessionStorage.getItem("session") == null
    ) {
      this.router.navigate(["login"]);
      return false;
    } else {
      this.session();
      return true;
    }
  }

  sessionReview() {
    // tslint:disable-next-line:max-line-length
    if (
      sessionStorage.getItem("session") == "false" ||
      sessionStorage.getItem("session") == null ||
      sessionStorage.getItem("session") == undefined
    ) {
      return false;
    } else {
      return true;
    }
  }

  public signOff() {
    this.sessionCounter = 1;
    sessionStorage.setItem("session", "false");
    sessionStorage.setItem("objLogin", null);
    sessionStorage.setItem("menu", null);
    sessionStorage.setItem("session", null);

    sessionStorage.removeItem("session");
    sessionStorage.removeItem("objLogin");
    sessionStorage.removeItem("menu");
  }

  validarEmail(email) {
    const expr =
      /^(?=[A-Z])[A-Z0-9_\-\.]+@(?=(([A-Z0-9_\-]+\.)+))\1[A-Z]{2,4}$/i;
    return expr.test(email);
  }

  newPayment(
    productId,
    typeMessage,
    currentBan,
    subscriber,
    amount,
    email,
    name,
    offerId,
    token,
    session = "",
    urlPayment = null,
    paymentMode = null
  ) {
    if (amount >= 5 && amount <= 2000) {
      this.loadingScreenService.startLoading();

      // installment
      if (urlPayment !== null) {
        this.urlPayment = urlPayment;
        this.loadingScreenService.stopLoading();
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.minWidth = "300px";
        dialogConfig.data = {
          message:
            "Será redirigido al portal de pago para completar el mismo por la cantidad de $" +
            amount +
            ".",
          colorSi: "colorRed",
          colorNo: "colorGray",
          btmConfirm: true,
          labelSi: "Aceptar",
          labelNo: "Cancelar",
        };
        const dialogRef = this.dialog.open(AlertasComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((value) => {
          if (value == "continue") {
            // var windS= window.open(this.urlPayment, '_blank');
            const windS = window.open(
              this.urlPayment,
              "Pago en línea",
              "width=1000,height=800,scrollbars=NO"
            );

            const data = {
              paymentToken: token,
            };

            if (windS == null || typeof windS == "undefined") {
              // tslint:disable-next-line:no-shadowed-variable
              const dialogConfig = new MatDialogConfig();
              dialogConfig.disableClose = true;
              dialogConfig.autoFocus = true;
              // tslint:disable-next-line:max-line-length
              dialogConfig.data = {
                message:
                  "Estimado cliente, la ventana ha sido bloqueada por el navegador, para poder realizar el pago habilite la opcion de la ventana emergente en el navegador.",
              };
              // tslint:disable-next-line:no-shadowed-variable
              const dialogRef = this.dialog.open(
                AlertasComponent,
                dialogConfig
              );
              dialogRef.afterClosed().subscribe((value1) => {
                if (value1 === "continue") {
                  // tslint:disable-next-line:max-line-length
                  this.loadingScreenService.startLoading(
                    true,
                    "Estimado Cliente, estamos esperando que habilite los permisos de la ventana emergente, si ya realizó el pago por favor espere que lo validemos.",
                    true,
                    session
                  );
                  this.cancel = false;
                  this.verifyPaymentStatus(typeMessage, data, 0, session);
                }
              });
            } else {
              windS.focus();
              // tslint:disable-next-line:max-line-length
              this.loadingScreenService.startLoading(
                true,
                "Está siendo redirigido a la página del Banco Popular para realizar el pago.",
                true,
                session
              );
              this.cancel = false;
              this.verifyPaymentStatus(typeMessage, data, 0, session);
            }
          }
        });
      } else {
        this.services
          .newPayment(productId, offerId, paymentMode)
          .subscribe((data) => {
            if (data.errorNum == 0) {
              if (data.paymentOptions.length > 0) {
                // tslint:disable-next-line:max-line-length
                const index = data.paymentOptions
                  .map((x) => x.paymentOptionType)
                  .indexOf("CHECKOUT");
                this.services
                  .applyPayment(
                    "",
                    productId,
                    data.paymentOptions[index].paymentOptionId,
                    amount,
                    email,
                    subscriber,
                    currentBan,
                    name,
                    "dir1",
                    "dir2",
                    "san juan",
                    "PR",
                    "00956",
                    "MICLAROWEB INVOICE PAYMENT",
                    "",
                    "",
                    "",
                    "",
                    this.isFlashPayment ? "FLASH" : ""
                  )
                  .subscribe((data2) => {
                    if (data2.errorNum == 0 && data2.hasError == false) {
                      this.urlPayment = data2.redirectURL;
                      this.loadingScreenService.stopLoading();
                      const dialogConfig = new MatDialogConfig();
                      dialogConfig.disableClose = true;
                      dialogConfig.autoFocus = true;
                      dialogConfig.minWidth = "300px";
                      dialogConfig.data = {
                        message:
                          "Será redirigido al portal de pago para completar el mismo por la cantidad de $" +
                          amount +
                          ".",
                        colorSi: "colorRed",
                        colorNo: "colorGray",
                        btmConfirm: true,
                        labelSi: "Aceptar",
                        labelNo: "Cancelar",
                      };
                      const dialogRef = this.dialog.open(
                        AlertasComponent,
                        dialogConfig
                      );
                      dialogRef.afterClosed().subscribe((value) => {
                        if (value == "continue") {
                          // var windS= window.open(this.urlPayment, '_blank');
                          const windS = window.open(
                            this.urlPayment,
                            "Pago en línea",
                            "width=1000,height=800,scrollbars=NO"
                          );
                          if (windS == null || typeof windS == "undefined") {
                            // tslint:disable-next-line:no-shadowed-variable
                            const dialogConfig = new MatDialogConfig();
                            dialogConfig.disableClose = true;
                            dialogConfig.autoFocus = true;
                            // tslint:disable-next-line:max-line-length
                            dialogConfig.data = {
                              message:
                                "Estimado cliente, la ventana ha sido bloqueada por el navegador, para poder realizar el pago habilite la opcion de la ventana emergente en el navegador.",
                            };
                            // tslint:disable-next-line:no-shadowed-variable
                            const dialogRef = this.dialog.open(
                              AlertasComponent,
                              dialogConfig
                            );
                            dialogRef.afterClosed().subscribe((value1) => {
                              if (value1 === "continue") {
                                // tslint:disable-next-line:max-line-length
                                this.loadingScreenService.startLoading(
                                  true,
                                  "Estimado Cliente, estamos esperando que habilite los permisos de la ventana emergente, si ya realizó el pago por favor espere que lo validemos.",
                                  true,
                                  session
                                );
                                this.cancel = false;
                                this.verifyPaymentStatus(
                                  typeMessage,
                                  data2,
                                  0,
                                  session
                                );
                              }
                            });
                          } else {
                            windS.focus();
                            // tslint:disable-next-line:max-line-length
                            this.loadingScreenService.startLoading(
                              true,
                              "Está siendo redirigido a la página del Banco Popular para realizar el pago.",
                              true,
                              session
                            );
                            this.cancel = false;
                            this.verifyPaymentStatus(
                              typeMessage,
                              data2,
                              0,
                              session
                            );
                          }
                        }
                      });
                    } else {
                      this.loadingScreenService.stopLoading();
                      // const username2 = sessionStorage.getItem('name');
                      // let accountName = username2.split(' ');
                      const menssageError =
                        "El servicio del banco no responde con la url de pago.";
                      const dialogConfig = new MatDialogConfig();
                      dialogConfig.disableClose = true;
                      dialogConfig.autoFocus = true;
                      dialogConfig.data = {
                        inverse: true,
                        colorSi: "colorRed",
                        colorNo: "colorGray",
                        labelSi: "Conectar vía Chat",
                        labelNo: "No, gracias",
                        menssageError,
                        messageHtml: false,
                        labelTitleHtml: "Bienvenido a Mi Claro Asistencia",
                        message: "",
                        email,
                        btmConfirm: true,
                      };
                      const dialogRef = this.dialog.open(
                        AlertasComponent,
                        dialogConfig
                      );
                      dialogRef.afterClosed().subscribe((value) => {
                        if (value == "continue") {
                          this.irChat();
                        }
                      });
                    }
                  });
              } else {
                this.loadingScreenService.stopLoading();
                // const username2 = sessionStorage.getItem('name');
                // let accountName = username2.split(' ');
                const menssageError =
                  "El servicio del banco no responde con la url de pago.";
                const dialogConfig = new MatDialogConfig();
                dialogConfig.disableClose = true;
                dialogConfig.autoFocus = true;
                dialogConfig.data = {
                  inverse: true,
                  colorSi: "colorRed",
                  colorNo: "colorGray",
                  labelSi: "Conectar vía Chat",
                  labelNo: "No, gracias",
                  menssageError,
                  messageHtml: false,
                  labelTitleHtml: "Bienvenido a Mi Claro Asistencia",
                  message: "",
                  email,
                  btmConfirm: true,
                };
                const dialogRef = this.dialog.open(
                  AlertasComponent,
                  dialogConfig
                );
                dialogRef.afterClosed().subscribe((value) => {
                  if (value == "continue") {
                    this.irChat();
                  }
                });
              }
            } else {
              this.loadingScreenService.stopLoading();
              // const username2 = sessionStorage.getItem('name');
              // const accountName = username2.split(' ');
              const menssageError =
                "El servicio del banco no responde con la url de pago.";
              const dialogConfig = new MatDialogConfig();
              dialogConfig.disableClose = true;
              dialogConfig.autoFocus = true;
              dialogConfig.data = {
                inverse: true,
                colorSi: "colorRed",
                colorNo: "colorGray",
                labelSi: "Conectar vía Chat",
                labelNo: "No, gracias",
                menssageError,
                messageHtml: false,
                labelTitleHtml: "Bienvenido a Mi Claro Asistencia",
                message: "",
                email,
                btmConfirm: true,
              };
              const dialogRef = this.dialog.open(
                AlertasComponent,
                dialogConfig
              );
              dialogRef.afterClosed().subscribe((value) => {
                if (value == "continue") {
                  this.irChat();
                }
              });
            }
          });
      }
    } else {
      if (amount < 5) {
        this.notif("El monto no puede ser menor a $5.00.");
      } else if (amount > 2000) {
        this.notif("El monto no puede ser mayor a $2000.00.");
      }
    }
  }

  // tslint:disable-next-line:max-line-length
  newPaymentNoLimit(
    productId,
    typeMessage,
    currentBan,
    subscriber,
    amount,
    email,
    name,
    offerId,
    token,
    banSend = "",
    subscribeSend = "",
    locationId = "",
    invoiceNumber = "",
    session = ""
  ) {
    this.loadingScreenService.startLoading(
      true,
      "Está siendo redirigido a la página del Banco Popular para realizar el pago.",
      true
    );
    this.services.newPayment(productId, offerId, "ATH").subscribe((data) => {
      if (data.errorNum == 0) {
        if (data.paymentOptions.length > 0) {
          // tslint:disable-next-line:max-line-length
          const index = data.paymentOptions
            .map((x) => x.paymentOptionType)
            .indexOf("CHECKOUT");
          if (email === null || email === undefined) {
            email = "noemail@claropr.com";
          }
          this.services
            .applyPayment(
              offerId,
              productId,
              data.paymentOptions[index].paymentOptionId,
              amount,
              email,
              subscriber,
              currentBan,
              name,
              "dir1",
              "dir2",
              "san juan",
              "PR",
              "00956",
              "MICLAROWEB INVOICE PAYMENT",
              banSend,
              subscribeSend,
              locationId,
              invoiceNumber,
              this.isFlashPayment ? "FLASH" : ""
            )
            .subscribe((data2) => {
              if (data2.errorNum == 0 && data2.hasError == false) {
                this.urlPayment = data2.redirectURL;
                this.loadingScreenService.stopLoading();
                const dialogConfig = new MatDialogConfig();
                dialogConfig.disableClose = true;
                dialogConfig.autoFocus = true;
                dialogConfig.minWidth = "300px";
                dialogConfig.data = {
                  message:
                    "Será redirigido al portal de pago para completar el mismo por la cantidad de $" +
                    amount +
                    ".",
                  colorSi: "colorRed",
                  colorNo: "colorGray",
                  btmConfirm: true,
                  labelSi: "Aceptar",
                  labelNo: "Cancelar",
                };
                const dialogRef = this.dialog.open(
                  AlertasComponent,
                  dialogConfig
                );
                dialogRef.afterClosed().subscribe((value) => {
                  if (value == "continue") {
                    // var windS= window.open(this.urlPayment, '_blank');
                    const windS = window.open(
                      this.urlPayment,
                      "Pago en línea",
                      "width=1000,height=800,scrollbars=NO"
                    );
                    if (windS == null || typeof windS == "undefined") {
                      // tslint:disable-next-line:no-shadowed-variable
                      const dialogConfig = new MatDialogConfig();
                      dialogConfig.disableClose = true;
                      dialogConfig.autoFocus = true;
                      // tslint:disable-next-line:max-line-length
                      dialogConfig.data = {
                        message:
                          "Estimado cliente, la ventana ha sido bloqueada por el navegador, para poder realizar el pago habilite la opcion de la ventana emergente en el navegador.",
                      };
                      // tslint:disable-next-line:no-shadowed-variable
                      const dialogRef = this.dialog.open(
                        AlertasComponent,
                        dialogConfig
                      );
                      dialogRef.afterClosed().subscribe((value1) => {
                        if (value1 == "continue") {
                          // tslint:disable-next-line:max-line-length
                          this.loadingScreenService.startLoading(
                            true,
                            "Estimado Cliente, estamos esperando que habilite los permisos de la ventana emergente, si ya realizó el pago por favor espere que lo validemos.",
                            true,
                            session
                          );
                          this.cancel = false;
                          this.verifyPaymentStatus(
                            typeMessage,
                            data2,
                            0,
                            session
                          );
                        }
                      });
                    } else {
                      windS.focus();
                      // tslint:disable-next-line:max-line-length
                      this.loadingScreenService.startLoading(
                        true,
                        "Está siendo redirigido a la página del Banco Popular para realizar el pago.",
                        true,
                        session
                      );
                      this.cancel = false;
                      this.verifyPaymentStatus(typeMessage, data2, 0, session);
                    }
                  }
                });
              } else {
                this.loadingScreenService.stopLoading();
                // const username2 = sessionStorage.getItem('name');
                // const accountName = username2.split(' ');
                const menssageError =
                  "El servicio del banco no responde con la url de pago.";
                const dialogConfig = new MatDialogConfig();
                dialogConfig.disableClose = true;
                dialogConfig.autoFocus = true;
                dialogConfig.data = {
                  inverse: true,
                  colorSi: "colorRed",
                  colorNo: "colorGray",
                  labelSi: "Conectar vía Chat",
                  labelNo: "No, gracias",
                  menssageError,
                  messageHtml: false,
                  labelTitleHtml: "Bienvenido a Mi Claro Asistencia",
                  message: "",
                  email,
                  btmConfirm: true,
                };
                const dialogRef = this.dialog.open(
                  AlertasComponent,
                  dialogConfig
                );
                dialogRef.afterClosed().subscribe((value) => {
                  if (value == "continue") {
                    // colocar aqui el nuevo chat
                    this.irChat();
                    /*
                   let url = this.pathChat + '?Email=' + email + '&firstname=' + accountName[0] +
                  '&lastname=' + accountName[1] + '&Question=' +
                  'Problema para iniciar el boton de pago.' + '&Department=' + '16'
                   + '';
                  window.open(url, '', 'width=630, height=490,top=100,left=100');
                  */
                  }
                });
              }
            });
        } else {
          this.loadingScreenService.stopLoading();
          // const username2 = sessionStorage.getItem('name');
          // const accountName = username2.split(' ');
          const menssageError =
            "El servicio del banco no responde con la url de pago.";
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.data = {
            inverse: true,
            colorSi: "colorRed",
            colorNo: "colorGray",
            labelSi: "Conectar vía Chat",
            labelNo: "No, gracias",
            menssageError,
            messageHtml: false,
            labelTitleHtml: "Bienvenido a Mi Claro Asistencia",
            message: "",
            email,
            btmConfirm: true,
          };
          const dialogRef = this.dialog.open(AlertasComponent, dialogConfig);
          dialogRef.afterClosed().subscribe((value) => {
            if (value == "continue") {
              // colocar aqui el nuevo chat
              this.irChat();
              /*
              let url = this.pathChat + '?Email=' + email + '&firstname=' +
               accountName[0] + '&lastname=' + accountName[1] + '&Question=' +
               'Problema para iniciar el boton de pago.' + '&Department=' + '16' + '';
              window.open(url, '', 'width=630, height=490,top=100,left=100');*/
            }
          });
        }
      } else {
        this.loadingScreenService.stopLoading();
        // const username2 = sessionStorage.getItem('name');
        // const accountName = username2.split(' ');
        const menssageError =
          "El servicio del banco no responde con la url de pago.";
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
          inverse: true,
          colorSi: "colorRed",
          colorNo: "colorGray",
          labelSi: "Conectar vía Chat",
          labelNo: "No, gracias",
          menssageError,
          messageHtml: false,
          labelTitleHtml: "Bienvenido a Mi Claro Asistencia",
          message: "",
          email,
          btmConfirm: true,
        };
        const dialogRef = this.dialog.open(AlertasComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((value) => {
          if (value == "continue") {
            // colocar aqui el nuevo chat
            this.irChat();
            /*
            let url = this.pathChat + '?Email=' + email + '&firstname=' + accountName[0] +
              '&lastname=' + accountName[1] + '&Question=' + 'Problema para iniciar el boton de pago.' +
              '&Department=' + '16' + '';
            window.open(url, '', 'width=630, height=490,top=100,left=100');
            */
          }
        });
      }
    });
  }

  checkTypeAccount(tipo, SubTipo) {
    let typeAccount = "no-detect";
    // tslint:disable-next-line:max-line-length
    if (
      (tipo === "I3" && SubTipo === "4") ||
      (tipo === "I" && SubTipo === "S") ||
      (tipo === "I2" && SubTipo === "4") ||
      (tipo === "I" && SubTipo === "R") ||
      (tipo === "I" && SubTipo === "4") ||
      (tipo === "I" && SubTipo === "E")
    ) {
      typeAccount = "postpago";
    }
    if (
      (tipo === "I" && SubTipo === "P") ||
      (tipo === "I3" && SubTipo === "P")
    ) {
      typeAccount = "prepago";
    }
    if (tipo === "I" && SubTipo === "W") {
      typeAccount = "telefoniaFijo";
    }
    return typeAccount;
  }

  verifyPaymentStatus(typeMessage, data, cont, session = "") {
    // se evalua si se cancelo la carga en el loading
    this.loadingPayment = this.loadingScreenService.loadingStatus.subscribe(
      (value: boolean) => {
        if (value == false) {
          this.cancel = true;
          // tslint:disable-next-line:no-unused-expression
          this.loadingPayment.unsubscribe;
          // tslint:disable-next-line:no-unused-expression
          this.loadingPayment.remove;
          this.paymentReceived.next(false);
        }
      }
    );
    const time = setTimeout(() => {
      if (this.cancel == true) {
        // si se cancela la carga se quita el tiempo y no carga mas el servicio
        this.cancel = false;
        this.loadingPayment.unsubscribe();
        clearTimeout(time);
      } else {
        sessionStorage.setItem("verifyPaymentStatus", null);

        this.loadingScreenService.startLoading(
          true,
          "Por favor espere, estamos verificando su pago.",
          true,
          session
        );
        this.services
          .verifyPaymentStatus(data.paymentToken)
          .subscribe((result) => {
            sessionStorage.setItem(
              "verifyPaymentStatus",
              JSON.stringify(result)
            );

            if (result.errorNum == 0) {
              if (result.processEndState == true) {
                if (result.hasError == false) {
                  this.loadingScreenService.stopLoading();
                  if (result.errorCode == "") {
                    sessionStorage.setItem(
                      "numConfirmation",
                      result.confirmationNumber
                    );
                    sessionStorage.setItem(
                      "numAuthorization",
                      result.authorizationNumber
                    );
                    this.paymentReceived.next(true);
                  } else {
                    this.notif(result.errorDisplay);
                    this.router.navigate(["login"]);
                  }
                } else {
                  this.loadingScreenService.stopLoading();
                  this.notif(result.errorDisplay);
                  this.router.navigate(["login"]);
                }
              } else {
                // if (cont < 5) {
                if (new Date().getTime() < Date.parse(result.paymentAgedDate)) {
                  cont++;
                  this.cancel = false;
                  this.verifyPaymentStatus(typeMessage, data, cont);
                } else {
                  // if (typeMessage == 'payment') {
                  // tslint:disable-next-line:max-line-length
                  //   this.notif('Estimado cliente, hemos encontrado un problema al aplicar el pago en tu cuenta.   Si su pago fue autorizado por el banco, puede tomar entre 24 y 48 horas.   Si tiene alguna duda con su pago favor de consultarle a Centro de Servicio al cliente.');
                  // } else if (typeMessage == 'prepaid') {
                  // tslint:disable-next-line:max-line-length
                  //   this.notif('Estimado cliente, hemos encontrado un problema verificar su pago.   Si su recarga fue autorizado por el banco, y no la ve reflejada no esta reflejada en su cuenta de Claro Prepago, favor de consultarle a Centro de Servicio al cliente.');
                  // } else {
                  // tslint:disable-next-line:max-line-length
                  //   this.notif('Estimado cliente, hemos encontrado un problema verificar su pago.   Si su compra fue autorizada por el banco, y no la ve reflejada no esta reflejada en su cuenta, favor de consultarle a Centro de Servicio al cliente.');
                  // }

                  // tslint:disable-next-line:max-line-length
                  this.notif(
                    "Estimado cliente, el tiempo para ejecutar el pago termino. Si tiene alguna duda con su pago favor de consultarle a Centro de Servicio al cliente."
                  );

                  this.loadingScreenService.stopLoading();
                  // this.paymentReceived.next(false);
                  this.router.navigate(["login"]);
                }
              }
            } else {
              this.loadingScreenService.stopLoading();
              this.notif(result.errorDisplay);
              this.paymentReceived.next(false);
            }
          });
      }
      // }, 5000);
    }, 30000);
  }

  setPaymentMethod() {
    this.services.getSetting().subscribe(
      (resp: any) => {
        this.paymentMethodActive = resp.paymentMethod_active;
      },
      (error) => {
        this.notif(error);
      }
    );
  }

  getCapchaKey() {}
  setStep(value) {
    this.stepCheck.next(value);
  }
}
