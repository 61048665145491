import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { ConfirmInstallmentTaxComponent } from './confirm-installment-tax/confirm-installment-tax.component';
import { ConfirmTelemarketingTaxComponent } from './confirm-telemarketing-tax/confirm-telemarketing-tax.component';



@NgModule({
  declarations: [
    ConfirmInstallmentTaxComponent,
    ConfirmTelemarketingTaxComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule
  ],
  exports: [
    ConfirmInstallmentTaxComponent,
    ConfirmTelemarketingTaxComponent
  ]
})
export class TaxModule { }
