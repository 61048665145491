<div class="menucont">
  <div class="miclaro-logo vcenter" (click)="goHome()">
    <div class="tabcell">
      <img class="nopointer" width="100%" src="../assets/images/miclaro-logo.png">
    </div>
  </div>
  <div class="time hidden-xs">
    <div class="clockcont">
      <div class="clock pull-left">
        <!-- TODO -->
        <div class="sh-" id="second-hand"></div>
        <div class="mh-" id="minute-hand"></div>
        <div class="hh-" id="hour-hand"></div>
      </div>
    </div>
    <div class="timecont">
      <div class="t-line"></div>
      <span class="f-date" id="date"></span><br />
      <span class="f-time" id="clock"></span>
      <div class="f-time-i" id="time"></div>
    </div>
  </div>
  <div class="autocont full pull-right vcenter">
    <div class="tabcell">
      <div class="btn-group pull-right off-z-index" data-toggle="buttons">
        <label class="btn btn-danger active" data-langcode="ES">
          <input type="checkbox" autocomplete="off"> ES
        </label>
        <label class="btn btn-danger" data-langcode="EN">
          <input type="checkbox" autocomplete="off"> EN
        </label>
      </div>
    </div>
  </div>
</div>
