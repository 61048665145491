import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GlobalService} from '../../core/services/global-services/global.service';
import {ApiService} from '../../core/services/api-services/api.service';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {AlertasComponent} from '../../shared/components/alertas/alertas.component';
import {LoadingScreenService} from '../../core/services/loading-screen/loading-screen.service';

@Component({
  selector: 'app-generated-url',
  templateUrl: './generated-url.component.html',
  styleUrls: ['./generated-url.component.sass']
})
export class GeneratedUrlComponent implements OnInit {

  constructor(
    public global: GlobalService,
    private services: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private loadingScreenService: LoadingScreenService,
  ) {}

  async loadToken() {
    const data = await this.services.getToken().toPromise();
    if (data.hasError === false) {
      this.global.tokenMiClaro = data.object.token;
      sessionStorage.setItem('token', data.object.token);
    }
  }

  async ngOnInit() {
    setTimeout(async () => {
      this.loadingScreenService.startLoading(true, 'Estamos buscando su informacion ', true, null);
      await this.loadToken();
      // @ts-ignore
      const code = this.route.params._value.code;
      const data = await this.services.verifyCode(code).toPromise();
      this.loadingScreenService.stopLoading();

      if (data.hasError) {
        this.showDialogError();
      } else {
        sessionStorage.setItem('getSubscriber', btoa(JSON.stringify(data.object)));
        sessionStorage.setItem('urlId', data.object.urlId);
        this.global.session();

        if (data.object.transactionType === 'INSTALLMENT') {
          // tslint:disable-next-line:max-line-length
          sessionStorage.setItem('subscriber', btoa(data.object.accountInfo.subscriberNumber !== '' ? data.object.accountInfo.subscriberNumber : data.object.subscriberInfo[0].subscriberNumber));
          sessionStorage.setItem('subscriberInfo', btoa(JSON.stringify(data.object.subscriberInfo)));
          sessionStorage.setItem('paymentToken', data.object.token);

          this.router.navigate(['confirm_installment_tax']);
        } else if (data.object.transactionType === 'TELEMARKETING_PAY_TAXES') {
          if (data.object.telemarketingPayTax !== null && data.object.telemarketingPayTax !== undefined) {
            sessionStorage.setItem('telemarketing_pay_tax', btoa(JSON.stringify(data.object.telemarketingPayTax)));
          }
          if (data.object.subscriberInfo !== null && data.object.subscriberInfo !== undefined) {
            sessionStorage.setItem('subscriberInfo', btoa(JSON.stringify(data.object.subscriberInfo)));
          }

          this.router.navigate(['confirm_telemarketing_tax']);
        } else if (data.object.transactionType === 'POSTPAID_PAY_INVOICE') {
          data.object.accountInfo.pastDueAmount = data.object.amount;
          sessionStorage.setItem('getSubscriber', btoa(JSON.stringify(data.object)));
          sessionStorage.setItem('paymentsLink', btoa(JSON.stringify(data.object.paymentsLink)));
          this.router.navigate(['payment_amount']);
        } else if (data.object.transactionType === 'PREPAID_BUY_BALANCE') {
          data.object.accountInfo.pastDueAmount = data.object.amount;
          data.object.subscriberInfo = data.object.subscriberInfo.servSubscriber;
          sessionStorage.setItem('getSubscriber', btoa(JSON.stringify(data.object)));
          this.router.navigate(['prepaid_buy_balance']);
        } else {
          this.showDialogError();
        }
      }
    }, 100);
  }

  private showDialogError() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    // tslint:disable-next-line:max-line-length
    dialogConfig.data = {message: 'Enlace expirado, por favor llamar al 787-793-1253', colorSi : 'colorRed', colorNo: undefined, btmOk: true, btmConfirm: false, labelOk: 'Aceptar', labelNo: undefined};
    const dialogRef = this.dialog.open(AlertasComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(value => {
      this.router.navigate(['']);
    });
  }
}
