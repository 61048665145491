import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../../../core/services/global-services/global.service';
import { Subscription } from 'rxjs';
import { ApiService } from '../../../core/services/api-services/api.service';
import { LoadingScreenService } from '../../../core/services/loading-screen/loading-screen.service';
import { ExecutePaymentService } from '../../../core/services/execute-payment/execute-payment.service';
@Component({
  selector: 'app-confirm-package',
  templateUrl: './confirm-package.component.html',
  styleUrls: ['./confirm-package.component.sass']
})
export class ConfirmPackageComponent implements OnInit {
  public offerList = [];
  public roamingList = [];
  public namePackage;
  public subscriberEmail;
  public subscriberName;
  public subscriberLastName;
  public pricePackage;
  public banSelected;
  public subscriberActual;
  public valueAmount;
  public confirmNumber;
  public offerId;
  public token: string = sessionStorage.getItem('token');
  paymentReceived: Subscription;
  public screenActual = 1;

  constructor(private loadingScreenService: LoadingScreenService, private services: ApiService,
              public global: GlobalService, private router: Router, public executePaymentService: ExecutePaymentService) {
  }

  ngOnInit() {
    this.paymentReceived = this.global.paymentReceived.subscribe((value: boolean) => {
      if (value === true) {
        this.loadingScreenService.stopLoading();
        this.global.notif( 'Se ha agregado el Paquete Adicional de Data' );
        this.screenActual = 2;
      }
    });
    this.banSelected = JSON.parse(atob(sessionStorage.getItem('getSubscriber'))).accountInfo.BAN;
    this.subscriberEmail = JSON.parse(atob(sessionStorage.getItem('getSubscriber'))).accountInfo.email;
    this.subscriberName = JSON.parse(atob(sessionStorage.getItem('getSubscriber'))).accountInfo.firstName;
    this.subscriberLastName = JSON.parse(atob(sessionStorage.getItem('getSubscriber'))).accountInfo.lastName;
    this.subscriberActual = atob(sessionStorage.getItem('subscriber'));
    this.namePackage = sessionStorage.getItem('namePackage');
    this.pricePackage = sessionStorage.getItem('pricePackage');
    this.offerId = sessionStorage.getItem('offerId');
    this.valueAmount = this.pricePackage.split('$').join('');
    this.offerList = JSON.parse(atob(sessionStorage.getItem('getSubscriber'))).subscriberInfo[0].additionalpackages.localPackages;
    this.roamingList = JSON.parse(atob(sessionStorage.getItem('getSubscriber'))).subscriberInfo[0].additionalpackages.roamingPackages;
  }
  backTo() {
    this.router.navigate(['additional_packages']);
  }
  goTo() {
    const typeMessage = 'dataPackage';
    this.executePaymentService.paymentProcess(this.subscriberName, this.subscriberLastName, this.subscriberEmail,
                                              this.banSelected, typeMessage, 3, this.offerId, this.pricePackage, this.token);
  }
  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy() {
    this.paymentReceived.unsubscribe();
  }

  updateScreen(event: number) {
    this.screenActual = event;
  }
}
