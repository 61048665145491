import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '../../../core/services/global-services/global.service';
import { Subscription } from 'rxjs';
import { PaymentMethodActive } from '../../../core/interfaces/interfaces';
import { ApiService } from '../../../core/services/api-services/api.service';
@Component({
  selector: 'app-additional-packages',
  templateUrl: './additional-packages.component.html',
  styleUrls: ['./additional-packages.component.sass']
})
export class AdditionalPackagesComponent implements OnInit {

  public subscriberList;
  public offerList;
  public roamingList;
  public info = [];
  public selTab: number;
  public token: string = sessionStorage.getItem('token');
  loadObserver: Subscription;
  constructor(private global: GlobalService, private router: Router, private route: ActivatedRoute, private services: ApiService) { }

  ngOnInit() {
    this.selTab = 1;
    this.getId();
    this.info = JSON.parse(atob(sessionStorage.getItem('getSubscriber'))).subscriberInfo;
    this.subscriberList = this.info[0].subscriberNumber;
    this.global.setPaymentMethod();
    for (let i = 0; i < this.info.length; i++) {
      this.offerList = JSON.parse(atob(sessionStorage.getItem('getSubscriber'))).subscriberInfo[i].additionalpackages.localPackages;
      this.roamingList = JSON.parse(atob(sessionStorage.getItem('getSubscriber'))).subscriberInfo[i].additionalpackages.roamingPackages;
    }
    this.loadObserver = this.global.loadObserver.subscribe((value: number) => {
      this.dataLoading(value);
    });
  }
  backToOptions() {
    this.router.navigate(['postpaid_select_option']);
  }
  goToBuy(namePackage, pricePackage, offerId) {
    sessionStorage.setItem('namePackage', namePackage);
    sessionStorage.setItem('pricePackage', pricePackage);
    sessionStorage.setItem('offerId', offerId);
    this.router.navigate(['confirm_package']);
  }
  refreshDataTo() {
    // tslint:disable-next-line:radix
    const index = parseInt(event.target['selectedIndex']);
    const ban = this.info[index].subscriberNumber;
    sessionStorage.setItem('selectBan', btoa(ban)); // qué se pondrá acá
    this.initializeData();
  }
  initializeData() {
    this.global.refreshDataTo(this.token);
  }
  dataLoading(sw) {
    this.selTab = 1;
    this.getId();
    this.info = JSON.parse(atob(sessionStorage.getItem('getSubscriber'))).subscriberInfo;
    this.subscriberList = this.info[0].subscriberNumber;
    for (let i = 0; i < this.info.length; i++) {
      this.offerList = JSON.parse(atob(sessionStorage.getItem('getSubscriber'))).subscriberInfo[i].additionalpackages.localPackages;
      this.roamingList = JSON.parse(atob(sessionStorage.getItem('getSubscriber'))).subscriberInfo[i].additionalpackages.roamingPackages;
    }
  }
  statusChange(sw) {
    this.selTab = sw;
  }
  getId() {
    if (this.route.firstChild) {
      const id = this.route.firstChild.snapshot.params.id;
      if (id == '1') {
        this.selTab = 1;
      } else if (id == '2') {
        this.selTab = 2;
      } else {
        this.selTab = 1;
        this.router.navigate(['additional_packages']);
      }
    }
  }
}
