<div class="splash-bg-container">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="splash-header">
                        <div class="close" (click)="close()">x</div>
                    </div>
                    
                    <div class="card-body">
                        <img src="../../../../assets/images/splash.jpeg" class="img-fluid" alt="" (click)="goStore()">
                    </div>
                    
                </div>
               
            </div>
        </div>
    </div>
</div>