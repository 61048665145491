<div class="wrapper">
    <app-loading-screen></app-loading-screen>
    <!--Se pasa a componente despues-->
    <app-header></app-header>
    <!--Inicia componente del paso a paso-->
    <app-step [step]="2"></app-step>
    <!--Inicio contenido del modulo-->
    <div class="allcont  f-black f-reg roboto-r" *ngIf="this.global.currentScreen==1">
        <div class="container">
            <div class="billcont no-m">
                <div class="basicrow">
                    <div class="autocont pull-left">Suscriptor</div>
                    <div class="autocont pull-right roboto-b">{{this.subscriber}}</div>
                </div>
                <div class="basicrow bill-marg">
                    <div class="autocont pull-left">Balance en Prepago</div>
                    <div class="autocont pull-right roboto-b">{{this.balancedPrepaid |currency:'$'}}</div>
                </div>
                <div class="basicrow bill-marg" style="display:none;">
                    <div class="autocont pull-left">Mi Claro Wallet</div>
                    <div class="autocont pull-right roboto-b">{{this.wallet}}</div>
                </div>
            </div>
            <div class="basicrow" *ngIf="this.planPrice!=0">
                <div class="basicrow f-bigtitle roboto-l m-top-i">
                    Confirmar Recarga
                </div>
                <label for="radioG1" class="r-opts">
                    <div class="checkdef">
                        <input type="radio" name="radioG" id="radioG1" class="css-radio" checked="true"
                            (click)="this.otherValue=false;this.optionSelected(this.planPriceString);" />
                        <!-- <label for="radioG1" class="css-label3 radGroup1"></label> -->
                    </div>
                    Plan Actual <span class="f-red roboto-b">{{this.planPriceString}}</span> <!-- FOXME -->
                </label>
            </div>
            <div class="basicrow">
                <div class="basicrow f-bigtitle roboto-l m-top-i">
                    <div *ngIf="this.planPrice!=0">
                        Otros Disponibles
                    </div>
                    <div *ngIf="this.planPrice==0">
                        Confirmar Recarga
                    </div>
                </div>
                <div class="basicrow">
                    <div class="row">
                        <!-- should be rendered with each but have to validate case with other amount -->
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                            <label for="radioG2" class="r-opts">
                                <div class="checkdef">
                                    <input type="radio" name="radioG" id="radioG2" class="css-radio"
                                        (click)="this.otherValue=false;this.optionSelected('$5.00');" />
                                    <!-- <label for="radioG2" class="css-label3 radGroup1"></label> -->
                                </div>
                                <span class="f-red">$5.00</span>
                            </label>
                        </div>
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                            <label for="radioG3" class="r-opts">
                                <div class="checkdef">
                                    <input type="radio" name="radioG" id="radioG3" class="css-radio"
                                        (click)="this.otherValue=false;this.optionSelected('$10.00');" />
                                    <!-- <label for="radioG3" class="css-label3 radGroup1"></label> -->
                                </div>

                                <span class="f-red">$10.00</span>
                            </label>
                        </div>
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                            <label for="radioG4" class="r-opts">
                                <div class="checkdef">
                                    <input type="radio" name="radioG" id="radioG4" class="css-radio"
                                        (click)="this.otherValue=false;this.optionSelected('$20.00')" />
                                    <!-- <label for="radioG4" class="css-label3 radGroup1"></label> -->
                                </div>

                                <span class="f-red">$20.00</span>
                            </label>
                        </div>
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                            <label for="radioG5" class="r-opts" data="idProductXX %>">
                                <!-- TODO put in data idProduct -->
                                <div class="checkdef">
                                    <input type="radio" name="radioG" id="radioG5" class="css-radio"
                                        (click)="this.otherValue=false;this.optionSelected('$30.00');" />
                                    <!-- <label for="radioG5" class="css-label3 radGroup1"></label> -->
                                </div>
                                <span class="f-red">$30.00</span>
                            </label>
                        </div>
                        <!-- end each -->
                    </div>
                </div>
                <label for="radioG6" class="r-opts other">
                    <div class="checkdef">
                        <input type="radio" name="radioG" id="radioG6" class="css-radio"
                            (click)="this.otherValue=true;this.optionSelected(this.freeAmountValue);" />
                        <!-- <label for="radioG6" class="css-label3 radGroup1"></label> -->
                    </div>
                    Otro monto
                    <input [appOnlyAmount] class="inp-other" name="otherAmount" id="otherAmount" [(ngModel)]="this.freeAmountValue"
                        (blur)="this.otherValue=true;this.optionSelected(this.freeAmountValue);">
                </label>
            </div>
            <div class="basicrow text-center m-top" style="margin-bottom: 25px;">
                <div class="graybtn next pull-left hidden-xs" id="back_btn" (click)="this.goBack()"><i
                        class="fa fa-angle-left lefticon" aria-hidden="true"></i> Volver</div>
                <div class="redbtn next pull-right" id="continue_btn" (click)="this.goToConfirm()">Continuar <i
                        class="fa fa-angle-right righticon" aria-hidden="true"></i></div>
            </div>
        </div>
    </div>
    <div class="allcont " *ngIf="this.global.currentScreen==2  || this.global.currentScreen==4">
        <app-confirm-prepaid-balance
        (selectBanIPOut)="this.selectBanIP=$event"
        (currentSuscriberIPOut)="this.currentSuscriberIP=$event"
        (goScreen)="this.global.currentScreen=$event" [dataPrice]="this.dataPrice"
            [ivuState]="this.ivuState" [ivuAmount]="this.ivuAmount"></app-confirm-prepaid-balance>
    </div>
    <div *ngIf="this.global.currentScreen==3">
        <app-bill 
        [banSelected]="this.selectBanIP" 
        [subscriberSelected]="this.currentSuscriberIP"
        [amountPay]="this.totalToPay" 
        [confirmNumber]="this.global.authorizationNumber"
        [confirmNumber2]="this.global.referenceNumber">
      </app-bill>

    </div>
    <app-footer *ngIf="(this.global.currentScreen != 2 || this.global.loadFooter==true)"></app-footer>
</div>