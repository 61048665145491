import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/core/services/global-services/global.service';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.sass']
})
export class SplashComponent implements OnInit {

  constructor(public global: GlobalService) { }

  ngOnInit(): void {
  }

  close(){
    this.global.showSplash = false;
  }

  goStore(){
    const androidUrl = 'https://play.google.com/store/apps/details?id=com.todoclaro.miclaroapp&hl=en&pli=1';
    const iosUrl = 'https://apps.apple.com/us/app/mi-claro-pr/id775322054'
    const deviceInfo = navigator.userAgent
    var selectedUrl = ""
    
    if (deviceInfo.indexOf('Android') !== -1) {
      selectedUrl = androidUrl
    } else if (deviceInfo.indexOf('iPhone') !== -1) {
      selectedUrl = iosUrl
    } else {
      this.global.showSplash = false;
    }

    this.goThen(selectedUrl)

  }

  goThen(url: string) {
    if (url) {
      window.open(url, '_blank')
      this.global.showSplash = false;
    }
    
  }

}
