import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../core/services/api-services/api.service';
import { GlobalService } from '../../core/services/global-services/global.service';
import { LoadingScreenService } from '../../core/services/loading-screen/loading-screen.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-update-email',
  templateUrl: './update-email.component.html',
  styleUrls: ['./update-email.component.sass']
})
export class UpdateEmailComponent implements OnInit {
  public email;
  public email1;
  public email2;
  public submitted;
  public newEmail;
  public accountInfo: any;
  constructor(private router: Router,
              private services: ApiService,
              private global: GlobalService,
              private loadingScreenService: LoadingScreenService) { }

  ngOnInit() {
    this.email = JSON.parse(atob(sessionStorage.getItem('getSubscriber'))).accountInfo.email;
    this.submitted = true;
    this.email1 = '';
    this.email2 = '';
  }
  continueEmail() {
    this.accountInfo = JSON.parse(atob(sessionStorage.getItem('getSubscriber'))).accountInfo;
    if (this.email1 === '' && this.email2 === '') {
      this.newEmail = this.email;
      this.global.notif('Ingrese su Correo Electrónico');
    } else if (this.email1 === this.email2) {
      this.newEmail = this.email1;
      if (this.global.validarEmail(this.email1) == false) {
        this.global.notif('Estimado cliente, el nuevo Correo Electrónico tiene un formato incorrecto.');
      } else {
        this.loadingScreenService.startLoading();
        this.services.getVerifyEmail(this.newEmail).subscribe(data => {
          if (data.hasErrorField == false) {
            // tslint:disable-next-line:max-line-length
           this.services.updatePersonalData(sessionStorage.getItem('token'),
             btoa(this.accountInfo.BAN),
             btoa(this.accountInfo.subscriberNumber),
             btoa(this.accountInfo.subscriberNumber),
             // tslint:disable-next-line:no-shadowed-variable
             btoa(this.newEmail)).subscribe((data: any) => {
              this.loadingScreenService.stopLoading();
              if (data.HasError !== false) {
                // cableado provisionalmente data.HasError !== false
                this.global.notif(data.ErrorDesc);
                this.router.navigate(['postpaid_select_option']);
              } else {
                this.global.notif(data.ErrorDesc);
              }
            });
          } else {
            this.loadingScreenService.stopLoading();
            this.global.notif(data.errorDescField);
          }
        });
      }
    } else {
      this.global.notif('Los correos no coinciden.');
    }
  }
}
