import { Directive, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appOnlyNumber]'
})
export class OnlyNumberDirective {

  constructor() { }
  @Input('appOnlyNumber') date: string;
  @HostListener('keypress', ['$event'])

  public onkeypress(ev: any): void {
    const isNumeric = (ev.charCode >= 48 && ev.keyCode <= 57);
    if (!isNumeric) {
      ev.preventDefault();
    }
  }
}
