import { Component } from '@angular/core';
import 'hammerjs';
import { Router } from '@angular/router';
import { GlobalService } from './core/services/global-services/global.service';
import { ApiService } from './core/services/api-services/api.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {
  constructor(private router: Router, public global: GlobalService, private services: ApiService) {

  }
  // tslint:disable-next-line:use-lifecycle-interface
  ngOnInit() {
    
    
    this.services.getSetting().subscribe(
      (resp: any) => {
        this.global.showSplash = resp.showSplash;
        this.global.siteKey = resp.prodCaptchaKey;
      }
    );
   }

}
