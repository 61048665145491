<div class="steps">
  <div id="step-div" class="step-div step-1 on">
    <div class="def-step">
              <span class="stepcirc text-center vcenter">
                  <span class="tabcell">
                      1
                  </span>
              </span>
      <span class="hidden-xs">Ingrese datos</span>
    </div>
    <div class="stepbar"></div>
  </div>
  <div id="step-div" class="step-div step-2" [class]="this.second">
    <div class="def-step">
              <span class="stepcirc text-center vcenter">
                  <span class="tabcell">
                      2
                  </span>
              </span>
      <span class="hidden-xs">Monto a pagar</span>
    </div>
    <div class="stepbar"></div>
  </div>
  <div id="step-div" class="step-div step-3" [class]="this.third">
    <div class="def-step">
              <span class="stepcirc text-center vcenter">
                  <span class="tabcell">
                      3
                  </span>
              </span>
      <span class="hidden-xs">Pago</span>
    </div>
    <div class="stepbar"></div>
  </div>
  <div id="step-div" class="step-div step-4 " [class]="this.four">
    <div class="def-step">
        <span class="stepcirc text-center vcenter">
            <span class="tabcell">
                4
            </span>
        </span>
        <span class="hidden-xs">Confirmación</span>
    </div>
    <div class="stepbar"></div>
</div>
</div>
