import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/core/services/global-services/global.service';
import { ExecutePaymentService } from 'src/app/core/services/execute-payment/execute-payment.service';
import { LoadingScreenService } from 'src/app/core/services/loading-screen/loading-screen.service';

@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.sass']
})
export class PaymentModalComponent implements OnInit {
  
  @Output() screenActual = new EventEmitter<any>();
  
  constructor(public executePaymentService: ExecutePaymentService,
              private loadingScreenService: LoadingScreenService,
              public global: GlobalService, private router: Router) {
  }

  ngOnInit(): void {
   
  }

  executAuthorizePayment() {
    if (this.executePaymentService.validateCreditCardForm()) {
      this.loadingScreenService.startLoading(true, 'Por favor espere, estamos verificando su pago.', false, null);
      this.executePaymentService.requestBodyCreationforAuthorizePayment()
        .then(
          result => {
            this.loadingScreenService.stopLoading();
            this.executePaymentService.applyPaymentValidation = !this.executePaymentService.applyPaymentValidation;
            const isValid = this.executePaymentService.validateResult(result);
            if (isValid) {
              this.screenActual.emit(this.executePaymentService.paymentOk(result));
              this.executePaymentService.updateTransaction(result, true);
            } else {
              this.executePaymentService.errorDisplay(result);
            }

          },
          error => {
            this.loadingScreenService.stopLoading();
            this.global.notif(error);
            this.router.navigate(['login']);
          }
        );
    }
  }

  validateAddressForm() {
    // validate errors in address form:
    this.executePaymentService.initializeAddressErrors();
    if (!this.executePaymentService.address.address1) {
      this.executePaymentService.address.address1Error = true;
      return;
    }
    if (!this.executePaymentService.address.city) {
      this.executePaymentService.address.cityError = true;
      return;
    }
    if (!this.executePaymentService.address.state) {
      this.executePaymentService.address.stateError = true;
      return;
    }
    if (!this.executePaymentService.address.zipCode) {
      this.executePaymentService.address.zipCodeError = true;
      return;
    }
    if (this.executePaymentService.address.zipCode.length !== 5) {
      this.executePaymentService.address.zipCodeErrorFormat = true;
      return;
    }

    this.validateZip(this.executePaymentService.address.zipCode);

  }

  continueWithPaymentMethod() {
    const data = this.executePaymentService.serviceAddressData;
    this.executePaymentService.isFirstStep = false;
    this.executePaymentService.addressFieldsNeedToBeFilled = false;
    this.executePaymentService.isValidAddress = true;
    this.executePaymentService.applyPaymentValidation = true;
    this.executePaymentService.fillingApplyPaymentRequest(data.paymentOptionId, data.productID, data.offerId, data.valueAmount);
  }

  cancel() {
    this.executePaymentService.initData();
    this.executePaymentService.addressFieldsNeedToBeFilled = false;
  }

  onChangeState(state: string) {
    this.executePaymentService.address.state = state;
  }

  validateZip(zipCode: string) {
    if (this.executePaymentService.isNumericValidCharacter(zipCode)) {
      this.executePaymentService.address.zipCodeErrorFormat = true;
      this.executePaymentService.address.zipCode = '';
      return;
    } else {
      this.continueWithPaymentMethod();
    }
  }

}
