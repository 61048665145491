import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingScreenService {
  // tslint:disable-next-line:variable-name
  private _loading = false;
  // tslint:disable-next-line:variable-name
  private _loadingMin = false;
  // tslint:disable-next-line:variable-name
  private _loadingMin2 = false;
  private session: string;

  loadingStatus = new Subject();
  loadingMinStatus = new Subject();
  loadingMin2Status = new Subject();
  statusText = new Subject();

  get loadingMin(): boolean {
    return this._loadingMin;
  }
  set loadingMin(value) {
    this._loadingMin = value;
    this.loadingMinStatus.next(value);
  }
  startLoadingMin() {
    this.loadingMin = true;
  }
  stopLoadingMin() {
    this.loadingMin = false;
  }
  getSession(value) {
   return this.session;
  }
  setSession(value) {
    this.session = value;
  }
  get loadingMin2(): boolean {
    return this._loadingMin2;
  }
  set loadingMin2(value) {
    this._loadingMin2 = value;
    this.loadingMin2Status.next(value);
  }
  startLoadingMin2() {
    this.loadingMin2 = true;
  }
  stopLoadingMin2() {
    this.loadingMin2 = false;
  }
  get loading(): boolean {
    return this._loading;
  }
  set loading(value) {
    this._loading = value;
    this.loadingStatus.next(value);
  }
  startLoading(statusText= false, text= '', btm= false, session= '') {
    this.statusText.next([statusText, text, btm, session]);
    this.loading = true;
  }
  stopLoading() {
    this.loading = false;
  }
}
