import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/core/services/global-services/global.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {

  constructor(private router: Router,public global: GlobalService) { }

  ngOnInit(): void {
  }

  goHome() {
    this.global.setStep(2);
    this.global.isStep1 = true;
    this.global.isPrepaid = false;
    this.global.onPrepaidStep2 = false;
    this.global.currentScreen=1;
    this.router.navigate(['login']);
  }

}
