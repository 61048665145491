import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { LoadingScreenService } from '../../../core/services/loading-screen/loading-screen.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { GlobalService } from '../../../core/services/global-services/global.service';
@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.sass']
})
export class LoadingScreenComponent implements OnInit, OnDestroy {

  @Input() status: boolean;
  public loading = false;
  public statusTextVisible = false;
  public text = '';
  public session = '';
  public btm = false;
  loadingSubscription: Subscription;
  statusText: Subscription;
  constructor(private loadingScreenService: LoadingScreenService, private router: Router) {}

  ngOnInit() {
    if (this.status != undefined) {
      this.loading = this.status;
    }
    this.statusText = this.loadingScreenService.statusText.subscribe((value: any) => {
      this.statusTextVisible = value[0];
      this.text = value[1];
      this.btm = value[2];
      this.session = value[3];
    });
    this.loadingSubscription = this.loadingScreenService.loadingStatus.subscribe((value: boolean) => {
      this.loading = value;
    });
  }
  close() {
    this.loadingScreenService.stopLoading();
    if (this.session == 'referred') {
      this.router.navigate(['home']);
    }
  }
  ngOnDestroy() {
    this.statusText.unsubscribe();
    this.loadingSubscription.unsubscribe();
  }
}

