import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../../../core/services/global-services/global.service';
@Component({
  selector: 'app-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.sass']
})
export class PaymentInfoComponent implements OnInit {
  public token: string;
  public balance;
  constructor(public global: GlobalService, private router: Router) { }

  ngOnInit() {
    this.token = sessionStorage.getItem('token');
    this.balance = this.global.amountToPay2;
  }
  goTo() {
    
  }
  backTo() {
    this.router.navigate(['payment_amount']);
  }
}
