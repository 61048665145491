import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdditionalPackagesComponent } from './additional-packages/additional-packages.component';
import { ConfirmPackageComponent } from './confirm-package/confirm-package.component';
import { PaymentAmountComponent } from './payment-amount/payment-amount.component';
import { PaymentInfoComponent } from './payment-info/payment-info.component';
import { PostpaidSelectOptionComponent } from './postpaid-select-option/postpaid-select-option.component';
import { BillBuyPackageComponent } from './confirm-package/components/bill-buy-package/bill-buy-package.component';
import { BillComponent } from './payment-amount/components/bill/bill.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    AdditionalPackagesComponent,
    ConfirmPackageComponent,
    BillBuyPackageComponent,
    PaymentAmountComponent,
    BillComponent,
    PaymentInfoComponent,
    PostpaidSelectOptionComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports: [
    AdditionalPackagesComponent,
    ConfirmPackageComponent,
    BillBuyPackageComponent,
    PaymentAmountComponent,
    BillComponent,
    PaymentInfoComponent,
    PostpaidSelectOptionComponent
  ]
})
export class PostpaidModule { }
