<div id="wrapper">
  <app-loading-screen></app-loading-screen>
  <!--Se pasa a componente despues-->
  <app-header></app-header>
  <!--Inicia componente del paso a paso-->
  <div class="steps" *ngIf="this.screenActual==1">
    <div id="step-div" class="step-div step-1 on">
      <div class="def-step">
                <span class="stepcirc text-center vcenter">
                    <span class="tabcell">
                        1
                    </span>
                </span>
        <span class="hidden-xs">Ingrese datos</span>
      </div>
      <div class="stepbar"></div>
    </div>
    <div id="step-div" class="step-div step-2 on">
      <div class="def-step">
                <span class="stepcirc text-center vcenter">
                    <span class="tabcell">
                        2
                    </span>
                </span>
        <span class="hidden-xs">Monto a pagar</span>
      </div>
      <div class="stepbar"></div>
    </div>
    <div id="step-div"
         [ngClass]="{'step-div step-3 on': this.activateLine==true, 'step-div step-3': this.activateLine==false}">
      <div class="def-step">
                <span class="stepcirc text-center vcenter">
                    <span class="tabcell">
                        3
                    </span>
                </span>
        <span class="hidden-xs">Pago</span>
      </div>
      <div class="stepbar"></div>
    </div>
    <div id="step-div" class="step-div step-4">
      <div class="def-step">
                <span class="stepcirc text-center vcenter">
                    <span class="tabcell">
                        4
                    </span>
                </span>
        <span class="hidden-xs">Confirmación</span>
      </div>
      <div class="stepbar"></div>
    </div>
  </div>
  <!--Inicia contenido del modulo-->
  <div class="allcont f-black f-reg roboto-r" *ngIf="this.screenActual==1">
    <div class="container">
      <div class="basicrow">
        <div class="autocont f-bigtitle roboto-l" style="text-align: center;">Monto a Pagar</div>
        <div class="autocont f-bigtitle roboto-l" style="text-align: center; color: #ef3829; font-weight: bold;">
          ${{this.amount}}</div>
      </div>

      <div class="billcont">
        <div class="basicrow">
          <div class="autocont pull-left" style="font-size: 24px; font-weight: bold;">Resumen de Deuda</div>
          <br><br>
        </div>
        <div class="basicrow bill-marg">
          <div class="autocont pull-left">Nombre</div>
          <div
            class="autocont pull-right roboto-b">{{this.subscriberName + '&nbsp;&nbsp;' + this.subscriberLastName}}</div>
        </div>
        <div class="basicrow bill-marg">
          <div class="autocont pull-left">N&uacute;mero de Cuenta</div>
          <div class="autocont pull-right roboto-b">{{this.accountNumber}}</div>
          <div class="basicrow bill-marg">

            <div class="autocont pull-left">N&uacute;mero de Suscriptor</div>
            <div class="autocont pull-right roboto-b">{{this.ban}}</div>
          </div>
          <div class="basicrow bill-marg">
            <div class="autocont pull-left">Monto Cuota</div>
            <div class="autocont pull-right roboto-b">${{this.paymentQuota}}</div>
          </div>
          <div class="basicrow bill-marg">
            <div class="autocont pull-left">Cuotas a Acelerar</div>
            <div class="autocont pull-right roboto-b">{{this.instllmentUnits}}</div>
          </div>
          <div class="basicrow bill-marg">
            <div class="autocont pull-left">Total Deuda</div>
            <div class="autocont pull-right roboto-b">{{this.amount}}</div>
          </div>
          <div class="basicrow bill-marg">
            <div class="autocont pull-left">Pago Dividido</div>
            <div class="autocont pull-right roboto-b">
              <mat-slide-toggle [disabled]="paySplit1Completed" [(ngModel)]="this.splitPayment"
                                class="mat-warn"></mat-slide-toggle>
            </div>
          </div>
        </div>

        <div *ngIf="this.splitPayment">
          <div class="billcont">
            <div class="row">
              <div class="col-4"><span style=" font-size: 40px; margin-left: 1rem;">1</span></div>
              <div class="col-8">
                <div class="basicrow">
                  <div class="autocont pull-left">
                    <span>Monto a Pagar</span>
                  </div>
                  <div class="autocont pull-right">
                    <input class="inpdef newpayment" type="number"
                           style="    width: 90px; height: 25px; padding: 8px 0px; font-size: 16px; text-align: right;"
                           [disabled]="paySplit1 || paySplit1Completed"
                           [(ngModel)]="this.valuePaymentSplit1" (ngModelChange)="calculateValuePaymentSplit2()"
                           (blur)="verifyIncorrectValueSplit1()"
                           id="payment-amount">
                  </div>
                </div>

                <br><br>

                <div *ngIf="paySplit1Completed">
                  <div class="basicrow">
                    <div class="autocont pull-left">Numero de Autorizacion: {{numAuthorizationPaySplit1}}</div>
                  </div>
                  <div class="basicrow bill-marg">
                    <div class="autocont pull-left">Metodo de Pago: <img src="{{paymentMethodDisplayPaySplit1}}"
                                                                         width="75px" alt=""></div>
                  </div>
                  <div class="basicrow bill-marg">
                    <div class="autocont pull-left">Fecha de Pago: {{dateProvisioningPaySplit1}}</div>
                  </div>
                </div>

                <div class="basicrow text-center m-top" *ngIf="!paySplit1Completed">
                  <div class="errortest redbtn next pull-left" id="continue_btn"
                       *ngIf="enableRefresButton && !paySplit1Completed" (click)="this.refresPayment(0)">Refrescar <i
                    class="fa fa-angle-right righticon" aria-hidden="true"></i></div>

                  <div class="errortest next pull-right"
                       [className]="!paySplit1 && validateAmount() ? 'redbtn' : 'graybtn'" id="continue_btn"
                       (click)="this.goPaySplit1()">{{paySplit1 ? 'Espere...' : 'Continuar'}} <i
                    class="fa fa-angle-right righticon" aria-hidden="true"></i></div>
                </div>
              </div>
            </div>
          </div>
          <div class="billcont">
            <div class="row">
              <div class="col-4"><span style=" font-size: 40px; margin-left: 1rem;">2</span></div>
              <div class="col-8">
                <div class="basicrow">
                  <div class="autocont pull-left">
                    <span>Monto a Pagar</span>
                  </div>
                  <div class="autocont pull-right">
                    <input class="inpdef newpayment" type="number" disabled
                           style="    width: 90px; height: 25px; padding: 8px 0px; font-size: 16px; text-align: right;"
                           [(ngModel)]="this.valuePaymentSplit2" id="payment-amount">
                  </div>
                </div>

                <br><br>

                <div *ngIf="paySplit2Completed">
                  <div class="basicrow">
                    <div class="autocont pull-left">Numero de Autorizacion: {{numAuthorizationPaySplit2}}</div>
                  </div>
                  <div class="basicrow bill-marg">
                    <div class="autocont pull-left">Metodo de Pago: <img src="{{paymentMethodDisplayPaySplit2}}"
                                                                         width="75px" alt=""></div>
                  </div>
                  <div class="basicrow bill-marg">
                    <div class="autocont pull-left">Fecha de Pago: {{dateProvisioningPaySplit2}}</div>
                  </div>
                </div>

                <div class="basicrow text-center m-top" *ngIf="paySplit1Completed && !paySplit2Completed">
                  <div class="errortest redbtn next pull-left" id="continue_btn"
                       *ngIf="enableRefresButton && !paySplit2Completed" (click)="this.refresPayment(0)">Refrescar <i
                    class="fa fa-angle-right righticon" aria-hidden="true"></i></div>

                  <div class="errortest redbtn next pull-right" id="continue_btn"
                       (click)="this.goPaySplit2()">{{paySplit2 ? 'Espere...' : 'Continuar'}} <i
                    class="fa fa-angle-right righticon" aria-hidden="true"></i></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="basicrow text-center m-top">
          <div class="errortest redbtn next pull-left" id="continue_btn" *ngIf="enableRefresButton && !splitPayment"
               (click)="this.refresPayment(0)">Refrescar <i
            class="fa fa-angle-right righticon" aria-hidden="true"></i></div>
          <div class="errortest redbtn next pull-right" id="continue_btn" *ngIf="!splitPayment" (click)="this.goTo()">
            Continuar <i
            class="fa fa-angle-right righticon" aria-hidden="true"></i></div>
        </div>

        <div class="basicrow text-center m-top" *ngIf="splitPayment && paySplit2Completed">
          <div class="errortest redbtn next pull-right" id="continue_btn" (click)="this.goToSplitPaymentFinished()">
            Continuar <i
            class="fa fa-angle-right righticon" aria-hidden="true"></i></div>
        </div>

      </div>
    </div>
  </div>
  <div *ngIf="this.screenActual==2">
    <app-bill *ngIf="!splitPayment" [banSelected]="this.ban" [amountPay]="this.amount"
              [confirmNumber]="this.numAuthorization" [refundInfo] = this.verifyPaymentStatus></app-bill>
    <app-bill *ngIf="splitPayment" [banSelected]="this.ban" [amountPay]="this.amount"
              [confirmNumber]="this.numAuthorizationPaySplit1"
              [confirmNumber2]="this.numAuthorizationPaySplit2" [refundInfo] = this.verifyPaymentStatus></app-bill>
  </div>
  <!--Inicia el footer-->
  <app-footer></app-footer>
</div>
