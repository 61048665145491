<div class="allcont">
  <div class="nopad f-black f-reg roboto-r">
    <div class="container new-w">
      <div class="basicrow text-center">
        <div class="r-title roboto-r vcenter">
          <div class="tabcell bottom f-green">
            <div style="text-align: center;">
              <img width="48px" src="../assets/images/icon_check-resumen.png">
            </div>
            <div style="text-align: center;">
              <span class="roboto-b ">&#161;Gracias&#33;</span> Su pago fue exitoso
            </div>
          </div>
        </div>
      </div>
      <div class="basicrow text-center">
        <div class="f-green" style="text-align: center;">
          Recibiras un correo electrónico con tu confirmacion de tu pago.
        </div>
        <div class="message-provision" *ngIf="this.global.hasProvision==false">
          <div class="color-txt-red neg">
            Tu pago fue aprobado por el banco y será aplicado a tu cuenta en un plazo de 24 a 48 horas.
          </div>
          <div class="color-txt-red neg">
            Por favor, no realices un nuevo pago hasta que la transacción sea procesada.
          </div>
        </div>
      </div>
      <div class="basicrow">
        <div class="conf-cont roboto-r ajuste">
          <i class="fa fa-check-circle conf-icon" aria-hidden="true"></i>
          Número de Autorización : <span class="roboto-b">{{this.confirmNumber}}</span><br />
          <span *ngIf="confirmNumber2 !== null">Número de Referencia: <span
              class="roboto-b">{{this.confirmNumber2}}</span></span><br />
          Su Número de Cuenta es : <span class="roboto-b">{{this.banSelected}}</span><br />
          Su Número de Suscriptor : <span class="roboto-b">{{this.subscriberSelected}}</span>
          <br />
        </div>
        <div class="conf-cont-i text-center vcenter roboto-r">
          <div class="tabcell">
            <span class="f-red roboto-b">
              Monto Pagado:<br />
              <span class="conf-n">{{this.amountPay | currency}}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="basicrow">
    <div class="container new-w">
      <div class="linesprt"></div>
    </div>
  </div>

  <div *ngIf="isRefoundData()" class="nopad f-black f-reg roboto-r">
    <div class="container">
      <div class="basicrow">
        <div class="basicrow roboto-m f-title f-black">
          <span style="    font-size: 1.15rem !important;">Aunque su pago fue existoso, no fue aplicado a su cuenta de
            Claro. Se ha reversado el monto pagado a su cuenta de banco.</span>
          <br>
          <a (click)="displayRefundInfo()" style="cursor: pointer; color: red; font-size: 0.9rem;">Pulse aqui para ver
            la Informaci&oacute;n de Reembolso</a>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="refundInfo" class="basicrow">
    <div class="container new-w">
      <div class="linesprt"></div>
    </div>
  </div>

  <div class=" nopad f-black f-reg roboto-r">
    <div class="container">
      <div class="basicrow">
        <div class="basicrow roboto-m f-title f-black">
          ¿Desea Recibo?
        </div>

        <div class="basicrow m-top-ii">
          <div class="r-half right">
            <div class="basicrow">
              Teléfono:
            </div>

            <input class="inpdef" type="text" placeholder="Ej: 8090084046" name="sms_input" [(ngModel)]="this.insNumTel"
              maxlength="10">

            <div class="redbtn" id="sendSms" (click)="enviarNumtel()">Enviar <i class="fa fa-angle-right righticon"
                aria-hidden="true"></i></div>
          </div>

          <div class="r-half left">
            <div class="basicrow">
              Correo electrónico:
            </div>

            <input class="inpdef" type="text" placeholder="user@email.com" name="email_input"
              [(ngModel)]="this.emailCorr">

            <div class="redbtn" id="sendEmail" (click)="verificarEmail()">Enviar <i class="fa fa-angle-right righticon"
                aria-hidden="true"></i></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="f-black f-reg roboto-r">
    <div class="basicrow text-center" style="padding-top: 33px;">
      <a href="https://www.claropr.com/" target="_blank" rel="noopener noreferrer">
        <img class="appban" width="100%" src="../assets/images/appbanner.png">
        <img class="appban-i" width="100%" src="../assets/images/appbannerv2.png">
      </a>
    </div>
    <div class="basicrow m-top text-center" style="margin-bottom: 25px;">
      <div class="container">
        <div class="graybtn home hidden-xs" id="close" (click)="this.goHome();">Inicio <i
            class="fa fa-angle-right righticon" aria-hidden="true"></i></div>
        <a href="https://miclaro.claropr.com/register" class="redbtn r-choice" target="_blank"
          rel="noopener noreferrer">
          Registro <i class="fa fa-angle-right righticon" aria-hidden="true"></i>
        </a>
      </div>
    </div>
  </div>
</div>