import { Component, OnInit , ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from '../../app.component';
import { ApiService } from '../../core/services/api-services/api.service';
import { LoadingScreenService } from '../../core/services/loading-screen/loading-screen.service';
import { GlobalService } from '../../core/services/global-services/global.service';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA  } from '@angular/material';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {
  public tabActive = 'Dashboard';
  public selectBan;
  public currentList;
  public postpago = false;
  public prepago = false;
  public telefoniaFijo = false;
  public totalAccountList;
  public viewPaper = 1;

  public billDueDateField;
  public billDateField;
  public lastPaymentAmountField;
  public billBalanceField;
  public paperless;
  public accountType;
  public accountSubtype;
  public type;
  public popPreguntasSeguridad;
  public token: string = sessionStorage.getItem('token');
  public subscribers;
  public subscriber;
  loadObserver: Subscription;

  public giftMessage = '';
  public nameSend;
  public gui;
  public cr = '';
  constructor(private router: Router,
              public appComponent: AppComponent,
              private services: ApiService,
              private loadingScreenService: LoadingScreenService,
              private global: GlobalService,
              public dialog: MatDialog) {

  }

  ngOnInit() {
   // this.global.validateSession();
    this.loadObserver = this.global.loadObserver.subscribe((value: number) => {
      this.dataLoading(value);
    });
    this.selectBan = atob(sessionStorage.getItem('selectBan'));

    }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    if (this.prepago == true) {
      /*this.services.updateToken(this.token,btoa(this.selectBan),this.subscriber).subscribe(data =>{
        if(data.hasError==false){
          sessionStorage.setItem("idCustomerCard",btoa(JSON.stringify(data.response)));
         this.GetGift1GBSendData();
        }
        else{
         this.GetGift1GBSendData();
          //mensaje que no se genero el token
        }
      });*/
    } else {
      /*this.GetGift1GBSendData();*/
    }
    /*if(this.popPreguntasSeguridad==true){
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      const dialogRef =this.dialog.open(PopupQuestionComponent, dialogConfig);
    }*/
  }
  initializeData() {
    this.global.refreshDataTo(this.token);
  }
  refreshDataTo(event: Event) {
    // tslint:disable-next-line:radix
    const index = parseInt(event.target['selectedIndex']);
    const ban = this.currentList[index].account;
    sessionStorage.setItem('selectBan', btoa(ban));
    this.initializeData();
  }
  dataLoading(sw) {
    this.selectBan = atob(sessionStorage.getItem('selectBan'));

    if (this.billBalanceField.search('CR') > -1) {
      this.cr = 'CR';
      this.billBalanceField= parseFloat(this.billBalanceField);
    } else {
      this.cr = '';
    }
    if (this.postpago == true) {
      this.type = 'postpaid';
    } else if (this.prepago == true) {
      this.type = 'prepaid';
    } else if (this.telefoniaFijo == true) {
      this.type = 'fixed';
    }
    /*this.GetGift1GBSendData();*/
  }
  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy() {
    this.loadObserver.unsubscribe();
  }
}

