<div class="allcont">
    <div class="nopad f-black f-reg roboto-r">
        <div class="container new-w">
            <div class="basicrow text-center footer-fixed-2">
                <div class="container-declined">
                    <div>
                        <div>
                            <img width="48px" src="../../../../assets/images/icon_validacionincorrecta.png">
                        </div>
                        <div class="txt-declined"  [innerHTML]="this.textMessageError | safeHtml">
                        </div>
                    </div>
                    <div>
                        <a (click)="this.retry();" class="redbtn r-choice" >
                            Reintentar <i class="fa fa-angle-right righticon" aria-hidden="true"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>