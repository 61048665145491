<div id="wrapper">
  <app-loading-screen></app-loading-screen>
  <!--Se pasa a componente despues-->
  <app-header></app-header>

  <div class="steps">
    <div id="step-div" class="step-div step-1 on">
      <div class="def-step">
                <span class="stepcirc text-center vcenter">
                    <span class="tabcell">
                        1
                    </span>
                </span>
        <span class="hidden-xs">Ingrese datos</span>
      </div>
      <div class="stepbar"></div>
    </div>
    <div id="step-div" class="step-div step-2 on">
      <div class="def-step">
                <span class="stepcirc text-center vcenter">
                    <span class="tabcell">
                        2
                    </span>
                </span>
        <span class="hidden-xs">Monto a pagar</span>
      </div>
      <div class="stepbar"></div>
    </div>
    <div id="step-div" class="step-div step-3">
      <div class="def-step">
                <span class="stepcirc text-center vcenter">
                    <span class="tabcell">
                        3
                    </span>
                </span>
        <span class="hidden-xs">Pago</span>
      </div>
      <div class="stepbar"></div>
    </div>
    <div id="step-div" class="step-div step-4">
      <div class="def-step">
                <span class="stepcirc text-center vcenter">
                    <span class="tabcell">
                        4
                    </span>
                </span>
        <span class="hidden-xs">Confirmación</span>
      </div>
      <div class="stepbar"></div>
    </div>
  </div>

  <div class="allcont f-black f-reg roboto-r">
    <div class="container">
      <div class="basicrow">
        <div class="autocont f-bigtitle roboto-l" style="text-align: center;">Monto a Pagar</div>
        <div class="autocont f-bigtitle roboto-l" style="text-align: center; color: #ef3829; font-weight: bold;">$0.00</div>
      </div>

      <div class="billcont">
        <div class="basicrow">
          <div class="autocont pull-left" style="font-size: 24px; font-weight: bold;">Resumen de Deuda</div><br><br>
        </div>
        <div class="basicrow">
          <div class="autocont pull-left">Monto Cuota</div>
          <div class="autocont pull-right roboto-b"></div>
        </div>
        <div class="basicrow bill-marg">
          <div class="autocont pull-left">Cuotas a Acelerar</div>
          <div class="autocont pull-right roboto-b"></div>
        </div>
        <div class="basicrow bill-marg">
          <div class="autocont pull-left">Total Deuda</div>
          <div class="autocont pull-right roboto-b"></div>
        </div>
        <div class="basicrow bill-marg">
          <div class="autocont pull-left">Pago Dividido</div>
          <div class="autocont pull-right roboto-b"><mat-slide-toggle [disabled]="true" class="mat-warn"></mat-slide-toggle></div>
        </div>
      </div>

    </div>
  </div>

  <!--Inicia el footer-->
  <app-footer></app-footer>
</div>
