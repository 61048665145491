import { Directive, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appZipCode]'
})
export class ZipCodeDirective {

  constructor() { }
  @Input('appZipCode') date: string;
  @HostListener('keypress', ['$event'])

  public onkeypress(ev: any): void {
    const isNumeric = (ev.charCode >= 48 && ev.keyCode <= 57) || ev.keyCode === 45;
    if (!isNumeric) {
      ev.preventDefault();
    }
  }
}
