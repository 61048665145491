import { EventEmitter, Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { LoadingScreenService } from '../loading-screen/loading-screen.service';
import { GlobalService } from 'src/app/core/services/global-services/global.service';
import { Router } from '@angular/router';
// tslint:disable-next-line:max-line-length
import {
  AddressData,
  ApplyPaymentRequest,
  ApplyPaymentResponse,
  NewPaymentResponse
} from '../../interfaces/interfaces';
import { ApiService } from '../api-services/api.service';
import { EncryptWithKeyService } from '../encrypt-with-key/encrypt-with-key.service';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ExecutePaymentService {

  public applyPaymentValidation = false;
  public paymentMethod: string;
  public cardName: string;
  public cardNumber = '';
  public cardNumberString = '';
  public ccv = '';
  public isTheCardNumberEmpty = false;
  public isTheCcvEmpty = false;
  public isTheCcvError = false;
  public expirationYear: string;
  public years: any [];
  public expirationMonth: string;
  public months: string [] = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  public today = new Date();
  public jstoday = '';
  public cardType: string;
  public isValidCard = true;
  public cardIsExpired = false;
  public applyPaymentResponse: ApplyPaymentResponse;
  public subscriberEmail;
  public subscriberName;
  public subscriberLastName;
  public banSelected;
  public isValidAddress = false;
  public addressFieldsNeedToBeFilled = false;
  public isFirstStep = false;
  public paymentOption;
  public serviceCompleteData: any;
  public address: AddressData = {
    address1Error: false,
    address2Error: false,
    cityError: false,
    stateError: false,
    zipCodeError: false,
    zipCodeErrorFormat: false,
    address1: '',
    address2: '',
    city: '',
    zipCode: '',
    state: 'PR'
  };
  public states = [
    'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT',
    'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN',
    'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI',
    'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
    'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA',
    'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT',
    'VI', 'VA', 'WA', 'WV', 'WI', 'WY',
  ];
  public serviceAddressData: any;
  message = 'Se ha agregado el Paquete Adicional de Data';
  isValidZip = true;
  isEmptyZipCode = false;
  userData;
  subscriberInfo;
  screenActual = new EventEmitter<any>();
  public isOpenPaymentModal = new Subject();
  isPrepaid: boolean;

  constructor(private loadingScreenService: LoadingScreenService, private services: ApiService,
              public global: GlobalService, private router: Router, private encrypt: EncryptWithKeyService) {
    this.initData();
  }

  initData() {
    this.jstoday = formatDate(this.today, 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0530');
    this.expirationYear = this.jstoday.substring(6, 10);
    this.expirationMonth = this.jstoday.substring(3, 5);
    this.cardNumber = '';
    this.cardNumberString = '';
    this.ccv = '';
    this.global.subscriberReceiver = '';
    this.global.ssnReceiver = '';
    this.isOpenPaymentModal.next(false);
    this.global.setStep(2);
  }

  openPaymentModal() {
    this.isOpenPaymentModal.next(true);
  }


  optionSelected(option) {
    this.paymentOption = option;
  }

  doPayment() {
    this.global.setStep(3);
    this.userData = JSON.parse(atob(sessionStorage.getItem('getSubscriber')));
    // tslint:disable-next-line:max-line-length
    this.subscriberInfo = this.userData.subscriberInfo.servSubscriber ? this.userData.subscriberInfo.servSubscriber[0] : this.userData.subscriberInfo[0];
    if (this.paymentOption === null || this.paymentOption === undefined) {
      this.global.notif('Por favor seleccione una opción de pago');
    } else if (this.paymentOption === 0) {
      this.h2hMethod(this.serviceCompleteData.productID, this.serviceCompleteData.offerId, this.serviceCompleteData.valueAmount);
    } else {
      // this.isFirstStep = false;
      // tslint:disable-next-line:max-line-length
      // let payload ={
      //   typeMessage: this.serviceCompleteData.typeMessage,
      //   productID: this.serviceCompleteData.productID,
      //   valueAmount: this.serviceCompleteData.valueAmount,
      //   offerId: this.serviceCompleteData.offerId,
      //   token: this.serviceCompleteData.token,
      // }
      this.webCheckoutMethod(this.serviceCompleteData.typeMessage, this.serviceCompleteData.productID, this.serviceCompleteData.valueAmount, this.serviceCompleteData.offerId, this.serviceCompleteData.token);
    }
  }

  paymentProcess(subscriberName, subscriberLastName, subscriberEmail,
                 banSelected, typeMessage, productID, offerId, valueAmount, token) {
    typeMessage === 'prepaid' ? this.isPrepaid = true : this.isPrepaid = false;
    this.subscriberEmail = subscriberEmail;
    this.subscriberName = subscriberName;
    this.subscriberLastName = subscriberLastName;
    this.banSelected = banSelected;
    const serviceCompleteData = {
      subscriberName,
      subscriberLastName,
      subscriberEmail,
      banSelected,
      typeMessage,
      productID,
      offerId,
      valueAmount,
      token
    };
    this.serviceCompleteData = serviceCompleteData;
    this.isFirstStep = true;
  }

  webCheckoutMethod(typeMessage, productID, valueAmount, offerId, token) {
    if (productID === 1) {
      this.global.newPayment(1, typeMessage, this.banSelected, atob(sessionStorage.getItem('subscriber')),
        valueAmount, this.subscriberEmail, this.subscriberName + ' ' + this.subscriberLastName, '',
        this.global.tokenMiClaro, "", null, this.paymentOption === 1 ? 'ATH' : null);
    } else {
      // tslint:disable-next-line:max-line-length
      this.global.newPaymentNoLimit(
        productID, typeMessage, this.banSelected,
        atob(sessionStorage.getItem('subscriber')),
        valueAmount, this.subscriberEmail,
        sessionStorage.getItem('name'), offerId, token);
    }
  }

  h2hMethod(productID, offerId, valueAmount) {
    this.loadingScreenService.startLoading();
    this.services.newPayment(productID, offerId).subscribe(
      (resp: NewPaymentResponse) => {
        this.loadingScreenService.stopLoading();
        // tslint:disable-next-line:max-line-length
        const index = resp.paymentOptions.map(x => x.paymentOptionType.toLocaleLowerCase()).indexOf(this.global.paymentMethodActive.toLocaleLowerCase());
        if (index === -1) {
          const result = {errorDisplay: 'Error: el metodo de pago configurado no coincide con la respuesta del servicio'};
          this.errorDisplay(result);
        } else {
          if (this.userData.accountInfo.address &&
            this.userData.accountInfo.city &&
            this.userData.accountInfo.zip) {
            this.isValidAddress = true;
            this.address.address1 = this.userData.accountInfo.address;
            this.address.city = this.userData.accountInfo.city;
            this.address.zipCode = this.userData.accountInfo.zip;
            this.fillingApplyPaymentRequest(resp.paymentOptions[index].paymentOptionId, productID, offerId, valueAmount);
          } else {
            const serviceData = {
              paymentOptionId: resp.paymentOptions[index].paymentOptionId,
              productID,
              offerId,
              valueAmount,
            };
            this.serviceAddressData = serviceData;
            this.initAddressData();
            this.prepareAddressInfo();
          }
        }
      },
      (error) => {
        this.loadingScreenService.stopLoading();
        this.errorDisplay(error);
      }
    );
  }

  cancelFirstStep() {
    this.isFirstStep = false;
    this.isOpenPaymentModal.next(false);
    this.global.setStep(2);
  }

  fillingApplyPaymentRequest(paymentOptionId, productId, offerId, valueAmount) {
    this.getTransactionId().then();
    const data: ApplyPaymentRequest = {
      recieverAcctNumber: '',
      receiverSubsNumber: '',
      merchantCodeId: '',
      productId: productId.toString(),
      paymentOptionId,
      paymentAmount: valueAmount.toString(),
      customerEmail: this.userData.accountInfo.email,
      subscriberNumber: this.subscriberInfo.subscriberNumber,
      subscriberAccountNumber: this.userData.accountInfo.BAN,
      subscriberFullName: this.userData.accountInfo.firstName + ' ' + this.userData.accountInfo.lastName,
      subscriberAddress1: this.address.address1,
      subscriberAddress2: this.address.address2 ? this.address.address2 : '',
      subscriberCity: this.address.city,
      subscriberState: this.userData.accountInfo.state,
      subscriberZipCode: this.address.zipCode,
      transactionDescription: 'MICLARO STORE',
      extendedData: this.global.isFlashPayment ? 'FLASH' : ''
    };
    // this.initAddressData();
    this.executApplyPayment(data, offerId);
  }

  executApplyPayment(data, offerId) {
    this.loadingScreenService.startLoading();
    this.services.applyPayment(offerId, data.productId, data.paymentOptionId,
      data.paymentAmount, data.customerEmail, data.subscriberNumber,
      data.subscriberAccountNumber, data.subscriberFullName,
      data.subscriberAddress1, data.subscriberAddress2, data.subscriberCity,
      data.subscriberState, data.subscriberZipCode, data.transactionDescription,
      '', '', '', '', data.extendedData
    ).subscribe(
      (resp: ApplyPaymentResponse) => {
        this.loadingScreenService.stopLoading();
        this.prepareAndDisplayModal(resp);
      },
      error => {
        this.loadingScreenService.stopLoading();
        this.applyPaymentValidation = false;
        this.global.notif('A ocurrido un error intente nuevamente mas tarde');
        // Error
      }
    );

  }

  prepareAndDisplayModal(resp) {
    this.applyPaymentResponse = resp;
    this.cardName = this.subscriberName + ' ' + this.subscriberLastName;
    let year = Number(this.jstoday.substring(6, 10));
    this.years = [];
    for (let i = 0; i < 10; i++) {
      this.years.push(year.toString());
      year++;
    }
    this.isFirstStep = false;
    this.applyPaymentValidation = true;
  }

  prepareAddressInfo() {
    this.addressFieldsNeedToBeFilled = true;
    this.initializeAddressErrors();
  }

  initializeAddressErrors() {
    this.address.address1Error = false;
    this.address.cityError = false;
    this.address.stateError = false;
    this.address.zipCodeError = false;
    this.address.zipCodeErrorFormat = false;
  }

  initAddressData() {
    this.address.address1 = '';
    this.address.address2 = '';
    this.address.city = '';
    this.address.zipCode = '';
    this.address.state = 'PR';
  }

  ccvChanged() {
    this.isTheCcvError = false;
    if (this.isNumericValidCharacter(this.ccv)) {
      this.ccv = '';
      this.isTheCcvError = true;
      return;
    }

    if (this.ccv !== '') {
      this.isTheCcvEmpty = false;
    }
  }

  zipCodeChanged() {
    this.isValidZip = true;
    this.isEmptyZipCode = false;
    if (this.isNumericValidCharacter(this.address.zipCode)) {
      this.address.zipCode = '';
      this.isValidZip = false;
      return;
    }
    if (this.address.zipCode !== '') {
      if (this.address.zipCode.length !== 5) {
        this.isValidZip = false;
      }
    } else {
      this.isEmptyZipCode = true;
    }
  }

  validateCreditCardForm() {
    let canContinue = true;
    if (this.cardNumberString === '') {
      this.isTheCardNumberEmpty = true;
      canContinue = !canContinue;
      return canContinue;
    }

    if (this.ccv === '') {
      this.isTheCcvEmpty = true;
      canContinue = !canContinue;
      return canContinue;

    }

    if (this.cardIsExpired) {
      canContinue = !canContinue;
      return canContinue;
    }

    if (this.isEmptyZipCode || !this.isValidZip) {
      canContinue = !canContinue;
      return canContinue;
    }

    return canContinue;
  }

  changeExpirationMonth() {
    if (this.expirationYear === this.jstoday.substring(6, 10) &&
      Number(this.jstoday.substring(3, 5)) > Number(this.expirationMonth)) {
      this.cardIsExpired = true;
    } else {
      this.cardIsExpired = false;
    }
  }

  async requestBodyCreationforAuthorizePayment() {
    let index = 0;
    if (this.isPrepaid) {
      index = this.applyPaymentResponse.customerInfo.map(x => x.accountNumber).indexOf(this.subscriberInfo.subscriberNumber);
    } else {
      index = this.applyPaymentResponse.customerInfo.map(x => x.accountNumber).indexOf(this.userData.accountInfo.BAN);
    }
    if (index === -1) {
      this.loadingScreenService.stopLoading();
      this.global.notif(`La cuenta: ${this.banSelected}, no ha llegado en la respuesta del servidor`);
      return;
    } else {
      // execut authorize payment
      const data = {
        data: '',
        filler1: this.isPrepaid ? 'PREPAGO' : '',
        paymentToken: this.applyPaymentResponse.paymentToken,
        creditCardNumber: this.cardNumber,
        paymentMethod: this.paymentMethod,
        cardExpMonth: this.expirationMonth,
        cardExpYear: this.expirationYear,
        cardSecurityCode: this.ccv,
        accountNumber: this.userData.accountInfo.BAN,
        customerName: this.cardName,
        customerEmail: this.userData.accountInfo.email ? this.userData.accountInfo.email : 'noEmail@claropr.com',
        address1: this.applyPaymentResponse.customerInfo[0].address1,
        address2: this.applyPaymentResponse.customerInfo[0].address2,
        city: this.applyPaymentResponse.customerInfo[0].city,
        state: this.applyPaymentResponse.customerInfo[0].state,
        zipCode: this.address.zipCode,
      };
      return this.getKey(data);
    }
  }

  async getKey(data) {
    try {
      const respKey = await this.services.getKeys().toPromise();
      this.encrypt.setKeys(atob(respKey.sharedKey), atob(respKey.ivKey));
      return this.services.authorizePayment(data).toPromise();
    } catch (error) {
      this.loadingScreenService.stopLoading();
      const result = {errorDisplay: 'En estos momentos estamos presentando inconvenientes intente nuevamente más tarde'};
      this.errorDisplay(result);
    }
  }

  validateResult(result) {
    if (result) {
      if (result.errorNum === 0) {
        if (result.processEndState === true) {
          if (result.hasError === false) {
            if (result.errorCode === '0000') {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  paymentOk(result) {
    this.initData();
    this.loadingScreenService.stopLoading();
    sessionStorage.setItem('numConfirmation', result.confirmationNumber);
    sessionStorage.setItem('numAuthorization', result.authorizationNumber);
    this.loadingScreenService.stopLoading();
    this.global.notif('Se ha efectuado el pago de la factura.');
    const screenActual = 2;
    return screenActual;
  }

  errorDisplay(result) {
    this.initData();
    this.loadingScreenService.stopLoading();
    this.router.navigate(['login']);
    result ? this.global.notif(result.errorDisplay) : this.global.notif('a ocurrido un error');
  }


  isValidCreditCardNumber(cardNumber) {
    this.isValidCard = true;
    this.isTheCardNumberEmpty = false;
    if (this.VisaCardnumber(cardNumber)) {
      this.cardType = 'VISA';
    } else if (this.MasterCardnumber(cardNumber)) {
      this.cardType = 'MASTER CARD';
    } else if (this.AmexCardnumber(cardNumber)) {
      this.cardType = 'AMERICAN EXPRESS';
    } else if (this.DiscoverCardnumber(cardNumber)) {
      this.cardType = 'DISCOVER';
    } else if (this.DinerClubCardnumber(cardNumber)) {
      this.cardType = 'DINERCLUB';
    } else if (this.JCBCardnumber(cardNumber)) {
      this.cardType = 'JCB';
    } else {
      this.cardType = 'invalid card type';
    }

    if (this.cardType === 'invalid card type') {
      this.isValidCard = !this.isValidCard;
      this.cardNumberString = '';
    } else {
      this.cardNumber = this.cardNumberString;
      this.cardNumberString = '************' + this.cardNumber.substring(13, this.cardNumber.length);
      this.getpaymentMethod(this.cardType);
    }
  }

  AmexCardnumber(inputtxt) {
    const cardno = /^(?:3[47][0-9]{13})$/;
    return cardno.test(inputtxt);
  }

  VisaCardnumber(inputtxt) {
    const cardno = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    return cardno.test(inputtxt);
  }

  MasterCardnumber(inputtxt) {
    const cardno = /^(?:5[1-5][0-9]{14})$/;
    return cardno.test(inputtxt);
  }

  DiscoverCardnumber(inputtxt) {
    const cardno = /^(:?6011|5[0-9][0-9])[0-9]{12}$/;
    return cardno.test(inputtxt);
  }

  DinerClubCardnumber(inputtxt) {
    const cardno = /^(?:3(?:0[0-5]|[68][0-9])[0-9]{11})$/;
    return cardno.test(inputtxt);
  }

  JCBCardnumber(inputtxt) {
    const cardno = /^(?:(?:2131|1800|35\d{3})\d{11})$/;
    return cardno.test(inputtxt);
  }

  getpaymentMethod(cardType) {
    this.applyPaymentResponse.paymentMethod.forEach(element => {
      if (element.methodDescription === cardType) {
        this.paymentMethod = element.methodCode;
      }
    });
  }

  applyPaymentValidationOff() {
    this.applyPaymentValidation = false;
    this.cardNumber = '';
    this.cardNumberString = '';
    this.ccv = '';
    this.cardType = '';
    this.isOpenPaymentModal.next(false);
    this.global.setStep(2);
  }

  isNumericValidCharacter(text: string): boolean {
    const numbers = '1234567890';
    let hasSpecial = false;

    for (let i = 0; i < text.length; i++) {
      if (numbers.indexOf(text.charAt(i), 0) === -1) {
        hasSpecial = true;
      }
    }
    return hasSpecial;
  }

  async getTransactionId() {
    // tslint:disable-next-line:max-line-length
    const transaction = await this.services.createTransaction(
      'MEPMMTCRDT',
      this.isPrepaid ? 'PREPAID_BUY_BALANCE' : 'POSTPAID_PAY_INVOICE',
      '',
      sessionStorage.getItem('token')).toPromise();
    sessionStorage.setItem('transactionIdSplit1', transaction.object.id);
    sessionStorage.setItem('transactionId', transaction.object.id);
  }

  updateTransaction(verifyPaymentStatus, value) {
    this.loadingScreenService.startLoading();
    this.services.updateTransaction(
      sessionStorage.getItem('transactionIdSplit1'),
      verifyPaymentStatus.errorCode === null ? '' : verifyPaymentStatus.errorCode,
      verifyPaymentStatus.confirmationNumber === null ? '' : verifyPaymentStatus.confirmationNumber,
      verifyPaymentStatus.authorizationNumber === null ? '' : verifyPaymentStatus.authorizationNumber,
      verifyPaymentStatus.paymentStatus + '###' + verifyPaymentStatus.provisioningStatus,
      verifyPaymentStatus.message === null ? '' : verifyPaymentStatus.message,
      this.getTransactionStatus(verifyPaymentStatus.paymentStatus, verifyPaymentStatus.provisioningStatus),
      sessionStorage.getItem('token'),
      sessionStorage.getItem('urlId'),
      this.serviceCompleteData.valueAmount,
      this.isPrepaid ? 'PREPAID_BUY_BALANCE' : 'POSTPAID_PAY_INVOICE', 'completed',
      JSON.parse(atob(sessionStorage.getItem('getSubscriber'))).accountInfo.accountType,
      JSON.parse(atob(sessionStorage.getItem('getSubscriber'))).accountInfo.accountSubtype,
      this.userData.accountInfo.BAN,
      this.userData.accountInfo.BAN,
      this.serviceCompleteData.valueAmount,
      this.subscriberName + ' ' + this.subscriberLastName
    ).subscribe(data => {
      this.loadingScreenService.stopLoading();

      if (!value) {
        this.global.notif('Disculpe, hemos tendido problemas procesando su pago, por favor llamar al 787-793-1253');
      }

    });
  }

  private getTransactionStatus(paymentStatus: string, provisioningStatus: string) {
    if (paymentStatus === 'BANK SUCCESS NOT PROVISIONABLE' && provisioningStatus === 'SPLIT TRANSACTION NOT PROVISIONABLE') {
      return 'approved';
    }
    if (paymentStatus === 'BANK PAYMENT SUCCESS' && provisioningStatus === 'PROVISIONED') {
      return 'provisioning';
    }
    if (paymentStatus === 'BANK PAYMENT SUCCESS' && provisioningStatus === 'EXTERNALLY PROVISIONED') {
      return 'provisioning';
    }
    if (paymentStatus === 'BANK PAYMENT SUCCESS' && provisioningStatus === 'PROVISIONING FAILED') {
      return 'noprovisioning';
    }
    if (Number(paymentStatus) === 10 && Number(provisioningStatus) === 60) {
      return 'provisioning';
    }
    return 'rejected';
  }


}
