import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertasComponent } from './components/alertas/alertas.component';
import { CollapseComponent } from './not-used-in-the-project/collapse/collapse.component';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { LoadingScreenMinComponent } from './not-used-in-the-project/loading-screen-min/loading-screen-min.component';
import { OptionsComponent } from './not-used-in-the-project/options/options.component';
import { PaymentModalComponent } from './components/payment-modal/payment-modal.component';
import { FooterComponent } from './layout/footer/footer.component';
import { MenuComponent } from './not-used-in-the-project/menu/menu.component';
import {
  MatButtonModule,
  MatDatepickerModule,
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule,
  MatNativeDateModule,
  MatRadioModule,
  MatTooltipModule,
  MatSelectModule,
  MatSlideToggleModule } from '@angular/material';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { OnlyAmountDirective } from '../core/directives/only-amount.directive';
import { OnlyNumberDirective } from '../core/directives/only-number.directive';
import { ZipCodeDirective } from '../core/directives/zip-code.directive';
import { HeaderComponent } from './layout/header/header.component';
import { StepComponent } from './layout/step/step.component';
import { SplashComponent } from './components/splash/splash.component';
import { IframePaymentComponent } from './components/iframe-payment/iframe-payment.component';
import { PaymentDeclinedComponent } from '../core/component/payment-declined/payment-declined.component';
import { SafeHtmlPipeModule } from './safe-html-pipe/safe-html-pipe.module';
import { ChatComponent } from '../pages/chat/chat.component';

const materialModules = [
  MatSelectModule,
  MatRadioModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatFormFieldModule,
  MatButtonModule,
  MatTooltipModule,
  MatInputModule,
  MatDialogModule,
  MatSlideToggleModule
];

@NgModule({
  declarations: [
    AlertasComponent,
    CollapseComponent,
    LoadingScreenComponent,
    LoadingScreenMinComponent,
    OptionsComponent,
    PaymentModalComponent,
    FooterComponent,
    MenuComponent,
    OnlyAmountDirective,
    OnlyNumberDirective,
    ZipCodeDirective,
    HeaderComponent,
    StepComponent,
    SplashComponent,
    IframePaymentComponent,
    PaymentDeclinedComponent,
    ChatComponent
  ],
  imports: [
    CommonModule,
    SafeHtmlPipeModule,
    materialModules,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    AlertasComponent,
    CollapseComponent,
    LoadingScreenComponent,
    LoadingScreenMinComponent,
    OptionsComponent,
    PaymentModalComponent,
    FooterComponent,
    MenuComponent,
    OnlyAmountDirective,
    OnlyNumberDirective,
    ZipCodeDirective,
    HeaderComponent,
    StepComponent,
    SplashComponent,
    IframePaymentComponent,
    SafeHtmlPipeModule,
    PaymentDeclinedComponent,
    ChatComponent
  ]
})
export class SharedModule { }
